import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
    getAnnouncementsInner,
    putAnnouncements,
} from '../../../../store/slices/announcementSlice';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '../../../../components/UI/buttons';
import UiInput from '../../../../components/UI/UiInput/UiInput';
import Input from '../../../../components/UI/inputs';

const ChangeAnnouncement = () => {
    const [text, setText] = useState({
        title: '',
        description: '',
        link: '',
    });

    const dispatch = useDispatch();
    const { id } = useParams();

    const { announcementsInner } = useSelector((state) => state.announcements);

    useEffect(() => {
        dispatch(getAnnouncementsInner({ ID: id }));
    }, [dispatch, id]);

    const navigate = useNavigate();

    const putSubmitHandle = () => {
        dispatch(
            putAnnouncements({
                Id: id,
                Data: {
                    title: text.title,
                    description: text.description,
                    linkVideo: text.link,
                },
            })
        );
        navigate(`/announcements/announcements-inner/${id}`);
    };

    useEffect(() => {
        if (announcementsInner !== null) {
            setText({
                title: announcementsInner?.title,
                description: announcementsInner?.description,
                link: announcementsInner?.link,
            });
        }
    }, [announcementsInner]);

    return (
        <StyledContainer>
            <StyledHeader>
                <Breadcrumbs aria-label="breadcrumb">
                    <LINK
                        underline="hover"
                        color="inherit"
                        href="/announcements"
                    >
                        Объявления
                    </LINK>
                    <LINK underline="hover" color="inherit" aria-current="page">
                        Редактировать {announcementsInner?.title}
                    </LINK>
                </Breadcrumbs>
            </StyledHeader>
            <StyledMain>
                <Label htmlFor="linkVideo">
                    Вставьте ссылку на видео:
                    <UiInput
                        type="url"
                        id="linkVideo"
                        value={text.link}
                        placeholder="Введите ссылку:"
                        onChange={({ target }) =>
                            setText({ ...text, link: target.value })
                        }
                    />
                </Label>

                <Label htmlFor="title">
                    Название:
                    <UiInput
                        id="title"
                        value={text.title}
                        placeholder="Введите название:"
                        onChange={({ target }) =>
                            setText({ ...text, title: target.value })
                        }
                    />
                </Label>
                <Input
                    label="Описание"
                    type="textarea"
                    value={text.description}
                    onChange={({ target }) =>
                        setText({ ...text, description: target.value })
                    }
                    placeholder="Введите описание:"
                />
                <Button onClick={putSubmitHandle}>Редактировать</Button>
            </StyledMain>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    width: 100%;
`;
const StyledHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const StyledMain = styled.div`
    width: 50%;
    display: flex;
    gap: 12px;
    flex-direction: column;
    margin-top: 10px;
    overflow: hidden;
`;
const LINK = styled(Link)`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #000000;
    @media screen and (max-width: 750px) {
        font-size: 14px;
    }
    @media screen and (max-width: 550px) {
        font-size: 12px;
    }
`;

const Label = styled.label`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #6a6a6a;
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    div {
        margin-top: 5px;
    }
`;

export default ChangeAnnouncement;
