import ContainerSlider from '../../../components/Containerslider';
import { SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useState } from 'react';
import { useEffect } from 'react';

const DATA = [
    {
        name: 'База пациентов',
        describtion:
            'База данных пациентов медцентра обладает широкими возможностями автоматизации, поиска, сортировок, настроек, внесения важной и второстепенной информации.',
        color: {
            bg: '#018CFF',
            colorOne: '#007ADF',
        },
    },
    {
        name: 'Складской учет',
        describtion:
            'Учет расходных материалов. Приход и Расход товаров. Автоматизация списания материалов и прочее.',
        color: {
            bg: '#ef9367',
            colorOne: '#f57738',
        },
    },
    {
        name: 'Whats App интеграция',
        describtion:
            'Смарт уведомлении на самой популярном мессенджере в мире. Никто не пропустить WhatsApp сообщении',
        color: {
            bg: '#7CFC00',
            colorOne: '#36d9f4',
        },
    },
    {
        name: 'Расчет зарплат',
        describtion:
            'Автоматический модуль расчета зарплат врачам посчитает сколько заработал врач за выбранный период за оказанные приемы, продажу товаров, учесть материалы.',
        color: {
            bg: '#a36afa',
            colorOne: '#6e2fd0',
        },
    },
    {
        name: 'Аналитика',
        describtion:
            'Профессиональная аналитика и статистика. Отчеты позволяют оценивать эффективность работы сотрудников и администраторов, возвращаемость клиентов и многое другое.',
        color: {
            bg: '#cd32e9',
            colorOne: '#e466fe',
        },
    },
    {
        name: 'Доступы сотрудникам',
        describtion:
            'Полностью настраиваемые доступы сотрудникам в зависимости от роли дадут возможность работать только с требуемым функционалом.',
        color: {
            bg: '#f5c315',
            colorOne: '#ffd83d',
        },
    },
    {
        name: 'Интеграции',
        describtion:
            'Интеграции позволяют организовать эффективное единое рабочее пространство.',
        color: {
            bg: '#295bd7',
            colorOne: '#2534fe',
        },
    },
    {
        name: 'Онлайн запись',
        describtion:
            'Теперь записаться на приём можно полностью онлайн - не выходя из дома и без лишних звонков.',
        color: {
            bg: '#018CFF',
            colorOne: '#007ADF',
        },
    }
]

const SliderOur = () => {
    const [cardWidthh, setCardWidth] = useState(0);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 470) {
                setCardWidth(160);
            } else {
                setCardWidth(280);
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="w-full mx-auto flex flex-wrap max-w-[1440px] items-center justify-center sm:max-w-[460px]">
            <ContainerSlider cardWidth={cardWidthh}>
                {DATA.map((item) => (
                    <SwiperSlide
                        key={item.name}
                        className="w-[315px] border h-[380px] min-h-[380px] max-h-[380px] rounded-[50px] sm:rounded-t-[50px] sm:rounded-b-[15px] sm:max-w-[195px] sm:min-h-[250px] "
                    >
                        <svg
                            width="325px min-w-[315px] max-w-[315px]"
                            viewBox="0 0 325 126"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 50C0 22.3858 22.3858 0 50 0H275C302.614 0 325 22.3858 325 50V126H0V50Z"
                                fill={item.color.bg}
                            ></path>
                            <path
                                d="M48.0116 64.0682C48.9411 62.6478 50.1502 61.3055 51.6214 60.0607L73.9188 41.1937C75.516 39.8422 77.5849 38.6888 80.0371 37.7828L98.8892 30.8172C105.259 28.4635 115.25 30.7937 117.995 35.2736C119.621 37.9278 124.046 40.0343 129.061 40.5416L131.219 40.7599C141.691 41.8191 148.758 48.0605 144.9 52.8421L138.442 60.8456C136.691 63.0169 136.133 65.5008 136.825 68.0478L139.986 79.675L136.599 81.6737C127.117 87.2689 115.372 91.636 102.001 94.5387L94.5057 101.282C92.0369 103.503 88.4628 105.263 84.0993 106.407C58.8503 113.026 26.4015 97.0902 35.8207 82.6968L48.0116 64.0682Z"
                                fill={item.color.colorOne}
                            ></path>
                            <path
                                d="M134.199 68.793L150.444 64.2684C156.666 62.5355 162.052 60.1088 166.384 57.0866L188.567 41.6103C195.731 36.6126 209.846 36.6808 221.467 41.7694C223.052 42.4632 224.714 43.0754 226.426 43.5963L230.425 44.8122C259.736 53.7266 276.902 73.5767 268.341 88.6565C265.171 94.2389 258.712 98.5937 249.87 101.11L238.171 104.438C223.855 108.512 204.23 106.279 188.401 98.7763L186.487 101.148C178.647 110.866 155.715 111.949 137.32 103.47C116.358 93.809 112.507 77.1314 129.71 70.5187L134.199 68.793Z"
                                fill={item.color.colorOne}
                            ></path>
                            <path
                                d="M163.447 23.6981C167.875 26.3091 170.244 30.8008 170.156 36.4173L170.096 40.2297C170.091 40.5386 170.018 40.8715 169.881 41.2138L168.085 45.6766C167.308 47.6052 164.281 48.5329 164.003 46.9276L160.458 37.4274C160.02 36.254 159.363 35.2174 158.503 34.3432L157.561 33.3858C155.781 31.5758 152.912 30.7782 149.471 31.1362L148.555 31.2314L146.563 25.9749C145.538 23.2704 149.412 19.0012 153.254 18.6016C154.116 18.5119 154.873 18.6427 155.445 18.9799L163.447 23.6981Z"
                                fill={item.color.colorOne}
                            ></path>
                            <path
                                d="M288.721 111.536C291.045 113.582 292.245 116.984 288.392 118.08C284.54 119.176 279.533 118.406 277.208 116.361C274.884 114.315 276.123 111.768 279.976 110.671C283.828 109.575 286.397 109.49 288.721 111.536Z"
                                fill={item.color.colorOne}
                            ></path>
                            <path
                                d="M51.167 29.6598C54.6739 29.368 56.6974 33.482 53.7579 39.0094C50.8184 44.5368 42.7617 51.7672 39.2548 52.0591C35.7479 52.3509 34.6502 45.8824 37.5897 40.355C40.5293 34.8277 47.6601 29.9517 51.167 29.6598Z"
                                fill={item.color.colorOne}
                            ></path>
                            <circle
                                cx="135.176"
                                cy="109.177"
                                r="2"
                                transform="rotate(-5.28136 135.176 109.177)"
                                fill={item.color.colorOne}
                            ></circle>
                            <path
                                d="M111.593 114.063C111.905 117.433 109.625 120.27 106.669 120.544C103.713 120.817 100.952 118.446 100.64 115.076C100.329 111.705 102.608 108.868 105.564 108.595C108.521 108.321 111.282 110.693 111.593 114.063Z"
                                stroke="black"
                                strokeWidth="2"
                            ></path>
                            <path
                                d="M25.0357 79.1548C24.8912 80.625 24.6241 82.0822 24.4789 83.5609C24.376 84.6081 24.9948 85.9674 25.5366 86.8116C26.9258 88.9762 29.0482 91.1235 31.6643 91.4412C32.5481 91.5485 33.5941 91.7853 34.4646 91.5316C35.0848 91.3508 36.0241 91.1268 36.4805 90.6357C37.3134 89.7395 38.0185 88.9999 38.0748 87.7237C38.0988 87.181 37.1162 87.1181 36.7743 87.0845C36.2552 87.0335 35.2945 87.9428 35.0423 88.3847C34.0804 90.0701 33.2432 91.8525 33.0144 93.8184C32.7262 96.2941 32.2305 98.4741 33.2515 100.867C33.689 101.892 34.3006 102.857 34.7815 103.866C34.9657 104.252 35.3897 105.179 35.7194 105.393"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                            ></path>
                            <path
                                d="M260.984 75.0996C259.811 75.9974 258.564 76.7967 257.384 77.6997C256.548 78.3391 255.977 79.7191 255.729 80.6913C255.095 83.184 254.974 86.2007 256.528 88.3296C257.052 89.0488 257.593 89.975 258.373 90.4381C258.928 90.768 259.733 91.3018 260.403 91.3002C261.627 91.2974 262.649 91.3079 263.62 90.4781C264.033 90.1253 263.408 89.3641 263.199 89.0913C262.882 88.6771 261.562 88.5955 261.067 88.7127C259.179 89.1599 257.304 89.7644 255.711 90.9388C253.705 92.4178 251.774 93.5433 250.721 95.9225C250.27 96.9422 249.983 98.0473 249.574 99.0874C249.417 99.4857 249.029 100.428 249.098 100.815"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                            ></path>
                            <path
                                d="M233.422 28.7227L239.252 22.557"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                            ></path>
                            <path
                                d="M237.723 31.8242L245.046 24.1299"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                            ></path>
                            <path
                                d="M241.371 34.5859L249.22 25.8144"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                            ></path>
                            <path
                                d="M87.2134 54.8905L87.471 55.6288L88.2496 55.5569L90.4584 55.3527L88.6372 57.4341L88.2538 57.8723L88.4456 58.4221L89.3096 60.8981L87.8642 59.7586L87.1181 59.1703L86.4925 59.8854L85.2805 61.2706L85.6759 58.6781L85.7637 58.1025L85.3064 57.742L83.1346 56.0297L85.3433 55.8255L86.122 55.7535L86.2399 54.9805L86.5455 52.9764L87.2134 54.8905Z"
                                stroke="black"
                                strokeWidth="2"
                            ></path>
                            <path
                                d="M182.846 11.3045C182.982 12.7049 181.967 13.9233 180.601 14.0438C179.235 14.1643 178.003 13.1441 177.868 11.7436C177.732 10.3432 178.747 9.12479 180.113 9.00429C181.479 8.88379 182.71 9.90405 182.846 11.3045Z"
                                stroke="black"
                                strokeWidth="2"
                            ></path>
                            <path
                                d="M284.5 55.9049C284.783 54.6284 284.983 53.3357 285.102 52.0344C285.649 47.616 288.28 44.2846 291.603 43.613L294 43.1358C294 43.1358 290.238 43.1358 288.666 42.0577C285.344 39.769 284.581 33 284.581 33C284.581 33 283.683 39.769 280.325 42.0577C278.762 43.1358 275 43.1358 275 43.1358L277.397 43.613C280.711 44.2846 283.351 47.6602 283.898 52.0344C284.011 53.3363 284.212 54.6293 284.5 55.9049Z"
                                fill={item.color.colorOne}
                            ></path>
                        </svg>
                        <div className="px-5 py-5 flex flex-col gap-5 sm:p-3 sm:gap-2">
                            <h1 className="text-[24px] font-semibold sm:text-[18px]">
                                {item.name}
                            </h1>
                            <span className="text-[13px] text-[gray] sm:text-[12px] max-h-[150px] overflow-y-scroll">
                                {item.describtion}
                            </span>
                        </div>
                    </SwiperSlide>
                ))}
            </ContainerSlider>
        </div>
    );
};

export default SliderOur;
