import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const postDiagnosis = createAsyncThunk(
    'diagnosis/postDiagnosis',
    async (value, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().post(
                'diagnosis/save',
                {
                    name: value,
                }
            );
            dispatch(getDiagnosis());
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const getDiagnosisAll = createAsyncThunk(
    'diagnosis/getDiagnosisAll',
    async ({ currentPage, search }, { rejectWithValue, dispatch }) => {
        try {
            if (search) {
                const response = await axiosConfigInstance().get(
                    `diagnosis/all?searchDiagnosis=${search}`
                );
                dispatch(getDiagnosis());
                return response.data;
            } else {
                const response = await axiosConfigInstance().get(
                    `diagnosis/all?page=${currentPage}`
                );
                dispatch(getDiagnosis());
                return response.data;
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getDiagnosis = createAsyncThunk(
    'diagnosis/getDiagnosis',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get('diagnosis');
            return response.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

export const deleteDiagnosis = createAsyncThunk(
    'diagnosis/deleteDiagnosis',
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `diagnosis/${id}`
            );
            showSuccessMessage(response.data.message);
            dispatch(getDiagnosis());
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const putDiagnosis = createAsyncThunk(
    'diagnosis/putDiagnosis',
    async ({ name, id }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                `diagnosis/${id}?name=${name}`
            );
            showSuccessMessage(response.data.message);
            dispatch(getDiagnosis());
            return response.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

const initialState = {
    diagnosis: [],
    diagnosisAll: [],
    isLoading: false,
    totalPages: 1,
};

export const diagnosisSlice = createSlice({
    name: 'diagnosis',
    initialState,
    reducers: {},
    extraReducers: {
        // post

        [postDiagnosis.pending]: (state) => {
            state.isLoading = true;
        },
        [postDiagnosis.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [postDiagnosis.rejected]: (state) => {
            state.isLoading = false;
        },
        //get

        [getDiagnosis.pending]: (state) => {
            state.isLoading = true;
        },
        [getDiagnosis.fulfilled]: (state, action) => {
            state.diagnosis = action.payload;
            state.isLoading = false;
        },
        [getDiagnosis.rejected]: (state) => {
            state.isLoading = false;
        },
        //GetAll
        [getDiagnosisAll.pending]: (state) => {
            state.isLoading = true;
        },
        [getDiagnosisAll.fulfilled]: (state, action) => {
            state.diagnosisAll = action.payload;
            state.isLoading = false;
        },
        [getDiagnosisAll.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default diagnosisSlice;
