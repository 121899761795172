import React, { useEffect } from 'react';
import Modal from '../../../components/UI/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { putpostCardsAddPatients } from '../../../store/slices/marketingSlice';
import { AiOutlineClose } from 'react-icons/ai';
import Input from '../../../components/UI/inputs';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function ModalSelectPatients({ showModal, setShowModal, cardId }) {
    const [searchValue, setSearchValue] = useState('');

    const { patientsData } = useSelector((state) => state.marketing);
    const [patientId, setPatientId] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [dataPatients, setDataPatients] = useState(patientsData);

    const dispatch = useDispatch();

    useEffect(() => {
        setDataPatients(
            patientsData.filter((user) =>
                user.fullName.toLowerCase().includes(searchValue.toLowerCase())
            )
        );
    }, [searchValue, patientsData]);

    const PATIENTS_HEADER = [
        {
            headerName: 'ID',
            field: 'patientId',
            width: 100,
        },
        {
            headerName: 'ФИО',
            field: 'fullName',
            width: 200,
        },
        { headerName: 'Телефон', field: 'phoneNumber', width: 200 },
        {
            headerName: 'Почта',
            field: 'email',
            width: 200,
        },
    ];

    const handleClick = async () => {
        setIsLoading(true);
        await delay(3000);
        dispatch(
            putpostCardsAddPatients({
                patientsId: patientId,
                cardId: cardId,
            })
        );
        setIsLoading(false);
        setShowModal(false);
    };

    return (
        <Modal active={showModal}>
            <InnerModal>
                <StyledContainer>
                    <StyledHeader>
                        <StyledTittle>Добавить пациента</StyledTittle>
                        <AiOutlineClose
                            onClick={() => setShowModal(false)}
                            className="cursor-pointer text-gray-400"
                        />
                    </StyledHeader>
                    <WrapprInput>
                        <Input
                            borderRadius="30px"
                            width="230px"
                            padding="5px 10px"
                            placeholder="Поиск по таблице..."
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </WrapprInput>
                    <StyledContainerMain>
                        <DataGrid
                            columns={PATIENTS_HEADER}
                            rows={dataPatients.map((row) => ({
                                ...row,
                                id: row.patientId,
                            }))}
                            pageSizeOptions={[10, 20, 30, 40, 50, 100]}
                            checkboxSelection
                            onRowSelectionModelChange={(e) => setPatientId(e)}
                            rowSelectionModel={patientId}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10, page: 0 },
                                },
                            }}
                            localeText={{
                                noRowsLabel: 'Нет выбранных пациентов',
                                columnMenuSortAsc: 'Сортировка по порядку',
                                columnMenuSortDesc: 'Сортировка не по порядку',
                                columnMenuUnsort: 'Несортировать',
                                columnMenuFilter: 'Поиск',
                                columnMenuManageColumns: 'Заголовки',
                                columnMenuHideColumn: 'Скрыть столбец',
                                columnsPanelTextFieldLabel: 'Найти столбец',
                                columnsPanelTextFieldPlaceholder:
                                    'Название столбца',
                                columnsPanelShowAllButton: 'Показать все',
                                columnsPanelHideAllButton: 'Скрыть все',
                                filterPanelInputLabel: 'Значение',
                                filterPanelInputPlaceholder: '',
                                filterPanelColumns: 'Заголовки',
                                filterPanelOperator: 'Оператор',
                                filterOperatorContains: 'Cодержит',
                                filterOperatorEquals: 'Pавно',
                                filterOperatorStartsWith: 'Hачинается с',
                                filterOperatorEndsWith: 'Заканчивается',
                                filterOperatorIsEmpty: 'Пусто',
                                filterOperatorIsNotEmpty: 'Не пусто',
                                filterOperatorIsAnyOf: 'Любой из',
                                footerRowSelected: (count) =>
                                    count !== 1
                                        ? `${count.toLocaleString()} Выбранные`
                                        : `${count.toLocaleString()} Выбранный`,
                                MuiTablePagination: {
                                    labelDisplayedRows: ({ from, to }) =>
                                        `${from} Из ${to}`,
                                    labelRowsPerPage: 'Рядов на странице:',
                                },
                            }}
                        />
                    </StyledContainerMain>
                    <StyledContainerButton>
                        <Buttones
                            disabled={isLoading}
                            onClick={handleClick}
                            className={`button ${isLoading ? 'loading' : ''}`}
                        >
                            {isLoading ? 'Добавляется' : 'Добавить'}
                        </Buttones>
                    </StyledContainerButton>
                </StyledContainer>
            </InnerModal>
        </Modal>
    );
}
const InnerModal = styled.div`
    @media screen and (max-width: 670px) {
        width: 320px;
    }
`;
const WrapprInput = styled.div`
    width: fit-content;
    margin-top: 10px;
`;
const StyledContainer = styled.div`
    width: 800px;
    display: flex;
    flex-direction: column;
    @media (max-width: 865px) {
        width: 700px;
    }
    @media (max-width: 765px) {
        width: 600px;
    }
    @media (max-width: 665px) {
        width: 500px;
    }
    @media screen and (max-width: 565px) {
        width: 320px;
    }
`;
const StyledContainerButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;
const loadingAnimation = keyframes`
  0% {
    transform: translateX(-200px);
  }
  100% {
    transform: translateX(600px);
  }
`;

const Buttones = styled.button`
    position: relative;
    overflow: hidden;
    transform: translateZ(0);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 190px;
    border: 0;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    font-family: 'Gill Sans', sans-serif;
    background: #5460e6;
    color: #f9f9f9;
    cursor: pointer;
    white-space: nowrap;
    > :first-child,
    > :last-child {
        position: relative;
        z-index: 3;
        transition: 0.125s;
    }
    > :last-child {
    }
    :disabled > :first-child,
    :disabled > :last-child {
        opacity: 0.7;
    }
    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        opacity: 0;
        transition: opacity 0.25s;
    }

    &::before {
        background: rgba(255, 255, 255, 0.1);
    }

    &::after {
        left: -100%;
        background: #34d297;
    }

    &.loading::before,
    &.loading::after {
        opacity: 1;
    }

    &.loading::after {
        animation: ${loadingAnimation} 1s infinite linear;
    }
    :disabled,
    :disabled:hover {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;
const StyledContainerMain = styled.div`
    width: 100%;
    height: 400px;
    margin-top: 15px;
    div {
        ::-webkit-scrollbar {
            width: 10px;
        }
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        ::-webkit-scrollbar-thumb {
            background: #888;
        }
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
`;
const StyledHeader = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 30px;
    width: 100%;
`;
const StyledTittle = styled.div`
    @media (max-width: 600px) {
        font-size: 15px;
    }
`;
export default ModalSelectPatients;
