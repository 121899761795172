import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';
import { axiosConfigInstance } from '../../api/axios-config';

export const postCards = createAsyncThunk(
    'marketing/postCards',
    async ({ data, img, type }, { rejectWithValue, dispatch }) => {
        try {
            let id;
            if (img !== null) {
                const formData = new FormData();
                formData.append('file', img);
                const res = await axiosConfigInstance().post(
                    '/files/POSTCARD',
                    formData
                );
                id = res.data.imageId;
            } else {
                id = 0;
            }

            const dannye = {
                title: data.title,
                description: data.description,
                startDate: data.startDate,
                endDate: data.endDate,
                imageId: id,
                postcardType: type,
            };
            const response = await axiosConfigInstance().post(
                '/postcards',
                dannye
            );
            dispatch(getpostCardsPromotions());
            dispatch(getpostCardsHolidays());
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const getpostCardsPromotions = createAsyncThunk(
    'marketing/getpostCardsPromotions',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(
                '/postcards/promotions'
            );
            return response.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

export const getpostCardsHolidays = createAsyncThunk(
    'marketing/getpostCardsHolidays',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(
                '/postcards/holidays'
            );
            return response.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

export const deletepostCards = createAsyncThunk(
    'marketing/deletepostCards',
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `/postcards/${id}`
            );
            dispatch(getpostCardsPromotions());
            dispatch(getpostCardsHolidays());
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const getpostCardsInside = createAsyncThunk(
    'marketing/getpostCardsInside',
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(
                `/postcards/${id}`
            );
            return response.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

export const putpostCards = createAsyncThunk(
    'marketing/putpostCards',
    async ({ data, id }, { rejectWithValue, dispatch }) => {
        try {
            let dannye = null;
            if (typeof data.imageId === 'number') {
                dannye = {
                    title: data.title,
                    description: data.description,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    imageId: data.imageId,
                    postcardType: data.postcardType,
                };
            } else {
                const formData = new FormData();
                formData.append('file', data.imagedFile);
                const res = await axiosConfigInstance().post(
                    '/files/POSTCARD',
                    formData
                );
                const id = res.data.imageId;
                dannye = {
                    title: data.title,
                    description: data.description,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    imageId: id,
                    postcardType: data.postcardType,
                };
            }
            const response = await axiosConfigInstance().put(
                `/postcards/${id}`,
                dannye
            );
            dispatch(getpostCardsPromotions());
            dispatch(getpostCardsHolidays());
            dispatch(getpostCardsInside({ id: id }));
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const getpostCardsPatients = createAsyncThunk(
    'marketing/getpostCardsPatients',
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(
                `/postcards/${id}/patients`
            );
            return response.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

export const putpostCardsAddPatients = createAsyncThunk(
    'marketing/putpostCardsAddPatients',
    async ({ patientsId, cardId }, { rejectedWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                `/postcards/${cardId}/add/patients?patientIds=${patientsId}`
            );
            dispatch(getpostCardsInside({ id: cardId }));
            dispatch(getpostCardsPatients({ id: cardId }));
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

export const putpostCardsSend = createAsyncThunk(
    'marketing/putpostCardsSend',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().put(
                `postcards/${id}/send`,
                data
            );
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const putpostCardsremovePatients = createAsyncThunk(
    'marketing/putpostCardsremovePatients',
    async ({ patientsId, cardId }, { rejectedWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                `/postcards/${cardId}/remove/patients?patientIds=${patientsId}`
            );
            dispatch(getpostCardsPatients({ id: cardId }));
            dispatch(getpostCardsInside({ id: cardId }));
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

const initialState = {
    isLoading: false,
    holidays: [],
    promotions: [],
    insideData: null,
    patientsData: [],
    valueSwitch: false,
};

const marketingSlice = createSlice({
    name: 'marketing',
    initialState,
    reducers: {
        deleteOldData(state, action) {
            state.insideData = action.payload;
        },
        handleSwitch(state, action) {
            state.valueSwitch = action.payload;
        },
    },
    extraReducers: {
        //post

        [postCards.pending]: (state) => {
            state.isLoading = true;
        },
        [postCards.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [postCards.rejected]: (state) => {
            state.isLoading = false;
        },

        //get

        [getpostCardsPromotions.pending]: (state) => {
            state.isLoading = true;
        },
        [getpostCardsPromotions.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.promotions = payload;
        },
        [getpostCardsPromotions.rejected]: (state) => {
            state.isLoading = false;
        },

        [getpostCardsHolidays.pending]: (state) => {
            state.isLoading = true;
        },
        [getpostCardsHolidays.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.holidays = payload;
        },
        [getpostCardsHolidays.rejected]: (state) => {
            state.isLoading = false;
        },

        //delete

        [deletepostCards.pending]: (state) => {
            state.isLoading = true;
        },
        [deletepostCards.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [deletepostCards.rejected]: (state) => {
            state.isLoading = false;
        },

        //getInside

        [getpostCardsInside.pending]: (state) => {
            state.isLoading = true;
        },
        [getpostCardsInside.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.insideData = payload;
        },
        [getpostCardsInside.rejected]: (state) => {
            state.isLoading = false;
        },

        //put

        [putpostCards.pending]: (state) => {
            state.isLoading = true;
        },
        [putpostCards.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [putpostCards.rejected]: (state) => {
            state.isLoading = false;
        },

        //getPatients

        [getpostCardsPatients.pending]: (state) => {
            state.isLoading = true;
        },
        [getpostCardsPatients.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.patientsData = payload;
        },
        [getpostCardsPatients.rejected]: (state) => {
            state.isLoading = false;
        },

        //put add

        [putpostCardsAddPatients.pending]: (state) => {
            state.isLoading = true;
        },
        [putpostCardsAddPatients.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [putpostCardsAddPatients.rejected]: (state) => {
            state.isLoading = false;
        },

        //put remove

        [putpostCardsremovePatients.pending]: (state) => {
            state.isLoading = true;
        },
        [putpostCardsremovePatients.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [putpostCardsremovePatients.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export const { deleteOldData, handleSwitch } = marketingSlice.actions;

export default marketingSlice;
