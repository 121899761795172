import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ADMIN_ROUTES } from '../../utils/constans/routes';
import Button from '../../components/UI/buttons';
import { authAction } from '../../store/slices/authSlice';

function LogoutBtn({ handleClose }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const navigateAfterLogout = () => {
        navigate(ADMIN_ROUTES.ADMIN.path);
    };

    const logoutHandler = () => {
        handleClose();
        dispatch(authAction.logout());
        navigateAfterLogout();
    };
    return (
        <Button
            className="delete"
            borderRadius="5px"
            border="1px solid red"
            backgroundColor="none"
            color="red"
            onClick={logoutHandler}
        >
            Выйти
        </Button>
    );
}

export default LogoutBtn;
