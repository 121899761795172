import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ADMIN_ROUTES } from '../utils/constans/routes';
import { Spinner } from '../components/UI/Spinners/Spinner';
import Applications from '../pages/admin/marketing/Applications';
import CraeteDocumentPage from '../pages/admin/Document/inner-page/CreateDocumentPage';
import InnerJurnal from '../pages/admin/Document/inner-page/InnerJurnal';
import Announcements from '../pages/admin/announcements/Announcements';
import NotFoundPage from '../pages/error-page/NotFoundPage';

const Dashboard = React.lazy(() =>
    import('../layout/dashboard/DashboardManager')
);
const Calender = React.lazy(() => import('../pages/admin/calendar/Calendar'));
const StaffPage = React.lazy(() => import('../pages/admin/stuff/StaffPage'));
const ServicesPage = React.lazy(() =>
    import('../pages/admin/services/ServicesPage')
);
const ReportsPage = React.lazy(() =>
    import('../pages/admin/reports/ReportsPage')
);
const PatientsPage = React.lazy(() =>
    import('../pages/admin/patients/PatientsPage')
);
const VisitPage = React.lazy(() =>
    import('../components/inner-pages/visit/VisitPage')
);
const Marketing = React.lazy(() =>
    import('../pages/admin/marketing/Marketing')
);
const StockPage = React.lazy(() => import('../pages/admin/stock/StokePage'));
// INNER
const InnerPagePatient = React.lazy(() =>
    import('../pages/admin/patients/inner-page')
);
const InnerStaffPage = React.lazy(() =>
    import('../pages/admin/stuff/inner-page')
);
const InnerManagerPage = React.lazy(() =>
    import('../pages/admin/stuff/inner-page/Manager')
);
const InnerSetPage = React.lazy(() =>
    import('../pages/admin/services/inner-page/InnerSetPage')
);
const InnerDocumentPage = React.lazy(() =>
    import('../pages/admin/Document/inner-page/InnerDocumentPage')
);

const ChatsPage = React.lazy(() => import('../pages/admin/chat/index'));
const ChatPage = React.lazy(() => import('../pages/admin/chat/ChatPage'));

const AnnouncementsInner = React.lazy(() =>
    import('../pages/admin/announcements/AnnouncementsInner')
);

export const ManagerRoutes = () => {
    const {
        ADMIN,
        CALENDAR,
        STAFF,
        PATIENTS,
        SERVICES,
        REPORTS,
        MARKETING,
        STOCK,
        CHATS,
        ANNOUNCEMENTS,
    } = ADMIN_ROUTES;
    return (
        <Suspense fallback={<Spinner />}>
            <Routes>
                <Route path={ADMIN.path} element={<Dashboard />}>
                    <Route
                        path="/login"
                        element={<Navigate to={ADMIN.path} />}
                    />
                    <Route path="" element={<Calender />} />
                    <Route path={CALENDAR.path} element={<Calender />} />
                    <Route path={STAFF.path} element={<StaffPage />} />
                    <Route path={PATIENTS.path} element={<PatientsPage />} />
                    <Route
                        path={`${STAFF.path}/doctor/:id`}
                        element={<InnerStaffPage />}
                    />
                    <Route
                        path={`${STAFF.path}/manager/:id`}
                        element={<InnerManagerPage />}
                    />
                    <Route
                        path={`${PATIENTS.path}/user/:id`}
                        element={<InnerPagePatient />}
                    />
                    <Route
                        path={`${SERVICES.path}/service/:id`}
                        element={<InnerSetPage />}
                    />
                    <Route
                        path={`${STOCK.path}/document/:id`}
                        element={<InnerDocumentPage />}
                    />
                    <Route
                        path={`${STOCK.path}/craete-new-document`}
                        element={<CraeteDocumentPage />}
                    />
                    <Route
                        path={`${STOCK.path}/jurnal/:id`}
                        element={<InnerJurnal />}
                    />
                    <Route
                        path={`${PATIENTS.path}/user/:patientId/visit/:id`}
                        element={<VisitPage />}
                    />
                    <Route
                        path={`${MARKETING.path}/card/:id`}
                        element={<Applications />}
                    />
                    <Route path={SERVICES.path} element={<ServicesPage />} />
                    <Route path={REPORTS.path} element={<ReportsPage />} />
                    <Route path={MARKETING.path} element={<Marketing />} />
                    <Route path={STOCK.path} element={<StockPage />} />
                    <Route path={CHATS.path} element={<ChatsPage />}>
                        <Route
                            path={`${CHATS.path}/chat/:chatId`}
                            element={<ChatPage />}
                        />
                    </Route>
                    <Route
                        path={ANNOUNCEMENTS.path}
                        element={<Announcements />}
                    />
                    <Route
                        path={`${ANNOUNCEMENTS.path}/announcements-inner/:id`}
                        element={<AnnouncementsInner />}
                    />
                    <Route path="/*" element={<NotFoundPage />} />
                </Route>
            </Routes>
        </Suspense>
    );
};
