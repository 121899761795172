import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    /* background-color: ${({ theme }) => (theme ? ' #f2f6fa' : '#383838')}; */
    background-color: #f2f6fa;
    padding: 0 !important;
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
