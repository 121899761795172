import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const paymentService = createApi({
    reducerPath: 'paymentApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAppointmentPayment: builder.query({
            query: (patientID) => `payments/patientPayments/${patientID}`,
            providesTags: ['payment'],
        }),
        getAppointmentPaymentwantsToPay: builder.query({
            query: ({ appointmentId }) =>
                `/payments/wantsToPay/${appointmentId}`,
            providesTags: ['payment'],
        }),
        getPaymentWantsToPayFast: builder.query({
            query: ({ appointmentId }) =>
                `/payments/wants-to-fast-pay/${appointmentId}`,
            providesTags: ['payment'],
        }),
        getAppointmentPaymentHistory: builder.query({
            query: ({ appointmentId }) =>
                `payments/appointmentPayments/${appointmentId}`,
            providesTags: ['payment'],
        }),
        postPayment: builder.mutation({
            query: ({ paymentValue, appointmentId }) => {
                return {
                    url: `/payments/toPay/${appointmentId}`,
                    method: 'POST',
                    body: paymentValue,
                };
            },
            invalidatesTags: ['payment'],
        }),
        deletePayment: builder.mutation({
            query: ({ paymentID }) => {
                return {
                    url: `payments/${paymentID}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['payment'],
        }),
    }),
});
export default paymentService;

export const {
    useGetAppointmentPaymentQuery,
    useGetAppointmentPaymentwantsToPayQuery,
    useLazyGetAppointmentPaymentwantsToPayQuery,
    useGetPaymentWantsToPayFastQuery,
    useGetAppointmentPaymentHistoryQuery,
    usePostPaymentMutation,
    useDeletePaymentMutation,
} = paymentService;
