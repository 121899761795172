import EditMarketing from './EditMarketing';
import SelectedPatients from './SelectedPatients';
import { useDispatch, useSelector } from 'react-redux';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import {
    deleteOldData,
    handleSwitch,
} from '../../../store/slices/marketingSlice';
import TabsUI from '../../../components/UI/tabs/Tabs';

function Applications() {
    const { insideData, isLoading, valueSwitch } = useSelector(
        (state) => state.marketing
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    function handleClick() {
        navigate('/marketing?type=АКЦИИ');
        dispatch(handleSwitch(false));
        dispatch(deleteOldData(null));
    }

    const TABS_MARKETING = ['Рассылкa', 'Получатели'];

    return (
        <div>
            <div className="flex justify-between items-center gap-[10px] mt-[10px] mb-[20px]">
                <div
                    className="flex items-center justify-center gap-[10px]"
                    onClick={() => handleClick()}
                >
                    <BiArrowBack size="25px" color="#5460e6" cursor="pointer" />
                    <h1 className="font-sans not-italic font-normal text-[18px] leading-[23px] text-[#5460e6] underline cursor-pointer">
                        {insideData?.title}
                    </h1>
                </div>
            </div>
            <TabsUI
                tabHeads={
                    insideData?.postcardType === 'HOLIDAY'
                        ? TABS_MARKETING.filter((item) => item === 'Рассылкa')
                        : TABS_MARKETING
                }
                tabKey="type"
            >
                <EditMarketing
                    insideData={insideData}
                    isLoading={isLoading}
                    valueSwitch={valueSwitch}
                    id={id}
                />
                {insideData?.postcardType !== 'HOLIDAY' && (
                    <SelectedPatients
                        insideData={insideData}
                        isLoading={isLoading}
                        id={id}
                    />
                )}
            </TabsUI>
        </div>
    );
}

export default Applications;
