import React, { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ImagePicker } from '../image-picker/ImagePicker';
import { IoIosArrowDown } from 'react-icons/io';

const Input = forwardRef((props, ref) => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const closeOptions = (event) => {
            if (props.option !== undefined && showModal) {
                if (!event.target.closest('.blockforselect')) {
                    setShowModal(false);
                }
            }
        };

        document.addEventListener('click', closeOptions);

        return () => {
            document.removeEventListener('click', closeOptions);
        };
    }, [props.option, showModal]);

    useEffect(() => {
        if(props.value === ''){
            setShowModal(false)
        }
    }, [props.value])

    if (props.type === 'file') {
        return <ImagePicker ref={ref} {...props} />;
    }
    if (props.type === 'select') {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <StyledLable htmlFor={props.label}>{props.label}</StyledLable>
                <InputStyledSelect ref={ref} {...props}>
                    {props?.options?.map((option) => (
                        <option
                            key={option.path}
                            value={option.path == null ? '' : option.path}
                            style={{ color: 'black', background: 'white' }}
                        >
                            {option.label}
                        </option>
                    ))}
                </InputStyledSelect>
            </div>
        );
    }
    if (props.type === 'textarea') {
        function handleResize(e) {
            if (props.option !== undefined) {
                setShowModal(props.value !== '' ? true : false);
            }
            e.target.style.height = '63px';
            e.target.style.height = `${e.target.scrollHeight}px`;
        }
        return (
            <div
                className={
                    props.option !== undefined
                        ? 'blockforselect w-full relative !bg-white'
                        : '!bg-white'
                }
            >
                <div className="w-full flex flex-col mt-1">
                    <StyledLable>{props.label}</StyledLable>
                    <div
                        className={
                            props.option !== undefined
                                ? 'w-full flex items-center justify-center relative pr-[30px]'
                                : 'w-full flex items-center justify-center relative'
                        }
                    >
                        <StyledTextArea
                            maxLength={1000}
                            onChange={props.onChange}
                            value={props.value}
                            spellCheck={props.spellCheck}
                            placeholder={props.placeholder}
                            onInput={handleResize}
                            disabled={props.disabled}
                            width={props.width}
                            maxH={props.maxH}
                            height={props.height}
                            notDisabled={props.notDisabled}
                            id={props.id}
                        />
                        {props.option !== undefined && (
                            <div className="flex h-full items-center justify-center absolute right-0 bg-white">
                                <div
                                    className="!bg-white px-3 border-[1.5px] h-full flex items-center justify-center cursor-pointer"
                                    onClick={() =>
                                        props.disabled ||
                                        setShowModal(!showModal)
                                    }
                                >
                                    <IoIosArrowDown />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {props.option !== undefined && showModal && (
                    <>
                        {props.option.length ? (
                            <StyledContaineroption
                                onClick={(e) => e.stopPropagation()}
                            >
                                {props.option?.map((item, index) => {
                                    return (
                                        <StyledInsideOption
                                            key={index}
                                            onClick={() => {
                                                setShowModal(false);
                                                props.onChange({
                                                    target: { value: item },
                                                });
                                            }}
                                        >
                                            <StyledOptionInsideTitle>
                                                {item}
                                            </StyledOptionInsideTitle>
                                        </StyledInsideOption>
                                    );
                                })}
                            </StyledContaineroption>
                        ) : (
                            <StyledContaineroption
                                onClick={(e) => e.stopPropagation()}
                            >
                                <StyledInsideOption>
                                    <StyledOptionInsideTitle>
                                        Пусто
                                    </StyledOptionInsideTitle>
                                </StyledInsideOption>
                            </StyledContaineroption>
                        )}
                    </>
                )}
            </div>
        );
    }
    return (
        <div>
            <StyledLable htmlFor={props.label}>{props.label}</StyledLable>
            <InputStyled
                type={props.type || 'text'}
                onChange={props.onChange}
                value={props.value == null || '' ? '' : props.value}
                placeholder={props.placeholder}
                autoComplete="off"
                disabled={props.disabled}
                notDisabled={props.notDisabled}
                active={props.active}
                ref={ref}
                {...props}
            />
        </div>
    );
});

const StyledLable = styled.label`
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #87939e;
`;

const StyledTextArea = styled.textarea`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || '100%'};
    max-height: ${({ maxH }) => maxH};
    min-height: 100px;
    border-radius: 5px;
    letter-spacing: 0.6px;
    color: grey;
    font-weight: bold;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    transition: 0.2s;
    background-color: white;
    border: 1px solid #ced4da;
    padding: 10px;
    ::placeholder {
        color: #d0d2d3;
    }
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        background: gray;
    }
    ::-webkit-scrollbar-thumb {
        background: #ced4da;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    :disabled,
    :disabled:hover {
        opacity: ${({ notDisabled }) => (notDisabled ? '1' : '0.5')};
        cursor: not-allowed;
    }
`;
const InputStyled = styled.input`
    width: ${({ width }) => width || '100%'};
    height: ${({ height }) => height || ''};
    max-width: ${({ maxWidth }) => maxWidth || '100%'};
    min-width: ${({ minWidth }) => minWidth || '100%'};
    padding: ${({ padding }) => padding || '10px'};
    border-radius: ${({ borderRadius }) => borderRadius || '5px'};
    letter-spacing: 0.6px;
    color: grey;
    font-weight: bold;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    transition: 0.2s;
    background-color: ${({ background }) => background || '#ffffff'};
    border: ${({ border }) => border || '1px solid #ced4da'};
    ::placeholder {
        color: #d0d2d3;
    }
    :active {
        border: ${({ active }) =>
            active ? '1px solid rgba(31, 88, 174, 0.6)' : ''};
    }
    :focus {
        -webkit-box-shadow: 0px 0px 1px 1px rgba(84, 96, 230, 1);
        -moz-box-shadow: 0px 0px 1px 1px rgba(84, 96, 230, 1);
        box-shadow: 0px 0px 1px 1px rgba(84, 96, 230, 1);
    }
    :disabled,
    :disabled:hover {
        opacity: ${({ notDisabled }) => (notDisabled ? '1' : '0.5')};
        cursor: not-allowed;
    }
    &[type='number']::-webkit-outer-spin-button,
    &[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    @media screen and (max-width: 440px) {
        &[type='date'] {
            width: 280px;
        }
    }
`;
const StyledOptionInsideTitle = styled.span`
    margin-left: 20px;
    font-size: 18px;
    color: grey;
`;
const StyledInsideOption = styled.div`
    width: 100%;
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    background-color: white !important;
    cursor: pointer;
    :hover {
        background-color: aliceblue !important;
    }
`;
const InputStyledSelect = styled.select`
    width: ${({ width }) => width || '100%'};
    min-width: ${({ minWidth }) => minWidth || ''};
    padding: 0.7em 0.8em;
    border-radius: 7px;
    letter-spacing: 0.6px;
    color: grey;
    font-weight: bold;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    transition: 0.2s;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    ::placeholder {
        color: #d0d2d3;
    }
    :active {
        border: 1px solid rgba(31, 88, 174, 0.6);
    }
    :focus {
        border: 1px solid rgba(31, 88, 174, 0.6);
    }
    :disabled,
    :disabled:hover {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

const StyledContaineroption = styled.div`
    width: 100%;
    /* max-width: 722px; */
    height: auto;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 3px;
    margin-top: 5px;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    z-index: 10;
    background-color: #ffffff !important;
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
        background: rgb(204, 204, 204);
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(179, 179, 179);
    }
`;
export default Input;
