import React from 'react';
import {
    MdOutlineDeleteOutline,
    MdSettings,
    MdOutlineOpenInBrowser,
} from 'react-icons/md';
import { BsPrinter } from 'react-icons/bs';
import { MeatBalls } from '../../../components/UI/MeatBalls/MeatBalls';

const AnnouncementMeatBalls = ({
    openDeleteModal,
    openEdditInner,
    innerClick,
    dataID,
    navigationInner,
    iconDollar,
    unnecessaryEdit,
    unnecessaryDelete,
    print,
    printHandler,
}) => {
    const Print = print
        ? {
              id: '0',
              icon: (
                  <BsPrinter
                      color={
                          dataID.appointmentServiceToPatientResponses.length < 1
                              ? 'grey'
                              : dataID.debt === dataID.pricePayable
                              ? 'grey'
                              : '#5460e6'
                      }
                  />
              ),
              name: 'Печать',
              click: () =>
                  dataID.appointmentServiceToPatientResponses.length < 1
                      ? 'grey'
                      : dataID.debt === dataID.pricePayable
                      ? 'grey'
                      : printHandler(dataID),
          }
        : undefined;
    const InnersPage =
        navigationInner === null
            ? undefined
            : {
                  id: '1',
                  icon: iconDollar ? (
                      iconDollar
                  ) : (
                      <MdOutlineOpenInBrowser color="#5460e6" />
                  ),
                  name: iconDollar ? 'Оплата' : 'Открыть',
                  click: () => innerClick(dataID),
              };
    const editPage =
        unnecessaryEdit === null
            ? undefined
            : {
                  id: '2',
                  icon: <MdSettings color="#5460e6" />,
                  name: 'Редактировать',
                  click: () => openEdditInner(dataID),
              };
    const deletePage =
        unnecessaryDelete === null
            ? undefined
            : {
                  id: '3',
                  icon: <MdOutlineDeleteOutline color="#5460e6" />,
                  name: 'Удалить',
                  click: () => openDeleteModal(dataID),
              };

    const navigate = [InnersPage, editPage, deletePage, Print];
    return <MeatBalls options={navigate} />;
};

export default AnnouncementMeatBalls;
