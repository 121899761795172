import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import AnnouncementMeatBalls from '../../../pages/super-admin/Announcement/AnnouncementMeatBalls';
import {
    HeaderTitleAccordioins,
    bodyAccordionData,
} from '../../../utils/helpers/helpers';
import { useSelector } from 'react-redux';
import { ROLES } from '../../../utils/constans/routes';


const AccardionTableMedia = ({
    data,
    deleteHandler,
    editHandler,
    navigationInner,
    page,
    innerClick,
    iconDollar,
    unnecessaryEdit,
    unnecessaryDelete,
    notMeat,
    print,
    onClick,
    printHandler,
}) => {
    const { role } = useSelector((state) => state.auth);

    const navigate = useNavigate();

    const navigationInnerHandler = (item) => {
        if (navigationInner) {
            navigate(
                `${navigationInner}/${
                    item.id ? item.id : item.appointmentId || item.clinicId
                }`
            );
        }
    };
    
    return (
        <Accordions>
            {data?.map((item) => {
                return (
                    <Accordion key={item?.id || Math.random()}>
                        <SummaryAccordion
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Element>
                                <>
                                    {HeaderTitleAccordioins({
                                        item: item,
                                        page: page,
                                    })}
                                </>
                            </Element>
                            <Element>
                                {notMeat === null ? null : item?.userId ===
                                  0 ? null : (
                                    <WrapperMeat
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <AnnouncementMeatBalls
                                            unnecessaryEdit={unnecessaryEdit}
                                            openDeleteModal={deleteHandler}
                                            dataID={item}
                                            print={print}
                                            unnecessaryDelete={
                                                unnecessaryDelete
                                            }
                                            printHandler={printHandler}
                                            openEdditInner={editHandler}
                                            innerClick={
                                                innerClick
                                                    ? innerClick
                                                    : navigationInnerHandler
                                            }
                                            navigationInner={navigationInner}
                                            iconDollar={iconDollar}
                                        />
                                    </WrapperMeat>
                                )}
                            </Element>
                        </SummaryAccordion>
                        <AccordionDetails>
                            <OpenAccordion>
                                {bodyAccordionData(item, page, onClick, role === ROLES.DOCTOR)}
                            </OpenAccordion>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Accordions>
    );
};

export default AccardionTableMedia;

const Accordions = styled.div`
    margin-top: 5px;
    margin-bottom: 15px;
`;

const OpenAccordion = styled.span`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 5px;
    @media (max-width: 250px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
`;
const SummaryAccordion = styled(AccordionSummary)`
    & .MuiAccordionSummary-content {
        display: flex !important;
        justify-content: space-between !important;
    }
    svg {
        font-weight: 600 !important;
        color: #000;
    }
    .MuiAccordionSummary {
        width: 20px;
    }
`;

const Element = styled.div`
    font-weight: 600 !important;
    color: #000;
    text-transform: capitalize;
    display: flex;
    align-items: center;
`;
const WrapperMeat = styled.span`
    margin-right: 10px;
`;
export const StyledActionsStatus = styled.p`
    display: flex;
    border: none;
    max-width: 150px;
    align-items: center;
    justify-content: space-around;
    padding: 6px;
    border-radius: 5px;
    color: white;
    background-color: ${({ bgColor }) =>
        bgColor === 'Оплачено' || bgColor === true
            ? '#2ba802'
            : bgColor === 'Частично' || bgColor === false
            ? '#72bcb2'
            : 'red'};
`;

export const StatusStyled = styled.div`
    color: ${({ status }) => (status === 'ACTIVE' ? '#49cc90' : '#f93e3e')};
`;
