import axios from 'axios';
import { removeWithKeyFromlocalStorage } from '../utils/helpers/helpers';
import { store } from '../store';

const createAxiosOptions = () => {
    const { token } = store.getState().auth;

    const create = { baseURL: 'https://backend.dentapp.online/api' };

    if (token) {
        create.headers = { Authorization: `Bearer ${token}` };
    } else {
        delete create.headers;
    }
    return create;
};

export const axiosConfigInstance = () => {
    const axiosInstance = axios.create(createAxiosOptions());

    axiosInstance.interceptors.request.use((config) => {
        const updatedConfig = { ...config };
        return updatedConfig;
    });

    axiosInstance.interceptors.response.use(
        (response) => {
            return Promise.resolve(response);
        },
        (error) => {
            if (error.response?.status === 401) {
                removeWithKeyFromlocalStorage();
            }
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};
