import React from 'react';
import styled from 'styled-components';

function UICheckbox({ onChange, checked, label, top, widthAndHeight }) {
    return (
        <StyledContainer top={top}>
            <StyledLabel>{label}</StyledLabel>
            <StyledInputCheckbox
                widthAndHeight={widthAndHeight}
                type="checkbox"
                onChange={onChange}
                checked={checked}
            />
        </StyledContainer>
    );
}

export default UICheckbox;

const StyledContainer = styled.div`
    margin-top: ${({ top }) => top || ''};
    display: flex;
    gap: 10px;
`;
const StyledLabel = styled.div`
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #87939e;
`;
const StyledInputCheckbox = styled.input`
    width: ${({ widthAndHeight }) => widthAndHeight || '25px'};
    height: ${({ widthAndHeight }) => widthAndHeight || '25px'};
    border-radius: 12px;
`;
