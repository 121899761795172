import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../../components/UI/Modal/Modal';
import Button from '../../../components/UI/buttons';
import Input from '../../../components/UI/inputs';
import { TiDeleteOutline } from 'react-icons/ti';
import { useDispatch } from 'react-redux';
import { postStock } from '../../../store/slices/stockSlice';

const StockModal = ({ active, setActive }) => {
    const [validation, setValidation] = useState(false);
    const [inputValues, setInputValues] = useState({
        name: '',
        price: 0,
    });

    const dispatch = useDispatch();

    const saveStock = () => {
        dispatch(postStock(inputValues));
        setInputValues({
            name: '',
            price: 0,
        });
        setActive(false);
    };

    function handleClose() {
        setActive(false);
        setInputValues({
            name: '',
            price: 0,
        });
    }
    useEffect(() => {
        setValidation(
            inputValues.price.length > 0 && inputValues.name.length > 0
        );
    }, [inputValues.name.length, inputValues.price.length]);

    return (
        <Modal active={active}>
            <WrapperModalHeader>
                <h2>Товар</h2>
                <TiDeleteOutline
                    cursor="pointer"
                    fontSize="25px"
                    onClick={() => handleClose()}
                />
            </WrapperModalHeader>
            <WrapperModalInputs>
                <Input
                    value={inputValues.name}
                    type="text"
                    label="Название"
                    onChange={(e) =>
                        setInputValues({
                            ...inputValues,
                            name: e.target.value,
                        })
                    }
                />
                <Input
                    value={inputValues.price}
                    type="number"
                    label="Цена"
                    onChange={(e) =>
                        setInputValues({
                            ...inputValues,
                            price: e.target.value,
                        })
                    }
                />
            </WrapperModalInputs>
            <WrapperSubmitButton>
                <Button
                    borderRadius="5px"
                    color="#5460e6"
                    backgroundColor="#ffffff"
                    border="1px solid #5460e6"
                    onClick={() => handleClose()}
                >
                    Отмена
                </Button>
                <Button
                    disabled={!validation}
                    borderRadius="5px"
                    onClick={() => saveStock()}
                >
                    Сохранить
                </Button>
            </WrapperSubmitButton>
        </Modal>
    );
};

const WrapperModalHeader = styled.div`
    min-width: 450px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        color: #000000;
    }
    @media screen and (max-width: 670px) {
        min-width: 250px;
    }
`;
const WrapperModalInputs = styled.div`
    margin-top: 10px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
`;
const WrapperSubmitButton = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding-left: 60%;
    margin-top: 10px;
`;

export default StockModal;
