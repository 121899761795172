import React, { useRef, useEffect } from 'react';
import { BiSupport } from 'react-icons/bi';
import { BsCheck2, BsCheck2All, BsThreeDotsVertical } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { MdDeleteOutline } from 'react-icons/md';
import { RiPencilLine } from 'react-icons/ri';
import {
    useDeleteSupportMutation,
    useGetChatSupportAdminQuery,
} from '../../../../services/chat.service';
import { showSuccessMessage } from '../../../../components/notification/Notification';

const MessageMain = () => {
    const { clinicData } = useSelector((state) => state.clinic);

    const [handleDelete] = useDeleteSupportMutation();

    const { id } = useParams();

    const { data: adminSupport } = useGetChatSupportAdminQuery(id);

    const messagesEndRef = useRef(null);

    useEffect(() => {
        messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }, [adminSupport]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const ID = open ? 'simple-popover' : undefined;

    const deleteHandler = async (ID) => {
        try {
            const response = await handleDelete(ID).unwrap();
            showSuccessMessage(response.message);
        } catch (error) {}
    };

    return (
        <Wrapper ref={messagesEndRef}>
            {adminSupport?.messageResponses?.map((item, index) => (
                <>
                    {item.channel !== true ? (
                        <WrapperMessage key={index}>
                            <Logoimg
                                src={clinicData?.imageResponse?.link}
                                alt="logo"
                            />
                            <WrapperMessageInner>
                                <div className="bubble left">
                                    {item?.imageResponse?.link ? (
                                        <img
                                            src={item?.imageResponse?.link}
                                            alt="Фото"
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {item?.message}
                                    <StyledLable>
                                        <WrapperTime>
                                            <span>
                                                {item.messageTime.slice(0, 10)}{' '}
                                                {item.messageTime.slice(11, 16)}
                                            </span>
                                        </WrapperTime>
                                    </StyledLable>
                                </div>
                            </WrapperMessageInner>
                        </WrapperMessage>
                    ) : (
                        <WrapperRight key={index}>
                            <WrapperMessageInner>
                                <WrapperDOTS
                                    aria-describedby={ID}
                                    variant="contained"
                                    onClick={handleClick}
                                >
                                    <BsThreeDotsVertical />
                                </WrapperDOTS>
                                <div className="bubble right">
                                    {item?.imageResponse?.link ? (
                                        <img
                                            src={item?.imageResponse?.link}
                                            alt="Фото"
                                        />
                                    ) : (
                                        ''
                                    )}
                                    {item?.message}

                                    <Popover
                                        id={ID}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: -100,
                                            horizontal: -100,
                                        }}
                                    >
                                        <WrapperPopverItem
                                            onClick={() =>
                                                deleteHandler(item.messageId)
                                            }
                                        >
                                            <MdDeleteOutline fontSize="20px" />
                                            <span>Удалить</span>
                                        </WrapperPopverItem>
                                        <WrapperPopverItem>
                                            <RiPencilLine fontSize="20px" />
                                            <span>Редактировать</span>
                                        </WrapperPopverItem>{' '}
                                    </Popover>
                                    <StyledLable>
                                        <WrapperTime>
                                            <span>
                                                {item.messageTime.slice(0, 10)}{' '}
                                                {item.messageTime.slice(11, 16)}
                                            </span>
                                            <WrapperRead>
                                                {item.read ? (
                                                    <BsCheck2All
                                                        color={
                                                            item.read
                                                                ? '#5460e6'
                                                                : ''
                                                        }
                                                    />
                                                ) : (
                                                    <BsCheck2 />
                                                )}
                                            </WrapperRead>
                                        </WrapperTime>
                                    </StyledLable>
                                </div>
                            </WrapperMessageInner>

                            <WrapperLogo>
                                <BiSupport fontSize="25px" />
                            </WrapperLogo>
                        </WrapperRight>
                    )}
                </>
            ))}
        </Wrapper>
    );
};

const WrapperPopverItem = styled.div`
    padding: 0.5rem;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: #8b96a1;
    transition: 0.4s;
    :hover {
        transition: 0.4s;
        color: black;
        background-color: #f2f6fa;
    }
`;

const WrapperDOTS = styled.div`
    position: absolute;
    top: 10px;
    cursor: pointer;
    color: #ffffff;
    font-size: 20px;
`;
const WrapperRead = styled.span`
    font-size: 20px;
`;
const WrapperTime = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
`;

const WrapperMessageInner = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;
const StyledLable = styled.p`
    font-family: 'PT Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #87939e;
`;
const WrapperMessage = styled.div`
    display: flex;
    align-items: flex-end;
`;

const WrapperLogo = styled.div`
    background-color: #5460e6;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 40px;
    height: 40px;
    color: #ffffff;
    @media screen and (max-width: 650px) {
        scale: 0.7;
        width: 28px;
        height: 28px;
    }
`;
const Logoimg = styled.img`
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 50px;
    height: 50px;
    color: #ffffff;
    @media screen and (max-width: 650px) {
        scale: 0.7;
        width: 28px;
        height: 28px;
    }
`;

const Wrapper = styled.div`
    height: 60vh;
    width: 800px;
    background-color: #537188;
    border-radius: 20px;
    padding: 5px 5px 10px;
    overflow-y: auto;
    ::-webkit-scrollbar {
        width: 0;
    }

    margin: 20px 0;
    width: 100%;
    display: grid;
    gap: 20px;
    font-family: system-ui, sans-serif;
    font-size: 18px;
    @media screen and (max-width: 650px) {
        font-size: 14px;
    }
    .bubble {
        --r: 25px; /* the radius */
        --t: 30px; /* the size of the tail */

        max-width: 400px;

        padding: calc(2 * var(--r) / 3);
        -webkit-mask: radial-gradient(
                    var(--t) at var(--_d) 0,
                    #0000 98%,
                    #000 102%
                )
                var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,
            conic-gradient(at var(--r) var(--r), #000 75%, #0000 0)
                calc(var(--r) / -2) calc(var(--r) / -2) padding-box,
            radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r)
                var(--r) space padding-box;
        background: #ffffff;
        color: #000;
        @media screen and (max-width: 650px) {
            max-width: 300px;
        }
    }
    .left {
        --_d: 0%;
        border-left: var(--t) solid #0000;
        margin-right: var(--t);
        place-self: start;
        display: flex;
        flex-direction: column;
        word-break: break-all;
    }
    .right {
        --_d: 100%;
        border-right: var(--t) solid #0000;
        margin-left: var(--t);
        place-self: end;
        display: flex;
        flex-direction: column;
        word-break: break-all;
    }
`;

const WrapperRight = styled.div`
    --_d: 100%;
    border-right: var(--t) solid #0000;
    margin-left: var(--t);
    place-self: end;
    display: flex;
    align-items: flex-end;
`;

export default MessageMain;
