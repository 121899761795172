import Banner from '../../assets/images/photoBannerCustom.svg';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import SliderOur from './slider';
import FooterPublish from './footer';
import HeaderPublish from './header';
import CountUps from './count-up';
import DentApp from './dentapp';
import FeaturesKey from './key-features';
import OurServices from './our-services';
import PricingPage from './pricing';
import { useRef } from 'react';

export const PublishPage = () => {
    const tariffsRef = useRef(null);

    const scrollToTariffs = () => {
        if (tariffsRef.current) {
            tariffsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return (
        <div className="w-full">
            <Helmet>
                <title>
                    Автоматизация стоматологии | Тарифы приложения DentApp
                </title>
                <meta
                    name="description"
                    content="Тарифные планы приложения для стоматологии, программа для стоматологии DentApp, CRM Бишкек, автоматизация стоматологии Бишкек, 
                    готовое решение для стоматологии, онлайн календарь, мобильное приложение для стоматологов, электронный журнал, 
                    все для стоматологов"
                />
                <meta
                    name="keywords"
                    content="Программа для стоматологии  Бишкек, Автоматизация стоматологии Бишкек, Программа для стоматологической клиники, 
                    Программа для стоматологии цена, CRM для стоматологии Кыргызстан, Программа для автоматизации, 
                    Каракол, Кыргызстан, тестовый период,DentApp, тарифы, услуги, стоматология"
                ></meta>
            </Helmet>
            <HeaderPublish />
            <div className="w-full h-[500px] sm:h-[200px] mt-[80px] sm:mt-[66px] bg-gradient-to-r from-[#5361e7]/40 via-[#5361e7]/60 to-[#5361e7]/90 relative z-10 flex justify-center items-center">
                <div className="w-full max-w-[1440px] flex items-center justify-center">
                    <motion.div
                        initial={{ x: -200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ delay: 0.1 }}
                        className="w-[50%] px-12 sm:px-6"
                    >
                        <h1 className="text-[45px] sm:text-[12px] font-semibold text-black/80">
                            Программа для стоматологических клиник
                        </h1>
                        <div className="text-[25px] sm:text-[10px] w-full max-w-[340px] text-black/80">
                            Готовое решение для управления стоматологией
                        </div>
                        <button
                            onClick={scrollToTariffs}
                            className="px-14 py-2 rounded-lg bg-[#1c4ed8] text-white mt-10 sm:px-6 sm:text-[10px] sm:py-1 sm:mt-4"
                        >
                            Тарифы
                        </button>
                    </motion.div>
                    <motion.div
                        initial={{ x: 200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        className="w-[50%] h-full max-h-[500px] sm:max-h-[200px]"
                    >
                        <img
                            className="w-full h-full max-h-[500px] sm:max-h-[200px]"
                            src={Banner}
                            alt="banner"
                        />
                    </motion.div>
                </div>
            </div>
            <main className="w-full flex flex-col items-start justify-center px-[60px] xs:px-[20px] py-5 max-w-[1440px] flex-wrap mx-auto">
                <DentApp />
            </main>
            <SliderOur />
            <CountUps />
            <main className="w-full flex flex-col items-start justify-center py-5 max-w-[1440px] mx-auto">
                <FeaturesKey />
            </main>
            <OurServices />
            <main
                ref={tariffsRef}
                className="w-full flex flex-col items-start justify-center px-[40px] py-5 max-w-[1440px] mx-auto"
            >
                <PricingPage />
            </main>
            <FooterPublish />
        </div>
    );
};
