import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SmallSpinnerIcon } from '../../../assets/icons/loading-spinner.svg';

export const SmallSpinner = () => {
    return (
        <Wrapper>
            <StyledSmallSpinner />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
`;

const StyledSmallSpinner = styled(SmallSpinnerIcon)`
    animation: LOADING linear 2s infinite;
    width: 60px;
    height: 60px;
    @keyframes LOADING {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
