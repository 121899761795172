import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getSingleDocument } from '../../../../store/slices/documentSlice';
import styled from 'styled-components';
import { BiArrowBack } from 'react-icons/bi';
import {
    deleteMagazine,
    getMagazine,
} from '../../../../store/slices/magazineSlice';
import { UITable } from '../../../../components/UI/tables/UITable';
import { SmallSpinner } from '../../../../components/UI/Spinners/SmallSpinner';
import { useState } from 'react';
import { MdEdit, MdOutlineDeleteOutline } from 'react-icons/md';
import DeleteModal from '../../../../components/UI/delete-modal/DeleteModal';
import JurnalEditModal from './JurnalEditModal';

const InnerJurnal = () => {
    const { insideDocument } = useSelector((state) => state.document);
    const { magazine, isLoading } = useSelector((state) => state.magazine);

    const [magazineData, setMagazineData] = useState();

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMagazine({ documentID: id }));
        dispatch(getSingleDocument({ documentID: id }));
    }, [dispatch, id]);

    useEffect(() => {
        setMagazineData(magazine?.journalResponses);
    }, [magazine]);

    const [itemData, setItemData] = useState();

    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const showEditModalHandler = (item) => {
        setItemData(item);
        setShowEditModal(true);
    };
    const showDeleteModalHandler = (item) => {
        setItemData(item);
        setShowDeleteModal(true);
    };

    const deleteHandler = () => {
        dispatch(deleteMagazine({ documentID: id, magazineID: itemData.id }));
        setShowDeleteModal(false);
    };

    const MAGAZINE_HEADER = useMemo(
        () => [
            {
                title: 'ID',
                accessKey: 'id',
                id: 1,
            },
            {
                title: 'Называние',
                accessKey: 'name',
                id: 2,
            },
            {
                title: 'Количество',
                accessKey: 'quantity',
                id: 3,
            },
            {
                title: 'Цена',
                accessKey: 'price',
                id: 4,
            },
            {
                title: 'Сумма',
                accessKey: 'totalPrice',
                id: 5,
            },
            {
                title: 'Действия',
                accessKey: 'actions',
                id: 6,
                action: (item) => (
                    <StyledActions key={Math.random().toString()}>
                        <MdEdit
                            fontSize="20px"
                            onClick={() => showEditModalHandler(item)}
                        />
                        <MdOutlineDeleteOutline
                            fontSize="20px"
                            onClick={() => showDeleteModalHandler(item)}
                        />
                    </StyledActions>
                ),
            },
        ],
        []
    );

    return (
        <Wrapper>
            <Header>
                <WrapperName onClick={() => navigate('/stock')}>
                    <ArrowStyled>
                        <BiArrowBack
                            size="25px"
                            color="#5460e6"
                            cursor="pointer"
                        />
                    </ArrowStyled>
                    <h1>{insideDocument?.supplier}</h1>
                </WrapperName>
                <WrapperrightBlock>
                    <h2>
                        Количестов: <span>{magazine?.quantity} </span>
                    </h2>
                    <h2>
                        Общая сумма: <span>{magazine?.totalPrice}</span>
                    </h2>
                </WrapperrightBlock>
            </Header>
            {isLoading ? (
                <SmallSpinner />
            ) : (
                <UITable data={magazineData} columns={MAGAZINE_HEADER} />
            )}
            <JurnalEditModal
                active={showEditModal}
                setActive={setShowEditModal}
                itemData={itemData}
            />
            <DeleteModal
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                deleteHandler={deleteHandler}
                title={itemData?.name}
            />
        </Wrapper>
    );
};
const Wrapper = styled.div`
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 14px;
`;
const ArrowStyled = styled.div`
    position: relative;
    :hover {
        transform: translateX(-15%);
        transition: ease-in-out 0.5s;
    }
`;
const WrapperName = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    h1 {
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color: #5460e6;
        text-decoration: underline;
        cursor: pointer;
    }
`;
const WrapperrightBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    font-family: 'PT Sans';
    span {
        font-family: 'PT Sans';
        font-weight: 600;
    }
`;
const StyledActions = styled.div`
    cursor: pointer;
    display: flex;
    border: none;
    align-items: center;
    justify-content: space-around;
    margin: 20px;
    gap: 5px;
`;
export default InnerJurnal;
