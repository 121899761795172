import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const TabsUI = ({ tabHeads, children, tabKey, getValue = (fol) => false}) => {
    const [value, setValue] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const param = queryParams.get(tabKey);

        if (param) {
            const index = tabHeads.indexOf(param);
            setValue(index === -1 ? 0 : index);
        }
    }, [location.search, tabHeads, tabKey]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        navigate(`?${tabKey}=${tabHeads[newValue]}`);
    };

    useEffect(() => {
        getValue(value)
    }, [value])

    return (
        <Box sx={{ width: '100%' }}>
            <StyledBox matches="true">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    allowScrollButtonsMobile={true}
                    sx={{ width: '100%' }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: '#5460e6',
                        },
                    }}
                >
                    {tabHeads.map((headTitle, index) => (
                        <Tab key={headTitle} label={headTitle} />
                    ))}
                </Tabs>
            </StyledBox>
            {children.map((child, index) => (
                <div key={index} hidden={value !== index}>
                    {value === index && child}
                </div>
            ))}
        </Box>
    );
};

const StyledBox = styled(Box)`
    border-bottom: 1;
    border-color: divider;
    overflow-x: ${({ matches }) =>
        matches !== 'false' ? 'initial' : 'scroll'};
    color: #5460e6 !important;
    button {
        color: #5460e6 !important;
    }
    span {
        color: #5460e6 !important;
    }
    & .MuiTabs-scrollButtons.Mui-disabled {
        opacity: 0.3;
    }
    & .MuiTabs-root {
        width: 100%;
    }
`;

export default TabsUI;
