import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

const Cards = ({ title, description, id, meatBalls, innerClick }) => {
    return (
        <>
            <Card
                sx={{
                    minWidth: 275,
                    border: '1px solid #e5e7eb;',
                    borderRadius: '7px',
                }}
            >
                <CardContent>
                    <TextHorizon>
                        <Typography
                            sx={{ fontSize: 14 }}
                            color="text.secondary"
                            gutterBottom
                            onClick={() => innerClick(id)}
                        >
                            Название
                        </Typography>
                        {meatBalls}
                    </TextHorizon>
                    <ClickedDiv onClick={() => innerClick(id)}>
                        <StyledText variant="h5" component="div">
                            {title}
                        </StyledText>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            Описание
                        </Typography>
                        <StyledText variant="body2">{description}</StyledText>
                    </ClickedDiv>
                </CardContent>
            </Card>
        </>
    );
};

export default Cards;
const ClickedDiv = styled.div`
    width: 100%;
    cursor: pointer;
`;
const StyledText = styled(Typography)`
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const TextHorizon = styled.div`
    display: flex;
    justify-content: space-between;
`;
