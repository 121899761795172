import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const getPayRoll = createAsyncThunk(
    'payrollCalculations/getPayRoll',
    async (_, { rejectWithValue }) => {
        try {
            const responce = await axiosConfigInstance().get(
                'payroll-calculations'
            );
            return responce.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);
export const postPayRoll = createAsyncThunk(
    'payrollCalculations/postPayRoll',
    async ({ data }, { rejectWithValue, dispatch }) => {
        try {
            const responce = await axiosConfigInstance().post(
                'payroll-calculations',
                data
            );
            showSuccessMessage(responce.data.message);
            dispatch(getPayRoll());
            return responce.data;
        } catch (error) {
            showErrorMessage(error.message);
            rejectWithValue(error.message);
        }
    }
);

export const putPayRoll = createAsyncThunk(
    'payrollCalculations/putPayRoll',
    async ({ id, salary }, { rejectWithValue, dispatch }) => {
        try {
            const responce = await axiosConfigInstance().put(
                `payroll-calculations/${id}?salary=${salary}`
            );
            showSuccessMessage(responce.data.message);
            dispatch(getPayRoll());
            return responce.data;
        } catch (error) {
            showErrorMessage(error.message);
            rejectWithValue(error.message);
        }
    }
);
export const putPayRollAddUsers = createAsyncThunk(
    'payrollCalculations/putPayRollAddUsers',
    async ({ id, userIds }, { rejectWithValue, dispatch }) => {
        try {
            const responce = await axiosConfigInstance().put(
                `payroll-calculations/${id}/add/users?userIds=${userIds}`
            );
            showSuccessMessage(responce.data.message);
            dispatch(getPayRoll());
            return responce.data;
        } catch (error) {
            showErrorMessage(error.message);
            rejectWithValue(error.message);
        }
    }
);
export const putPayRollRemoveUsers = createAsyncThunk(
    'payrollCalculations/putPayRollRemoveUsers',
    async ({ userIds }, { rejectWithValue, dispatch }) => {
        try {
            const responce = await axiosConfigInstance().put(
                `payroll-calculations/remove/users?userIds=${userIds}`
            );
            showSuccessMessage(responce.data.message);
            dispatch(getPayRoll());
            return responce.data;
        } catch (error) {
            showErrorMessage(error.message);
            rejectWithValue(error.message);
        }
    }
);

export const deletePayRoll = createAsyncThunk(
    'payrollCalculations/deletePayRoll',
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {
            const responce = await axiosConfigInstance().delete(
                `payroll-calculations/${id}`
            );
            showSuccessMessage(responce.data.message);
            dispatch(getPayRoll());
            return responce.data;
        } catch (error) {
            showErrorMessage(error.message);
            rejectWithValue(error.message);
        }
    }
);
const initialState = {
    payRoll: [],
    isLoading: false,
};

const payrollCalculationsSlice = createSlice({
    name: 'payrollCalculations',
    initialState,
    reducers: {},
    extraReducers: {
        [getPayRoll.pending]: (state, { payload }) => {
            state.isLoading = true;
        },
        [getPayRoll.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.payRoll = payload;
        },
        [getPayRoll.pending]: (state, { payload }) => {
            state.isLoading = false;
        },
    },
});

export default payrollCalculationsSlice;
