import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const postAvatar = createAsyncThunk(
    'client/postAvatar',
    async ({ file }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const res = await axiosConfigInstance().post(
                '/files/AVATAR',
                formData
            );

            const imageId = await res.data.imageId;
            return imageId;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectWithValue(error.message);
        }
    }
);

export const getClients = createAsyncThunk(
    'client/getClients',
    async (
        { START_DATE, END_DATE, TARIFF_ID, STATUS, SEARCH, currentPage },
        { rejectedWithValue }
    ) => {
        try {
            const response = await axiosConfigInstance().get(
                `clinics?${SEARCH ? `search=${SEARCH}` : ''}${
                    TARIFF_ID ? `tariffType=${TARIFF_ID}` : ''
                }${STATUS ? `&status=${STATUS}` : ''}${
                    START_DATE ? `&start=${START_DATE}` : ''
                }${
                    END_DATE ? `&end=${END_DATE}` : ''
                }&page=${currentPage}&size=10`
            );
            return response.data;
        } catch (error) {
            return rejectedWithValue(error.message);
        }
    }
);

export const getClinicPaymentHistory = createAsyncThunk(
    'client/getClinicPaymentHistory',
    async ({ CLINIC_ID }, { rejectWithValue }) => {
        try {
            const responce = await axiosConfigInstance().get(
                `payments/clinics/${CLINIC_ID}/history`
            );
            return responce.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

export const postClinic = createAsyncThunk(
    'client/postClinic',
    async ({ TARIF, data, successsNavigate }, { rejectedWithValue }) => {
        try {
            const response = await axiosConfigInstance().post(
                `clinics/${TARIF}`,
                data
            );
            showSuccessMessage(response.data.message);
            successsNavigate(`/clients/clinic/${response?.data.id}`);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectedWithValue(error.message);
        }
    }
);

export const getSingleClients = createAsyncThunk(
    'client/getSingleClients',
    async ({ ID }, { rejectedWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(
                `clinics/${ID}/about`
            );
            return response.data;
        } catch (error) {
            return rejectedWithValue(error.message);
        }
    }
);

export const putClinic = createAsyncThunk(
    'client/putClinic',
    async (
        { ClientData, CLINIC_ID, ADMIN_ID, TARIFF_ID },
        { rejectedWithValue, dispatch }
    ) => {
        try {
            const response = await axiosConfigInstance().put(
                `clinics/${CLINIC_ID}/${ADMIN_ID}/${TARIFF_ID}`,
                ClientData
            );
            showSuccessMessage(response.data.message);
            dispatch(getSingleClients({ ID: CLINIC_ID }));
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectedWithValue(error.message);
        }
    }
);

export const deleteClinic = createAsyncThunk(
    'client/deleteClinic',
    async ({ ID, navigateAfterDelete }, { rejectedWithValue }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `clinics/${ID}`
            );
            showSuccessMessage(response.data.message);
            navigateAfterDelete();
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectedWithValue(error.message);
        }
    }
);

export const postPaymentTariff = createAsyncThunk(
    'client/postPaymentTariff',
    async (
        { data, hideModalHandler, showCheckHandler },
        { rejectedWithValue, dispatch }
    ) => {
        try {
            const response = await axiosConfigInstance().post(
                `payment-tariffs/tariffs`,
                data
            );
            dispatch(getClinicPaymentHistory({ CLINIC_ID: data.clinicId }));
            showSuccessMessage(response.data.message);
            hideModalHandler();
            showCheckHandler();
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectedWithValue(error.message);
        }
    }
);

const initialState = {
    clients: [],
    paymentHistory: [],
    singleClinic: null,
    isLoading: false,
};
export const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {},
    extraReducers: {
        [getClients.pending]: (state) => {
            state.isLoading = true;
        },
        [getClients.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.clients = action.payload;
        },
        [getClients.rejected]: (state) => {
            state.isLoading = false;
        },
        //SINGLE
        [getSingleClients.pending]: (state) => {
            state.isLoading = true;
        },
        [getSingleClients.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.singleClinic = action.payload;
        },
        [getSingleClients.rejected]: (state) => {
            state.isLoading = false;
        },
        // HISTORY
        [getClinicPaymentHistory.pending]: (state) => {
            state.isLoading = true;
        },
        [getClinicPaymentHistory.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.paymentHistory = action.payload;
        },
        [getClinicPaymentHistory.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default clientSlice;
