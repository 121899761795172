import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const getStocks = createAsyncThunk(
    'stock/getStocks',
    async ({ search, currentPage }, { rejectWithValue }) => {
        try {
            if (search) {
                const responce = await axiosConfigInstance().get(
                    `/items?search=${search}`
                );
                return responce.data;
            } else {
                const responce = await axiosConfigInstance().get(
                    `items?page=${currentPage}&size=10`
                );
                return responce.data;
            }
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const getAllStocks = createAsyncThunk(
    'stock/getStocks',
    async (_, { rejectWithValue }) => {
        try {
            const responce = await axiosConfigInstance().get(`items`);
            return responce.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const postStock = createAsyncThunk(
    'stock/postStock',
    async (newStock, { rejectWithValue, dispatch }) => {
        try {
            const responce = await axiosConfigInstance().post(
                '/items',
                newStock
            );
            showSuccessMessage(responce.data.message);
            dispatch(getAllStocks());
            return responce.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);
export const deleteStock = createAsyncThunk(
    'stock/deleteStock',
    async ({ ID }, { rejectWithValue, dispatch }) => {
        try {
            const responce = await axiosConfigInstance().delete(`items/${ID}`);
            showSuccessMessage(responce.data.message);
            dispatch(getAllStocks());
            return responce.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const putStock = createAsyncThunk(
    'stock/putStock',
    async ({ data, ID }, { rejectWithValue, dispatch }) => {
        try {
            const responce = await axiosConfigInstance().put(
                `items/${ID}`,
                data
            );
            showSuccessMessage(responce.data.message);
            dispatch(getAllStocks());
            return responce.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

const initialState = {
    stock: [],
    allStock: [],
    isLoading: false,
};

const stockSlice = createSlice({
    name: 'stock',
    initialState,
    reducers: {},
    extraReducers: {
        [getStocks.pending]: (state) => {
            state.isLoading = true;
        },
        [getStocks.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.stock = payload;
        },
        [getStocks.rejected]: (state) => {
            state.isLoading = false;
        },
        [getAllStocks.pending]: (state) => {
            state.isLoading = true;
        },
        [getAllStocks.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.allStock = payload;
        },
        [getAllStocks.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default stockSlice;
