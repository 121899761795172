import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { DOCTOR_ROUTES } from '../utils/constans/routes';
import { Spinner } from '../components/UI/Spinners/Spinner';
import NotFoundPage from '../pages/error-page/NotFoundPage';

const DashboardDoctor = React.lazy(() =>
    import('../layout/dashboard/DashboardDoctor.jsx')
);
const Calender = React.lazy(() => import('../pages/admin/calendar/Calendar'));
const InnerStaffPage = React.lazy(() =>
    import('../pages/admin/stuff/inner-page')
);
const PatientsPage = React.lazy(() =>
    import('../pages/admin/patients/PatientsPage')
);
const InnerPagePatient = React.lazy(() =>
    import('../pages/admin/patients/inner-page')
);
const VisitPage = React.lazy(() =>
    import('../components/inner-pages/visit/VisitPage')
);
const ServicesPage = React.lazy(() =>
    import('../pages/admin/services/ServicesPage')
);
const Announcements = React.lazy(() =>
    import('../pages/admin/announcements/Announcements')
);
const AnnouncementsInner = React.lazy(() =>
    import('../pages/admin/announcements/AnnouncementsInner')
);
export const DoctorRoutes = () => {
    const { DOCTOR, CALENDAR, PATIENTS, STAFF, SERVICES, ANNOUNCEMENTS } =
        DOCTOR_ROUTES;
    return (
        <Suspense fallback={<Spinner />}>
            <Routes>
                <Route path={DOCTOR.path} element={<DashboardDoctor />}>
                    <Route
                        path="/login"
                        element={<Navigate to={DOCTOR.path} />}
                    />
                    <Route path="/" element={<Navigate to={CALENDAR.path} />} />
                    <Route path={CALENDAR.path} element={<Calender />} />
                    <Route
                        path={`${STAFF.path}/doctor/:id`}
                        element={<InnerStaffPage />}
                    />
                    <Route path={PATIENTS.path} element={<PatientsPage />} />
                    <Route
                        path={`${PATIENTS.path}/user/:id`}
                        element={<InnerPagePatient />}
                    />
                    <Route
                        path={`${PATIENTS.path}/user/:patientId/visit/:id`}
                        element={<VisitPage />}
                    />
                    <Route path={SERVICES.path} element={<ServicesPage />} />

                    <Route
                        path={ANNOUNCEMENTS.path}
                        element={<Announcements />}
                    />
                    <Route
                        path={`${ANNOUNCEMENTS.path}/announcements-inner/:id`}
                        element={<AnnouncementsInner />}
                    />
                    <Route path="/*" element={<NotFoundPage />} />
                </Route>
            </Routes>
        </Suspense>
    );
};
