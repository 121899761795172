import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const specialityService = createApi({
    reducerPath: 'specialityApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getSpeciality: builder.query({
            query: ({ page }) => `specialities?page=${page}&size=10`,
            providesTags: ['speciality'],
        }),
        postSpeciality: builder.mutation({
            query: (name) => ({
                url: `specialities?name=${name}`,
                method: 'POST',
            }),
            invalidatesTags: ['speciality'],
        }),
        deleteSpeciality: builder.mutation({
            query: (specialityId) => ({
                url: `specialities/${specialityId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['speciality'],
        }),
        updateSpeciality: builder.mutation({
            query: ({ specialityId, name }) => ({
                url: `specialities/${specialityId}?name=${name}`,
                method: 'PUT',
            }),
            invalidatesTags: ['speciality'],
        }),
        getSpecialitySelect: builder.query({
            query: (userId) => `users/${userId}/specialities/select`,
            providesTags: ['speciality'],
        }),
        postSpecialityUserId: builder.mutation({
            query: ({ userId, serviceArray }) => {
                return {
                    url: `users/${userId}/specialities?${serviceArray}`,
                    method: 'POST',
                };
            },
            invalidatesTags: ['speciality'],
        }),
        getSpecialityUserId: builder.query({
            query: (userId) => `users/${userId}/specialities`,
            providesTags: ['speciality'],
        }),
        deleteSpecialityUserId: builder.mutation({
            query: ({ userId, specialityId }) => {
                return {
                    url: `users/${userId}/specialities/${specialityId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['speciality'],
        }),
    }),
});
export default specialityService;

export const {
    useGetSpecialityQuery,
    usePostSpecialityMutation,
    useDeleteSpecialityMutation,
    useUpdateSpecialityMutation,
    useGetSpecialitySelectQuery,
    usePostSpecialityUserIdMutation,
    useGetSpecialityUserIdQuery,
    useDeleteSpecialityUserIdMutation,
} = specialityService;
