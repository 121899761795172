import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const userService = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['personal'],
    endpoints: (builder) => ({
        getPersonal: builder.query({
            query: ({ currentPage, searchValue }) =>
                `users?page=${currentPage}&size=10${
                    searchValue ? `&search= ${searchValue} ` : ''
                }`,
            providesTags: ['personal'],
        }),
        getManager: builder.query({
            query: ({ currentPage, searchValue }) =>
                `users/managers?page=${currentPage}&size=10${
                    searchValue ? `&search= ${searchValue} ` : ''
                }`,
            providesTags: ['personal'],
        }),
        getPersonalById: builder.query({
            query: (id) => `/users/${id}`,
            providesTags: ['personal'],
        }),
        postPersonal: builder.mutation({
            query: (body) => {
                return {
                    url: 'users',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['personal'],
        }),
        deletePersonal: builder.mutation({
            query: (id) => {
                return {
                    url: `users/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['personal'],
        }),

        deletePersonalAppointment: builder.mutation({
            query: (appointmentId) => {
                return {
                    url: `appointments/${appointmentId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['personal'],
        }),
        updatePersonal: builder.mutation({
            query: ({ data, id, imageId }) => {
                return {
                    url: imageId
                        ? `users/${id}?imageId=${imageId}`
                        : `users/${id}`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['personal'],
        }),
    }),
});
export default userService;

export const {
    useGetPersonalQuery,
    useGetPersonalByIdQuery,
    usePostPersonalMutation,
    useUpdatePersonalMutation,
    useDeletePersonalMutation,
    useGetManagerQuery,
    useLazyGetPersonalByIdQuery,
    useDeletePersonalAppointmentMutation,
} = userService;
