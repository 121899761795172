import { FaRobot } from 'react-icons/fa';
import { IoMdNotifications } from 'react-icons/io';
import { FaMailBulk } from 'react-icons/fa';

export default function OurServices() {
    return (
        <div className="w-full bg-gradient-to-r from-[#5361e7]/40 via-[#5361e7]/60 to-[#5361e7]/90 relative z-10 flex flex-col justify-start items-center pt-[12px] py-12">
            <h1 className="text-[30px] text-white font-medium max-w-[540px] text-center">
                Маркетинг, уведомления и рассылки. WhatsApp, SMS
            </h1>
            <div className="w-[80%] mt-[40px] flex items-center justify-center flex-wrap gap-10">
                <div className="w-[470px] min-h-[200px] h-auto border rounded-2xl p-3 flex flex-col justify-start items-center">
                    <div className="w-full flex items-center justify-start gap-4">
                        <div className="w-[45px] h-[45px] min-w-[45px] min-h-[40px] rounded-full flex items-center justify-center bg-[#1c4ed8]">
                            <FaRobot size={30} color="white" />
                        </div>
                        <h1 className="text-[20px] text-white font-semibold">
                            Автоматические роботы
                        </h1>
                    </div>
                    <div className="text-[15px] text-white w-full h-full mt-3">
                        Автоматические роботы уведомлений напомнят пациентам о
                        том, что они записаны на приём.
                    </div>
                </div>
                <div className="w-[470px] min-h-[200px] h-auto border rounded-2xl p-3 flex flex-col justify-start items-center">
                    <div className="w-full flex items-center justify-start gap-4">
                        <div className="w-[45px] h-[45px] min-w-[45px] min-h-[40px] rounded-full flex items-center justify-center bg-[#1c4ed8]">
                            <IoMdNotifications size={30} color="white" />
                        </div>
                        <h1 className="text-[20px] text-white font-semibold">
                            Уведомления для докторов
                        </h1>
                    </div>
                    <div className="text-[15px] text-white w-full h-full mt-3">
                        Роботы уведомят доктора об отменённой записи или сообщат
                        от том, что пациент пришёл в клинику.
                    </div>
                </div>
                <div className="w-[470px] min-h-[200px] h-auto border rounded-2xl p-3 flex flex-col justify-start items-center">
                    <div className="w-full flex items-center justify-start gap-4">
                        <div className="w-[45px] h-[45px] min-w-[45px] min-h-[40px] rounded-full flex items-center justify-center bg-[#1c4ed8]">
                            <IoMdNotifications size={30} color="white" />
                        </div>
                        <h1 className="text-[20px] text-white font-semibold">
                            Уведомления для администратора
                        </h1>
                    </div>
                    <div className="text-[15px] text-white w-full h-full mt-3">
                        Роботы уведомят администратора о созданных задачах, о
                        том, что нужно связаться с пациентом.
                    </div>
                </div>
                <div className="w-[470px] min-h-[200px] h-auto border rounded-2xl p-3 flex flex-col justify-start items-center">
                    <div className="w-full flex items-center justify-start gap-4">
                        <div className="w-[45px] h-[45px] min-w-[45px] min-h-[40px] rounded-full flex items-center justify-center bg-[#1c4ed8]">
                            <FaMailBulk size={25} color="white" />
                        </div>
                        <h1 className="text-[20px] text-white font-semibold">
                            Массовые рассылки по группам пациентов
                        </h1>
                    </div>
                    <div className="text-[15px] text-white w-full h-full mt-3">
                        Массовые рассылки по группам пациентов, по критериям,
                        позволят сделать массовые рассылки, для определённой
                        категории пациентов, уведомлять о событиях, а также
                        позволят создавать массовые задачи на обзвон пациентов.
                    </div>
                </div>
            </div>
        </div>
    );
}
