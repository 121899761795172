import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Button from '../../../components/UI/buttons';
import ModalSelectPatients from './ModalSelectPatients';
import {
    getpostCardsPatients,
    putpostCardsremovePatients,
} from '../../../store/slices/marketingSlice';
import { DataGrid } from '@mui/x-data-grid';
import Input from '../../../components/UI/inputs';
import { MdOutlineDelete } from 'react-icons/md';
import { SmallSpinner } from '../../../components/UI/Spinners/SmallSpinner';

function SelectedPatients({ insideData, isLoading, id }) {
    const [showModal, setShowModal] = useState(false);
    const [patientId, setPatientId] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [dataPatients, setDataPatients] = useState([]);

    useEffect(() => {
        if (insideData?.patientPostcardResponses !== undefined) {
            const filteredData = insideData?.patientPostcardResponses?.filter(
                (user) =>
                    user.fullName
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
            );
            setDataPatients(filteredData);
        }
    }, [searchValue, insideData]);

    const dispatch = useDispatch();

    const PATIENTS_HEADER = [
        {
            headerName: 'ID',
            field: 'patientId',
            width: 100,
        },
        {
            headerName: 'ФИО',
            field: 'fullName',
            width: 300,
        },
        { headerName: 'Телефон', field: 'phoneNumber', width: 300 },
        {
            headerName: 'Почта',
            field: 'email',
            width: 300,
        },
    ];

    useEffect(() => {
        dispatch(getpostCardsPatients({ id: id }));
    }, [dispatch, id]);

    const handleClick = () => {
        dispatch(
            putpostCardsremovePatients({
                patientsId: patientId,
                cardId: id,
            })
        );
    };

    return (
        <StyledContainerTable>
            {isLoading ? (
                <SmallSpinner />
            ) : (
                <>
                    <StyledHeader>
                        <WrapprInput>
                            <Input
                                borderRadius="30px"
                                width="230px"
                                padding="5px 10px"
                                placeholder="Поиск по таблице..."
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            {patientId?.length > 0 && (
                                <MdOutlineDelete
                                    size={25}
                                    color="gray"
                                    cursor="pointer"
                                    onClick={handleClick}
                                />
                            )}
                        </WrapprInput>
                        <Button
                            width="200px"
                            onClick={() => setShowModal(true)}
                        >
                            Добавить пациента
                        </Button>
                    </StyledHeader>
                    <StyledContainerMain>
                        <DataGrid
                            columns={PATIENTS_HEADER}
                            rows={dataPatients?.map((row) => ({
                                ...row,
                                id: row.patientId,
                            }))}
                            pageSizeOptions={[10, 20, 30, 40, 50, 100]}
                            checkboxSelection
                            onRowSelectionModelChange={(e) => setPatientId(e)}
                            rowSelectionModel={patientId}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10, page: 0 },
                                },
                            }}
                            localeText={{
                                noRowsLabel: 'Нет выбранных пациентов',
                                columnMenuSortAsc: 'Сортировка по порядку',
                                columnMenuSortDesc: 'Сортировка не по порядку',
                                columnMenuUnsort: 'Несортировать',
                                columnMenuFilter: 'Поиск',
                                columnMenuManageColumns: 'Заголовки',
                                columnMenuHideColumn: 'Скрыть столбец',
                                columnsPanelTextFieldLabel: 'Найти столбец',
                                columnsPanelTextFieldPlaceholder:
                                    'Название столбца',
                                columnsPanelShowAllButton: 'Показать все',
                                columnsPanelHideAllButton: 'Скрыть все',
                                filterPanelInputLabel: 'Значение',
                                filterPanelInputPlaceholder: '',
                                filterPanelColumns: 'Заголовки',
                                filterPanelOperator: 'Оператор',
                                filterOperatorContains: 'Cодержит',
                                filterOperatorEquals: 'Pавно',
                                filterOperatorStartsWith: 'Hачинается с',
                                filterOperatorEndsWith: 'Заканчивается',
                                filterOperatorIsEmpty: 'Пусто',
                                filterOperatorIsNotEmpty: 'Не пусто',
                                filterOperatorIsAnyOf: 'Любой из',
                                footerRowSelected: (count) =>
                                    count !== 1
                                        ? `${count.toLocaleString()} Выбранные`
                                        : `${count.toLocaleString()} Выбранный`,
                                MuiTablePagination: {
                                    labelDisplayedRows: ({ from, to }) =>
                                        `${from} Из ${to}`,
                                    labelRowsPerPage: 'Рядов на странице:',
                                },
                            }}
                        />
                    </StyledContainerMain>
                    <ModalSelectPatients
                        showModal={showModal}
                        setShowModal={setShowModal}
                        cardId={id}
                    />
                </>
            )}
        </StyledContainerTable>
    );
}

const WrapprInput = styled.div`
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 500px) {
        input {
            width: 200px;
        }
    }
`;
const StyledContainerTable = styled.div`
    width: 100%;
    margin-top: 20px;
`;
const StyledContainerMain = styled.div`
    width: 100%;
    height: 500px;
    margin-top: 30px;
    div {
        ::-webkit-scrollbar {
            width: 10px;
        }
        ::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        ::-webkit-scrollbar-thumb {
            background: #888;
        }
        ::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
`;
const StyledHeader = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        gap: 10px;
        button {
            width: 100%;
            font-size: 12px;
            padding: 4px 8px !important;
        }
    }
`;

export default SelectedPatients;
