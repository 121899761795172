import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const scheduleService = createApi({
    reducerPath: 'scheduleApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['schedule', 'break'],
    endpoints: (builder) => ({
        // BREAK PATTERNS

        postBreakPattern: builder.mutation({
            query: (body) => {
                return {
                    url: 'break-patterns',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['break'],
        }),
        getBreakPattern: builder.query({
            query: () => 'break-patterns',
            providesTags: ['break'],
        }),
        deletBreakPattern: builder.mutation({
            query: (id) => {
                return {
                    url: `break-patterns/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['break'],
        }),
        updateBreakPattenr: builder.mutation({
            query: ({ id, body }) => {
                return {
                    url: `break-patterns/${id}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: ['break'],
        }),

        // SCHEDULE

        postSchedule: builder.mutation({
            query: ({ userId, body }) => {
                return {
                    url: `schedules/${userId}`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['schedule'],
        }),

        getSchedule: builder.query({
            query: ({ userId, startWeek }) =>
                `schedules/${userId}?startWeek=${startWeek}`,
            providesTags: ['schedule'],
        }),

        deleteSchedule: builder.mutation({
            query: (scheduleId) => {
                return {
                    url: `schedules/${scheduleId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['schedule'],
        }),
        getAllSchedule: builder.query({
            query: ({ startWeek }) => `schedules?startWeek=${startWeek}`,
            providesTags: ['schedule'],
        }),

        deleteDaySchedule: builder.mutation({
            query: (dayScheduleId) => {
                return {
                    url: `day-schedules/${dayScheduleId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['schedule'],
        }),
        putDaySchedule: builder.mutation({
            query: ({ dayScheduleId, startTime, endTime, breakPatternIds }) => {
                return {
                    url: `day-schedules/${dayScheduleId}?startTime=${startTime}&endTime=${endTime}&breakPatternIds=${breakPatternIds}`,
                    method: 'PUT',
                };
            },
            invalidatesTags: ['schedule'],
        }),
    }),
});
export default scheduleService;

export const {
    usePostBreakPatternMutation,
    useGetBreakPatternQuery,
    useDeletBreakPatternMutation,
    useUpdateBreakPattenrMutation,
    usePostScheduleMutation,
    useGetScheduleQuery,
    useDeleteScheduleMutation,
    useGetAllScheduleQuery,
    useDeleteDayScheduleMutation,
    usePutDayScheduleMutation,
} = scheduleService;
