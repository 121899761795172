import { useState } from 'react';

const TARIF_NAME = [
    {
        name: 'Doctor',
        month: 'Скидка -10% / $36',
        price1: '$40',
        halfYear: 'Скидка -10% / $215',
        price2: '$240',
        year: 'Скидка -20% / $384',
        price3: '$480',
    },
    {
        name: 'Doctor 5',
        month: 'Скидка -10% / $45',
        price1: '$50',
        halfYear: 'Скидка -10% / $270',
        price2: '$300',
        year: 'Скидка -20% / $480',
        price3: '$600',
    },
    {
        name: 'Doctor 10',
        month: 'Скидка -10% / $55',
        price1: '$60',
        halfYear: 'Скидка -10% / $325',
        price2: '$360',
        year: 'Скидка -20% / $576',
        price3: '$720',
    },
];

const DATA_TARIF = [
    {
        name: 'Докторов',
        currentTarifs: ['1', '5', '10'],
    },
    {
        name: 'Администратор',
        currentTarifs: ['1', 'Неограничено', 'Неограничено'],
    },
    {
        name: 'Мобильное приложение',
        currentTarifs: [true, true, true],
    },
    {
        name: 'Дисковое пространство',
        currentTarifs: ['Неограничено', 'Неограничено', 'Неограничено'],
    },
    {
        name: 'Расписание врачей',
        currentTarifs: [true, true, true],
    },
    {
        name: 'База пациентов',
        currentTarifs: ['Неограничено', 'Неограничено', 'Неограничено'],
    },
    {
        name: 'Прайс услуг',
        currentTarifs: [true, true, true],
    },
    {
        name: 'Склад',
        currentTarifs: [false, true, true],
    },
    {
        name: 'Отчет финансы',
        currentTarifs: [true, true, true],
    },
    {
        name: 'Email уведомление о записи',
        currentTarifs: [true, true, true],
    },
    {
        name: 'Рассылки SMS (1.7 сом)',
        currentTarifs: [false, true, true],
    },
    {
        name: 'Whats app интеграция (0.10 сом)',
        currentTarifs: [false, false, true],
    },
    {
        name: 'Обучение',
        currentTarifs: [true, true, true],
    },
];

function colorPicker(current) {
    switch (current) {
        case 0:
            return 'bg-[#f6f7ff]';
        case 1:
            return 'bg-[#c8ceff]';
        case 2:
            return 'bg-[#b5bdff]';
        default:
            return 'bg-[#b5bdff]';
    }
}

function PricingPage() {
    const [currentTarif, setCurrentTarif] = useState(1);
    return (
        <div className="w-full h-full flex flex-col items-center justify-center">
            <h1 className="text-[30px] text-[#717cec] font-medium max-w-[540px] text-center">
                Тарифы
            </h1>
            <div className="w-full h-full flex flex-wrap items-center justify-center gap-4 mt-5">
                {TARIF_NAME.map((item, index) => (
                    <div
                        key={index}
                        className="w-[350px] h-fit py-3 rounded-lg border flex sm:flex-col sm:gpa-3 items-center justify-center cursor-pointer"
                        onClick={() => setCurrentTarif(index)}
                    >
                        <div className="w-[40%] flex flex-col items-center justify-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`w-7 h-7 ${
                                    currentTarif !== index
                                        ? 'text-gray-400'
                                        : 'text-[#2563ea]'
                                }`}
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <h2 className="text-xl font-medium text-gray-700">
                                {item.name}
                            </h2>
                        </div>
                        <div
                            className={`w-full h-full ${
                                currentTarif !== index
                                    ? 'text-gray-500'
                                    : 'text-[#2563ea]'
                            }
                                 }`}
                        >
                            <div className="h-full flex flex-col items-start sm:items-center justify-around space-y-1">
                                <div className="">
                                    <div className="px-2 text-[20px] bg-gray-100 rounded-full">
                                        {item.price1}
                                        <span className="text-[16px]">
                                            {' '}
                                            - 1 Месяц
                                        </span>
                                    </div>
                                    <h2 className="text-2xl font-semibold">
                                        {item.month}
                                    </h2>
                                </div>
                                <div className="mt-16">
                                    <div className="px-2 text-[20px] bg-gray-100 rounded-full">
                                        {item.price2}
                                        <span className="text-[16px]">
                                            {' '}
                                            - 6 месяцев{' '}
                                        </span>
                                    </div>
                                    <h2 className="text-2xl font-semibold">
                                        {item.halfYear}
                                    </h2>
                                </div>
                                <div className="">
                                    <div className="px-2 text-[20px] bg-gray-100 rounded-full">
                                        {item.price3}
                                        <span className="text-[16px]">
                                            {' '}
                                            - 1 год
                                        </span>
                                    </div>
                                    <h2 className="text-2xl font-semibold">
                                        {item.year}
                                    </h2>
                                    <span className="text-[11px]">
                                        Пробный период 7 дней
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div
                className={`
                    w-[60%] sm:w-full p-8 mt-8 space-y-8 rounded-xl  ${colorPicker(
                        currentTarif
                    )}
                    `}
            >
                {DATA_TARIF.map((element, index) => (
                    <div
                        key={index}
                        className="flex items-center justify-between text-gray-800"
                    >
                        <p className="text-[16px]">{element.name}</p>
                        {typeof element.currentTarifs[currentTarif] ===
                        'string' ? (
                            <p className="text-[16px]">
                                {element.currentTarifs[currentTarif]}
                            </p>
                        ) : element.currentTarifs[currentTarif] ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-blue-500"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-red-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PricingPage;
