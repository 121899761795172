import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';

export const getAllDeposit = createAsyncThunk(
    'deposit/getDeposit',
    async ({ patientID, currentPage, depositType }, { rejectWithValue }) => {
        try {
            const responce = await axiosConfigInstance().get(
                `deposits/patients/${patientID}?page=${currentPage}&size=10&depositType=${depositType}`
            );
            return responce.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

const initialState = {
    deposit: [],
    totalPages: [],
    isLoading: false,
};

const depositAllSlice = createSlice({
    name: 'deposit',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllDeposit.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllDeposit.fulfilled, (state, action) => {
                state.isLoading = false;
                state.deposit = action.payload;
            })
            .addCase(getAllDeposit.rejected, (state, action) => {
                state.isLoading = false;
            });
    },
});

export default depositAllSlice;
