import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';
import { clearEmptyProps } from '../utils/helpers/helpers';

const patternService = createApi({
    reducerPath: 'patternApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getPattern: builder.query({
            query: ({ search, patternType }) => {
                return {
                    url: `patterns`,
                    params: { ...clearEmptyProps({ search, patternType }) },
                };
            },
        }),
    }),
});
export default patternService;

export const { useGetPatternQuery } = patternService;
