import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const getAnnouncements = createAsyncThunk(
    'announcements/getAnnouncements',
    async (_, { rejectedWithValue }) => {
        try {
            const { data } = await axiosConfigInstance().get('announcements');
            return data;
        } catch (error) {
            rejectedWithValue(error.message);
        }
    }
);

export const postAnnouncements = createAsyncThunk(
    'announcements/postAnnouncements',
    async ({ data }, { rejectedWithValue }) => {
        try {
            const response = await axiosConfigInstance().post(
                'announcements',
                data
            );
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

export const getAnnouncementsInner = createAsyncThunk(
    'announcements/getAnnouncementsInner',
    async ({ ID }, { rejectedWithValue, dispatch }) => {
        try {
            const { data } = await axiosConfigInstance().get(
                `announcements/${ID}`
            );
            dispatch(getAnnouncementsInner());
            return data;
        } catch (error) {
            rejectedWithValue(error.message);
        }
    }
);

export const deleteAnnouncements = createAsyncThunk(
    'announcements/deleteAnnouncements',
    async ({ ID }, { rejectedWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `announcements/${ID}`
            );
            showSuccessMessage(response.data.message);
            dispatch(getAnnouncements());
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

export const putAnnouncements = createAsyncThunk(
    'announcements/putAnnouncements',
    async ({ Data, Id }, { rejectedWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                `announcements/${Id}`,
                Data
            );
            showSuccessMessage(response.data.message);
            dispatch(getAnnouncements());
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

export const getAnnouncementsLast = createAsyncThunk(
    'announcements/getAnnouncementsLast',
    async (_, { rejectedWithValue }) => {
        try {
            const { data } = await axiosConfigInstance().get(
                'announcements/last'
            );
            return data;
        } catch (error) {
            rejectedWithValue(error.message);
        }
    }
);

const initialState = {
    announcements: [],
    announcementsMessage: null,
    announcementsInner: null,
    isLoading: false,
};

const announcementsSlice = createSlice({
    name: 'announcements',
    initialState,
    reducers: {},
    extraReducers: {
        //Post

        [postAnnouncements.pending]: (state) => {
            state.isLoading = true;
        },
        [postAnnouncements.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [postAnnouncements.rejected]: (state) => {
            state.isLoading = false;
        },

        //Get

        [getAnnouncements.pending]: (state) => {
            state.isLoading = true;
        },
        [getAnnouncements.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.announcements = payload;
        },
        [getAnnouncements.rejected]: (state) => {
            state.isLoading = false;
        },

        //InnerGet
        [getAnnouncementsInner.pending]: (state) => {
            state.isLoading = true;
        },
        [getAnnouncementsInner.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.announcementsInner = payload;
        },
        [getAnnouncementsInner.rejected]: (state) => {
            state.isLoading = false;
        },

        // Last

        [getAnnouncementsLast.pending]: (state) => {
            state.isLoading = true;
        },
        [getAnnouncementsLast.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.announcementsMessage = payload;
        },
        [getAnnouncementsLast.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default announcementsSlice;
