import React from 'react';
import Modal from '../../../../components/UI/Modal/Modal';
import { useDispatch } from 'react-redux';
import { putMagazine } from '../../../../store/slices/magazineSlice';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/UI/buttons';
import Input from '../../../../components/UI/inputs';
import { useEffect } from 'react';

const JurnalEditModal = ({ active, setActive, itemData }) => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const [dataMagazine, setDataMagazine] = useState({
        journalId: '',
        name: '',
        price: '',
        quantity: '',
    });

    useEffect(() => {
        setDataMagazine({
            journalId: itemData?.id,
            name: itemData?.name,
            price: itemData?.price,
            quantity: itemData?.quantity,
        });
    }, [itemData?.id, itemData?.name, itemData?.price, itemData?.quantity]);

    const saveMagazineHandler = () => {
        dispatch(putMagazine({ documentID: id, dataMagazine }));
        setActive(false);
    };
    return (
        <Modal active={active}>
            <div>
                <h2>Товар</h2>
            </div>
            <Input
                label="Название *"
                value={dataMagazine.name}
                onChange={(e) =>
                    setDataMagazine({ ...dataMagazine, name: e.target.value })
                }
            />
            <WrapperDoubleInput>
                <div>
                    <Input
                        label="Количество"
                        value={dataMagazine.quantity}
                        onChange={(e) =>
                            setDataMagazine({
                                ...dataMagazine,
                                quantity: e.target.value,
                            })
                        }
                    />
                </div>
                <div>
                    <Input
                        label="Цена"
                        value={dataMagazine.price}
                        onChange={(e) =>
                            setDataMagazine({
                                ...dataMagazine,
                                price: e.target.value,
                            })
                        }
                    />
                </div>
            </WrapperDoubleInput>
            <WrapperSubmitButton>
                <Button
                    borderRadius="5px"
                    color="#5460e6"
                    backgroundColor="#ffffff"
                    border="1px solid #5460e6"
                    onClick={() => setActive(false)}
                >
                    Отмена
                </Button>
                <Button borderRadius="5px" onClick={saveMagazineHandler}>
                    Сохранить
                </Button>
            </WrapperSubmitButton>
        </Modal>
    );
};
const WrapperSubmitButton = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    width: 100%;
    padding: 0 0 0 70%;
`;
const WrapperDoubleInput = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    input {
        max-width: 150px;
    }
`;

export default JurnalEditModal;
