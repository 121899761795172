export const JWT_TOKEN_KEY = 'token';
export const _KEY_AUTH = 'USER_DATA';

export const REGEXP_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;
export const REGEXP_PASSWORD = /(?=.*[0-9])(?=.*[A-Z])[0-9a-zA-Z]{6,32}/g;

export const WEEK_CALENDAR = [
    'воскресенье',
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота',
];

export const translatebuttonText = {
    today: 'Сегодня',
    day: 'День',
    month: 'Месяц',
    week: 'Неделя',
    list: 'Список',
};

export const calendarTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
};

export const chartLabelsTranslateRu = (value) => {
    switch (value) {
        case 'MON':
            return 'ПН';
        case 'TUE':
            return 'ВТ';
        case 'WED':
            return 'СР';
        case 'THU':
            return 'ЧТ';
        case 'FRI':
            return 'ПТ';
        case 'SAT':
            return 'СБ';
        case 'SUN':
            return 'ВС';
        case 'JAN':
            return 'ЯНВ';
        case 'FEB':
            return 'ФЕВ';
        case 'MAR':
            return 'МАР';
        case 'APR':
            return 'АПР';
        case 'MAY':
            return 'МАЙ';
        case 'JUN':
            return 'ИЮН';
        case 'JUL':
            return 'ИЮЛ';
        case 'AUG':
            return 'АВГ';
        case 'SEP':
            return 'СЕН';
        case 'OCT':
            return 'ОКТ';
        case 'NOV':
            return 'НОЯ';
        case 'DEC':
            return 'ДЕК';
        default:
            return value;
    }
};

export const STATUS_FILTER = [
    {
        path: 'ACTIVE',
        label: 'Активные',
    },
    {
        path: 'BLOCKED',
        label: 'Заблокированные',
    },
];

export const HEADER_SCHEDULE = [
    {
        title: '№',
    },
    {
        title: 'Доктора',
    },
    {
        title: 'Понедельник',
    },
    {
        title: 'Вторник',
    },
    {
        title: 'Среда',
    },
    {
        title: 'Четверг',
    },
    {
        title: 'Пятница',
    },
    {
        title: 'Суббота',
    },
    {
        title: 'Воскресенье',
    },
];

export const DEPOSIT_TYPE = [
    {
        path: 'PAYMENT_BY_DEPOSIT',
        label: 'Оплата с депозит',
    },
    {
        path: 'MAKE_DEPOSIT',
        label: 'Внесен депозит',
    },
];

export const GENDER = [
    {
        path: 'MALE',
        label: 'Мужской',
    },
    {
        path: 'FEMALE',
        label: 'Женский',
    },
];
export const RECORDTYPEDATA = [
    {
        path: 'TREATMENT',
        label: 'Лечение',
    },
    { path: 'CONSULTATION', label: 'Консультация' },
    { path: 'FIRST_DOCTOR_APPOINTMENT', label: 'Первый визит' },
    { path: 'DIAGNOSTICS', label: 'Диагностика' },
    { path: 'INSPECTION', label: 'Осмотр' },
    { path: 'IMPLANTATION', label: 'Имплантация' },
    { path: 'ORTHOPEDICS', label: 'Ортопедия' },
    { path: 'ORTHODONTICS', label: 'Ортодонтия' },
    { path: 'PERIODONTOLOGY', label: 'Пародонтология' },
    { path: 'SURGERY', label: 'Хирургия' },
    { path: 'ENDODONTII', label: 'Эндодонтия' },
];
export const APPOINTMENTSTATUSDATA = [
    { path: 'NOT_CONFIRMED', label: 'Не подтверждена' },
    { path: 'CONFIRMED', label: 'Подтвержден' },
    { path: 'COMEIN', label: 'Пришел' },
    { path: 'NOT_COME', label: 'Не пришел' },
    { path: 'CANCELED', label: 'Отменен' },
];

export const WHERE_COME = [
    {
        path: 'INSTAGRAM',
        label: 'Instagram',
    },
    {
        path: 'TV',
        label: 'Телевидение',
    },
    {
        path: 'RADIO',
        label: 'Радио',
    },
    {
        path: 'STOCK',
        label: 'Акция',
    },
    {
        path: 'MAIL',
        label: 'Почта рассылка',
    },
    {
        path: 'WHATSAPP',
        label: 'Whatsapp',
    },
    {
        path: 'TWOGIS',
        label: '2Гис',
    },
    {
        path: 'ADVISED',
        label: 'Посоветовали знакомые',
    },
    {
        path: 'RELATIVES',
        label: 'Друзья, Родные',
    },
];

export const CURRENCY_TYPE = [
    {
        path: 'SOM',
        label: 'Сом',
    },
    {
        path: 'TEN',
        label: 'Тенге',
    },
    {
        path: 'RUB',
        label: 'Рубль',
    },
    {
        path: 'UE',
        label: 'у.е',
    },
];
export const CATEGORYS_TYPE_SERVICES = [
    {
        path: 'CONSULTATION',
        label: 'Консультация',
    },
    {
        path: 'X_RAY',
        label: 'Рентгенология',
    },
    {
        path: 'ORTHOPEDICS',
        label: 'Ортопедия',
    },
    {
        path: 'SURGERY_CHILD',
        label: 'Детская хирургическая стоматология',
    },
    {
        path: 'THERAPY',
        label: 'Терапия',
    },
    {
        path: 'SURGERY',
        label: 'Хирургия',
    },
    {
        path: 'IMPLANTATION',
        label: 'Имплантация',
    },
    {
        path: 'ORTHODONTICS',
        label: 'Ортодонтия',
    },

    {
        path: 'ANESTHESIA',
        label: 'Анестезия',
    },
    {
        path: 'HYGIENE',
        label: 'Гигиена',
    },
    {
        path: 'PREPS_AND_MATERIALS',
        label: 'Препараты и материалы',
    },
    {
        path: 'CHILD_DENTISTRY',
        label: 'Детская стоматология',
    },
    {
        path: 'LABORATORY',
        label: 'Лаборатория',
    },
    {
        path: 'BONE_SOFT',
        label: 'Пластика костных и мягких тканей',
    },
    {
        path: 'COSMETOLOGY',
        label: 'Косметология',
    },
    {
        path: 'PEDIATRIC_DENTISTRY',
        label: 'Детская терапевтическая стоматология',
    },
    {
        path: 'TECHNICAL_WORKS',
        label: 'Технические работы',
    },
    {
        path: 'FUNCTIONAL_DIAGNOSTICS',
        label: 'Функциональная диагностика',
    },
    {
        path: 'DIAGNOSTICS',
        label: 'Диагностика',
    },
    {
        path: 'GENERAL_EVENTS',
        label: 'Общие мероприятия',
    },
    {
        path: 'MAXILLOFACIAL_SURGERY',
        label: 'Челюстно-лицевая хирургия',
    },
    {
        path: 'PREVENTION',
        label: 'Профилактика',
    },
    {
        path: 'PREVENTION',
        label: 'Профилактика',
    },
    {
        path: 'ENDODONTII',
        label: 'Эндодонтия',
    },
];

export const PAYMENT_OF_TYPE = [
    {
        path: 'CASH',
        label: 'Наличные',
    },
    {
        path: 'CARD',
        label: 'Без Нал.',
    },
    {
        path: 'MBANK',
        label: 'М Банк',
    },
    {
        path: 'OPTIMA',
        label: 'Оптима',
    },
];
export const DISCOUNT_OF_TYPE = [
    {
        path: 'PERCENT',
        label: '%',
    },
    {
        path: 'FIXED',
        label: 'Фиксированный',
    },
];

export const CALENDAR_TYPE_IN_DOCTOR = [
    {
        label: 'Мои',
        value: 1,
    },
    {
        label: 'Все',
        value: 2,
    },
];

export const LITTLE_WEEK = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
