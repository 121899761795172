import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import { _KEY_AUTH } from '../../utils/constans/general';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const getClinics = createAsyncThunk(
    'clinic/getClinics',
    async (id, { rejectedWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(`/clinics/${id}`);
            return response.data;
        } catch (error) {
            return rejectedWithValue(error.message);
        }
    }
);

export const putClinics = createAsyncThunk(
    'clinic/putClinics',
    async ({ id, putDataClinic, imageId }, { rejectedWithValue, dispatch }) => {
        try {
            let data;
            if (typeof imageId !== 'object') {
                data = imageId;
            } else {
                const formData = new FormData();
                formData.append('file', imageId);

                const res = await axiosConfigInstance().post(
                    '/files/AVATAR',
                    formData
                );
                data = await res.data.imageId;
            }

            const response = await axiosConfigInstance().put(
                `/clinics/${id}?imageId=${data}`,
                putDataClinic
            );
            dispatch(getClinics(id));
            showSuccessMessage(
                response.status === 200 && 'Операция успешно сохранен'
            );
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

const localData = JSON.parse(localStorage.getItem(_KEY_AUTH)) || {};

const initialState = {
    clinicData: localData?.clinicData || {},
    isLoading: false,
};

export const clinicSlice = createSlice({
    name: 'clinic',
    initialState,
    reducers: {},
    extraReducers: {
        //get
        [getClinics.pending]: (state) => {
            state.isLoading = true;
        },
        [getClinics.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.clinicData = action.payload;
        },
        [getClinics.rejected]: (state) => {
            state.isLoading = false;
        },

        //put

        [putClinics.pending]: (state) => {
            state.isLoading = true;
        },
        [putClinics.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [putClinics.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default clinicSlice;
