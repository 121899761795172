import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const patientService = createApi({
    reducerPath: 'patientApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getPatientAppointment: builder.query({
            query: (appointmentId) => `/appointments/${appointmentId}`,
        }),
        searchPatient: builder.query({
            query: ({search}) => `/patients?searchPatient=${search}`
        })
    }),
});
export default patientService;

export const { useGetPatientAppointmentQuery, useSearchPatientQuery, useLazySearchPatientQuery } = patientService;
