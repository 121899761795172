import React from 'react';
import { Link } from 'react-router-dom';
import NOT from '../../assets/images/not_found_page.png';
import Button from '../../components/UI/buttons/index';
import styled from 'styled-components';

const NotFoundPage = () => {
    return (
        <Wrapper className="flex items-center flex-col">
            <img src={NOT} alt="NOT FOUND PAGE" />
            <Link to="/">
                <Button>НАЗАД</Button>
            </Link>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    margin: 0 auto;
`;

export default NotFoundPage;
