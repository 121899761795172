import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    ToothID: '',
    miniConditionData: null,
    showConditionToothModal: false,
    selectDiagnosToothData: [],
    pdfModal: false,
};

const selectTeethSlice = createSlice({
    name: 'selectTeeth',
    initialState,
    reducers: {
        showPDFModalHandler: (state, { payload }) => {
            state.pdfModal = true;
        },
        hidePDFModalHandler: (state, { payload }) => {
            state.pdfModal = false;
        },
        selectConToothRemove: (state) => {
            state.selectDiagnosToothData = [];
        },
        selectConToothHandler: (state, { payload }) => {
            const { selectDiagnosToothData } = state;
            const { item, toothNumber, innerToothResponse } = payload;

            if (selectDiagnosToothData.length) {
                const existingIndex = selectDiagnosToothData.findIndex(
                    (i) => i.toothNumber === +toothNumber
                );
                if (existingIndex !== -1) {
                    const updatedObject = {
                        toothNumber: toothNumber,
                        main: item,
                        innerToothResponse,
                    };
                    return {
                        ...state,
                        selectDiagnosToothData: [
                            ...selectDiagnosToothData.slice(0, existingIndex),
                            updatedObject,
                            ...selectDiagnosToothData.slice(existingIndex + 1),
                        ],
                    };
                } else {
                    return {
                        ...state,
                        selectDiagnosToothData: [
                            ...selectDiagnosToothData,
                            {
                                toothNumber: toothNumber,
                                main: item,
                                innerToothResponse,
                            },
                        ],
                    };
                }
            } else {
                return {
                    ...state,
                    selectDiagnosToothData: [
                        {
                            toothNumber: toothNumber,
                            main: item,
                            innerToothResponse,
                        },
                    ],
                };
            }
        },
        showToothModalHandler: (state, { payload }) => {
            state.ToothID = payload;
        },
        showConditionToothModalHandler: (state, { payload }) => {
            state.miniConditionData = payload;
        },
    },
    extraReducers: {},
});

export default selectTeethSlice;
export const {
    showToothModalHandler,
    selectConToothRemove,
    showConditionToothModalHandler,
    selectConToothHandler,
    showPDFModalHandler,
    hidePDFModalHandler,
} = selectTeethSlice.actions;
