import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import patientsSlice from './slices/patientsSlice';
import servicesSlice from './slices/servicesSlice';
import selectTeethSlice from './slices/selectTeethSlice';
import clinicSlice from './slices/clinicSlice';
import exelSlice from './slices/exelSlice';
import marketingSlice from './slices/marketingSlice';
import dashboardSlice from './slices/dashboardSlice';
import stockSlice from './slices/stockSlice';
import setSlice from './slices/setSlice';
import documentSlice from './slices/documentSlice';
import magazineSlice from './slices/magazineSlice';
import payrollCalculationsSlice from './slices/payrollCalculations';
import diagnosisSlice from './slices/diagnosisSlice';
import depositAllSlice from './slices/depositAllSlice';
import discountSlice from './slices/discountSlice.js';
import clientSlice from './slices/clientSlice';
import tariffsSlice from './slices/tariffsSlice';
import announcementsSlice from './slices/announcementSlice';
import workPatternSlice from './slices/workPatternSlice';
import whatsappSlice from './slices/whatsappSlice';
import calendarService from '../services/calendar.service';
import patternService from '../services/pattern.service';
import roomService from '../services/room.service';
import serviceService from '../services/services.service';
import chatService from '../services/chat.service';
import reportsService from '../services/reports.service';
import patientService from '../services/patients.service';
import toothService from '../services/tooth.service';
import paymentService from '../services/payment.service';
import diagnosService from '../services/diagnos.service';
import workService from '../services/work.service';
import userService from '../services/user.service';
import specialityService from '../services/speciality.service.js';
import scheduleService from '../services/schedule.service';

export const store = configureStore({
    reducer: {
        [calendarService.reducerPath]: calendarService.reducer,
        [patternService.reducerPath]: patternService.reducer,
        [roomService.reducerPath]: roomService.reducer,
        [serviceService.reducerPath]: serviceService.reducer,
        [chatService.reducerPath]: chatService.reducer,
        [reportsService.reducerPath]: reportsService.reducer,
        [patientService.reducerPath]: patientService.reducer,
        [toothService.reducerPath]: toothService.reducer,
        [paymentService.reducerPath]: paymentService.reducer,
        [diagnosService.reducerPath]: diagnosService.reducer,
        [workService.reducerPath]: workService.reducer,
        [userService.reducerPath]: userService.reducer,
        [specialityService.reducerPath]: specialityService.reducer,
        [scheduleService.reducerPath]: scheduleService.reducer,
        auth: authSlice.reducer,
        patients: patientsSlice.reducer,
        services: servicesSlice.reducer,
        selectTeeth: selectTeethSlice.reducer,
        clinic: clinicSlice.reducer,
        exel: exelSlice.reducer,
        marketing: marketingSlice.reducer,
        dashboard: dashboardSlice.reducer,
        stock: stockSlice.reducer,
        set: setSlice.reducer,
        document: documentSlice.reducer,
        magazine: magazineSlice.reducer,
        payrollCalculations: payrollCalculationsSlice.reducer,
        diagnosis: diagnosisSlice.reducer,
        deposit: depositAllSlice.reducer,
        discount: discountSlice.reducer,
        client: clientSlice.reducer,
        tariffs: tariffsSlice.reducer,
        announcements: announcementsSlice.reducer,
        servicesToothList: servicesSlice.reducer,
        workPattern: workPatternSlice.reducer,
        whatsapp: whatsappSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            calendarService.middleware,
            patternService.middleware,
            roomService.middleware,
            serviceService.middleware,
            chatService.middleware,
            reportsService.middleware,
            patientService.middleware,
            toothService.middleware,
            paymentService.middleware,
            diagnosService.middleware,
            workService.middleware,
            userService.middleware,
            specialityService.middleware,
            scheduleService.middleware
        ),
});
