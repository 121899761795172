import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getAnnouncementsInner } from '../../../../store/slices/announcementSlice';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '../../../../components/UI/buttons';
import { getYoutubeThumbnail } from '../../../../utils/helpers/helpers';

const InnerAnnouncements = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const { announcementsInner } = useSelector((state) => state.announcements);

    useEffect(() => {
        dispatch(getAnnouncementsInner({ ID: id }));
    }, [dispatch, id]);

    function сhangeNavigate() {
        navigate(`/announcements/announcements-edit/${id}`);
    }

    const descriptionData = announcementsInner?.description?.split('\n');

    return (
        <StyledContainer>
            <StyledHeader>
                <Breadcrumbs aria-label="breadcrumb">
                    <LINK
                        underline="hover"
                        color="inherit"
                        href="/announcements"
                    >
                        Объявления
                    </LINK>
                    <LINK underline="hover" color="inherit" aria-current="page">
                        {announcementsInner?.title}
                    </LINK>
                </Breadcrumbs>
                <Button onClick={сhangeNavigate}>
                    Редактировать объявление
                </Button>
            </StyledHeader>
            <StyledMain>
                {announcementsInner?.link && (
                    <Iframe
                        width="70%"
                        height="450px"
                        src={getYoutubeThumbnail(announcementsInner.link)}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    />
                )}
                <Texts>
                    <span>Название:</span>
                    <p>{announcementsInner?.title}</p>
                    <span>Описание:</span>
                    <>
                        {descriptionData?.map((el) => (
                            <p key={el}>{el}</p>
                        ))}
                    </>
                </Texts>
            </StyledMain>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    width: 100%;
`;
const StyledHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
        width: 230px;
    }
`;
const StyledMain = styled.div`
    width: 95%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-top: 10px;
`;
const LINK = styled(Link)`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #000000;
    width: auto;
    word-break: break-all;
    @media screen and (max-width: 750px) {
        font-size: 14px;
    }
    @media screen and (max-width: 550px) {
        font-size: 12px;
    }
`;

const Texts = styled.div`
    width: auto;
    word-break: break-all;
    span {
        color: #87939e;
    }
    p {
        font-size: 18px;
        width: auto;
    }
`;

const Iframe = styled.iframe`
    border-radius: 10px;
    border: none;
`;

export default InnerAnnouncements;
