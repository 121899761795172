import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const setPost = createAsyncThunk(
    'set/setPost',
    async ({ data, id }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().post(
                `services/${id}/sets`,
                data
            );
            showSuccessMessage(response.data.message);
            dispatch(setGet(id));
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const setGet = createAsyncThunk(
    'set/setGet',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(
                `services/${id}/sets`
            );
            return response.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

export const setDelete = createAsyncThunk(
    'set/setDelete',
    async ({ id, carId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `services/{serviceId}/sets?setIds=${id}`
            );
            dispatch(setGet(carId));
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const setPut = createAsyncThunk(
    'set/setPut',
    async ({ id, carId, quantity }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                `services/{serviceId}/sets/${carId}?quantity=${quantity}`
            );
            dispatch(setGet(id));
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

const initialState = {
    set: [],
    isLoading: false,
};

const setSlice = createSlice({
    name: 'set',
    initialState,
    reducers: {},
    extraReducers: {
        //post

        [setPost.pending]: (state) => {
            state.isLoading = true;
        },
        [setPost.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [setPost.rejected]: (state) => {
            state.isLoading = false;
        },

        //get

        [setGet.pending]: (state) => {
            state.isLoading = true;
        },
        [setGet.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.set = action.payload;
        },
        [setGet.rejected]: (state) => {
            state.isLoading = false;
        },

        //delete

        [setDelete.pending]: (state) => {
            state.isLoading = true;
        },
        [setDelete.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [setDelete.rejected]: (state) => {
            state.isLoading = false;
        },

        //put

        [setPut.pending]: (state) => {
            state.isLoading = true;
        },
        [setPut.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [setPut.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default setSlice;
