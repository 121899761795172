import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const postDocument = createAsyncThunk(
    'document/postDocument',
    async ({ data }, { rejectedWithValue }) => {
        try {
            const response = await axiosConfigInstance().post(
                'documents',
                data
            );
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

export const getDocument = createAsyncThunk(
    'document/getDocument',
    async (_, { rejectedWithValue }) => {
        try {
            const response = await axiosConfigInstance().get('documents');
            return response.data;
        } catch (error) {
            rejectedWithValue(error.message);
        }
    }
);

export const getSingleDocument = createAsyncThunk(
    'document/getSingleDocument',
    async ({ documentID }, { rejectedWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(
                `documents/${documentID}`
            );
            return response.data;
        } catch (error) {
            rejectedWithValue(error.message);
        }
    }
);

export const deleteDocument = createAsyncThunk(
    'document/deleteDocument',
    async ({ documentID }, { rejectedWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `documents/${documentID}`
            );
            showSuccessMessage(response.data.message);
            dispatch(getDocument());
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

export const putDocument = createAsyncThunk(
    'document/putDocument',
    async ({ documentData }, { rejectedWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                'documents/to-pay',
                documentData
            );
            showSuccessMessage(response.data.message);
            dispatch(getDocument());
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

export const putGlobalDocument = createAsyncThunk(
    'document/putGlobalDocument',
    async ({ documentData }, { rejectedWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                'documents',
                documentData
            );
            showSuccessMessage(response.data.message);
            dispatch(getDocument());
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

const initialState = {
    document: [],
    isLoading: false,
    insideDocument: null,
};

export const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {},
    extraReducers: {
        //post

        [postDocument.pending]: (state) => {
            state.isLoading = true;
        },
        [postDocument.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [postDocument.rejected]: (state) => {
            state.isLoading = false;
        },

        //get

        [getDocument.pending]: (state) => {
            state.isLoading = true;
        },
        [getDocument.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.document = action.payload;
        },
        [getDocument.rejected]: (state) => {
            state.isLoading = false;
        },

        //get

        [getSingleDocument.pending]: (state) => {
            state.isLoading = true;
        },
        [getSingleDocument.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.insideDocument = action.payload;
        },
        [getSingleDocument.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default documentSlice;
