import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const reportsService = createApi({
    reducerPath: 'reportsApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Salaries', 'Expenses'],
    endpoints: (builder) => ({
        getDebtors: builder.query({
            query: ({ size, page }) =>
                `reports/debtors?page=${page}&size=${size}`,
        }),
        getPaymentHistory: builder.query({
            query: ({ start, end, page, size }) =>
                `reports/payment-report?start=${start}&end=${end}&page=${page}&size=${size}`,
        }),
        getReports: builder.query({
            query: ({ start, end }) => `/reports?start=${start}&end=${end}`,
        }),

        //Salaries

        getSalariesTable: builder.query({
            query: ({ start, end }) =>
                `salaries/table?start=${start}&end=${end}`,
            providesTags: ['Salaries'],
        }),

        postSalaries: builder.mutation({
            query: (body) => {
                return {
                    url: 'salaries',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['Salaries'],
        }),

        getSalaries: builder.query({
            query: ({ end, start, page }) =>
                `salaries?page=${page}&size=10&start=${start}&end=${end}`,
            providesTags: ['Salaries'],
        }),

        // Expense

        getExpenses: builder.query({
            query: ({ page, start, end }) =>
                `expenses?page=${page}&size=10&start=${start}&end=${end}`,
            providesTags: ['Expenses'],
        }),

        postExpenses: builder.mutation({
            query: (body) => {
                return {
                    url: 'expenses',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['Expenses'],
        }),
    }),
});
export default reportsService;

export const {
    useGetDebtorsQuery,
    useGetPaymentHistoryQuery,
    useGetSalariesTableQuery,
    usePostSalariesMutation,
    useGetExpensesQuery,
    usePostExpensesMutation,
    useGetSalariesQuery,
    useGetReportsQuery,
} = reportsService;
