import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const toothService = createApi({
    reducerPath: 'toothApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['conditions'],
    endpoints: (builder) => ({
        getMainTooth: builder.query({
            query: (patientID) => `/teeth/mains/${patientID}`,
        }),
        getConditions: builder.query({
            query: () => '/conditions/mains',
            providesTags: ['conditions'],
        }),
        getMiniConditions: builder.query({
            query: () => '/conditions/inners',
            providesTags: ['conditions'],
        }),
        getJowConditions: builder.query({
            query: () => '/conditions/jows',
            providesTags: ['conditions'],
        }),
        postConditions: builder.mutation({
            query: (body) => {
                return {
                    url: `conditions`,
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['work', 'conditions'],
        }),
        deleteConditions: builder.mutation({
            query: ({ conditionId }) => {
                return {
                    url: `conditions/${conditionId}`,
                    method: 'DELETE'
                }
            },
            invalidatesTags: ['conditions']
        }),
        putConditions: builder.mutation({
            query: ({conditionId, body}) => {
                return {
                    url: `conditions/${conditionId}`,
                    method: 'PUT',
                    body: body
                }
            },
            invalidatesTags: ['conditions']
        })
    }),
});
export default toothService;

export const {
    useGetMainToothQuery,
    useGetConditionsQuery,
    useGetMiniConditionsQuery,
    useGetJowConditionsQuery,
    usePostConditionsMutation,
    useDeleteConditionsMutation,
    usePutConditionsMutation
} = toothService;
