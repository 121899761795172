import React, { lazy } from 'react';
import { ROUTES } from '../utils/constans/routes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PublishPage } from '../pages/publish';
import PrivacyPublish from '../pages/publish/privacy';

const Auth = lazy(() => import('../components/auth/Auth'));
const NotFoundPage = lazy(() => import('../pages/error-page/NotFoundPage'));

export const GuestRoutes = () => {
    const { LOGIN, NOT_FOUND_PAGE } = ROUTES;
    return (
        <Routes>
            <Route path="/" element={<Navigate to={LOGIN.path} />} />
            <Route path={LOGIN.path} element={<Auth />} />
            <Route path={NOT_FOUND_PAGE.path} element={<NotFoundPage />} />
            <Route path="/prices" element={<PublishPage />} />
            <Route path='/prices/privacy' element={<PrivacyPublish />} />
        </Routes>
    );
};
