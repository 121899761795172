import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const roomService = createApi({
    reducerPath: 'roomApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getRooms: builder.query({
            query: () => 'rooms',
            providesTags: ['rooms'],
        }),
        postRoom: builder.mutation({
            query: (name) => {
                return {
                    url: `rooms?name=${name}`,
                    method: 'POST',
                };
            },
            invalidatesTags: ['rooms'],
        }),
        deleteRoom: builder.mutation({
            query: (roomId) => {
                return {
                    url: `/rooms/${roomId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['rooms'],
        }),
        putRoom: builder.mutation({
            query: ({ roomId, name }) => {
                return {
                    url: `rooms/${roomId}?name=${name}`,
                    method: 'PUT',
                };
            },
            invalidatesTags: ['rooms'],
        }),
        getRoomsSelect: builder.query({
            query: ({ date, startTime, endTime }) =>
                `rooms/select?date=${date}&startTime=${startTime}&endTime=${endTime}`,
            providesTags: ['rooms'],
        }),
    }),
});
export default roomService;

export const {
    useGetRoomsQuery,
    usePostRoomMutation,
    useDeleteRoomMutation,
    usePutRoomMutation,
    useGetRoomsSelectQuery,
} = roomService;
