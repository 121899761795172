import React from 'react';
import styled from 'styled-components';
import {
    MdLogout,
    MdInfoOutline,
    MdOutlineFolderSpecial,
} from 'react-icons/md';
import { FiUsers } from 'react-icons/fi';
import { HiMenuAlt3, HiOutlineSpeakerphone } from 'react-icons/hi';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import GlobalStyle from '../../styles/globalStyled';
import { OpenAndCloseInDashboard } from '../../store/slices/dashboardSlice';
import { useState } from 'react';
import LogoutBtn from '../createClinic/LogoutBtn';
import Button from '../../components/UI/buttons';
import Modal from '../../components/UI/Modal/Modal';
import { BiSupport } from 'react-icons/bi';

export const SuperDashboard = () => {
    const { isOpen } = useSelector((state) => state.dashboard);
    const { pathname } = useLocation();
    const dispatch = useDispatch();

    const menus = [
        { name: 'Клиники', link: '/clients', icon: FiUsers },
        {
            name: 'Специализации',
            link: '/specialization',
            icon: MdOutlineFolderSpecial,
        },
        { name: 'Тариф', link: '/tariffs', icon: MdInfoOutline },
        {
            name: 'Объявления',
            link: '/announcements',
            icon: HiOutlineSpeakerphone,
        },
        { name: 'Поддержка', link: '/supports', icon: BiSupport },
    ];

    setInterval(() => {
        if (window.innerWidth <= 480) {
            dispatch(OpenAndCloseInDashboard(false));
        }
    }, 1000);

    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const handleClose = () => {
        setShowLogoutModal(false);
    };

    return (
        <>
            <GlobalStyle />
            <section className="flex gap-6">
                <div
                    style={{ backgroundColor: '#f2f6fa' }}
                    className={`bg-[#383838] min-h-screen sm:w-16 ${() =>
                        dispatch(OpenAndCloseInDashboard(false))} ${
                        isOpen ? 'w-[250px]' : 'w-16'
                    }  text-gray-100 px-4 duration-500 dark:bg-white border-r`}
                >
                    <div className="pb-3 pt-6 flex justify-end text-[#5460e6]">
                        <HiMenuAlt3
                            size={26}
                            className="cursor-pointer"
                            onClick={() =>
                                dispatch(OpenAndCloseInDashboard(!isOpen))
                            }
                        />
                    </div>
                    <WrapperLink>
                        {menus?.map((menu, i) => {
                            return (
                                <Styledlink
                                    to={menu?.link}
                                    link={menu?.link}
                                    pathname={pathname}
                                    key={i}
                                >
                                    <div>
                                        {React.createElement(menu?.icon, {
                                            size: '22',
                                        })}
                                    </div>
                                    <h2
                                        className={`whitespace-pre duration-500 ${
                                            !isOpen &&
                                            'opacity-0 translate-x-28 overflow-hidden'
                                        } `}
                                    >
                                        {menu?.name}
                                    </h2>
                                </Styledlink>
                            );
                        })}
                    </WrapperLink>
                    <DIV onClick={() => setShowLogoutModal(true)}>
                        <div>
                            {React.createElement(MdLogout, {
                                size: '22',
                            })}
                        </div>
                        <h2
                            className={`whitespace-pre duration-500 ${
                                !isOpen &&
                                'opacity-0 translate-x-28 overflow-hidden'
                            } `}
                        >
                            Выйти
                        </h2>
                    </DIV>
                </div>
            </section>
            <Modal active={showLogoutModal}>
                <h2>Вы действительно хотите выйти ?</h2>
                <WrapperButtons>
                    <Button
                        borderRadius="5px"
                        color="#5460e6"
                        backgroundColor="#ffffff"
                        border="1px solid #5460e6"
                        onClick={() => setShowLogoutModal(false)}
                    >
                        Отменить
                    </Button>
                    <LogoutBtn handleClose={handleClose} />
                </WrapperButtons>
            </Modal>

            <div className="w-[100%] pt-[10px] pr-[20px] pl-[5px]">
                <div className="bg-white pt-[10px] pr-[20px] pl-[20px] pb-[10px] rounded-2xl">
                    <Outlet />
                </div>
            </div>
        </>
    );
};
const WrapperLink = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: relative;
`;
const Styledlink = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    font-size: 14px;
    line-height: 1.5rem;
    font-weight: 500;
    padding: 5px;
    border-radius: 6px;
    background-color: ${(props) => props.link === props.pathname && '#5460e6'};
    color: ${(props) => (props.link === props.pathname ? 'white' : '#5460e6')};
    :hover {
        color: white;
        background-color: #5460e6;
    }
`;

const DIV = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    font-size: 14px;
    line-height: 1.5rem;
    font-weight: 500;
    padding: 5px;
    margin-top: 5px;
    border-radius: 6px;
    color: #5460e6;
    :hover {
        color: white;
        background-color: #5460e6;
    }
`;
const WrapperButtons = styled.div`
    padding: 10px;
    display: flex;
    gap: 20px;
    .delete {
        :hover {
            opacity: 0.9;
        }
        :active {
            color: white;
            background-color: red;
        }
    }
`;
