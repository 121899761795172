import styled from '@emotion/styled/macro';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showSuccessMessage = (msg) => toast.success(msg);
export const showErrorMessage = (msg) => toast.error(msg);
export const showWarningMessage = (msg) => toast.warning(msg);

export const Notification = () => {
    return (
        <StyledToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    );
};

const StyledToastContainer = styled(ToastContainer)`
    width: 400px;
    font-size: 14px;
    @media screen and (max-width: 600px) {
        width: 250px;
        margin-top: 10px;
    }
    .Toastify__toast--success {
        background: #36ac0c;
        border-radius: 5px;
        color: #fff;
        svg {
            fill: #fff;
        }
    }
    .Toastify__toast--error {
        background: #c91e1e;
        border-radius: 5px;
        color: #fff;
        svg {
            fill: #fff;
        }
    }
    .Toastify__toast--warning {
        background: #ff9700;
        border-radius: 5px;
        color: #fff;
        svg {
            fill: #fff;
        }
    }
`;
