import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const getWhatsappRequest = createAsyncThunk(
    'whatsapp/getWhatsappRequest',
    async (clinickId, { rejectedWithValue }) => {
        try {
            const { data } = await axiosConfigInstance().get(
                `/whats-apps/${clinickId}`
            );
            showSuccessMessage(data.message);
            return data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

export const postWhatsappRequest = createAsyncThunk(
    'whatsapp/postWhatsappRequest',
    async ({ Data }, { rejectedWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().post(
                '/whats-apps',
                Data
            );
            showSuccessMessage(response.data.message);
            dispatch(getWhatsappRequest(Data.clinicId));
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

export const putWhatsappRequest = createAsyncThunk(
    'whatsapp/putWhatsappRequest',
    async ({ WhatsappId, Data }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                `/whats-apps/${WhatsappId}?newPhoneNumber=${Data.newPhoneNumber}&newToken=${Data.newToken}`,
                Data
            );
            showSuccessMessage(response.data.message);
            dispatch(getWhatsappRequest(Data.clinicId));
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectWithValue(error.message);
        }
    }
);

export const deleteWhatsappRequest = createAsyncThunk(
    'whatsapp/deleteWhatsappRequest',
    async ({ whatsappId, clinicId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `/whats-apps/${whatsappId}`
            );
            showSuccessMessage(response.data.message);
            dispatch(getWhatsappRequest(clinicId));
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    isLoading: false,
    whatsappDate: null,
};

const whatsappSlice = createSlice({
    name: 'whatsapp',
    initialState,
    reducers: {},
    extraReducers: {
        [getWhatsappRequest.pending]: (state) => {
            state.isLoading = true;
        },
        [getWhatsappRequest.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.whatsappDate = payload;
        },
        [getWhatsappRequest.rejected]: (state) => {
            state.isLoading = false;
        },

        //post

        [postWhatsappRequest.pending]: (state) => {
            state.isLoading = true;
        },
        [postWhatsappRequest.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [postWhatsappRequest.rejected]: (state) => {
            state.isLoading = false;
        },

        //delete

        [deleteWhatsappRequest.pending]: (state) => {
            state.isLoading = true;
        },
        [deleteWhatsappRequest.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [deleteWhatsappRequest.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default whatsappSlice;
