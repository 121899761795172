import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAnnouncements } from '../../../store/slices/announcementSlice';
import Meat from './Meat';
import Cards from '../../../components/UI/Cards/Cards';
import { useNavigate } from 'react-router-dom';

const Announcements = () => {
    const { announcements } = useSelector((state) => state.announcements);
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getAnnouncements());
    }, [dispatch]);

    const innerNavigate = (id) => {
        navigate(`/announcements/announcements-inner/${id}`);
    };

    return (
        <StyledAdvertisments>
            <StyledHeader>
                <StyledHeaderTitle>Объявления</StyledHeaderTitle>
            </StyledHeader>
            <Div>
                {announcements.map((el) => {
                    return (
                        <div key={el.announcementId}>
                            <Cards
                                title={el.title}
                                description={el.description}
                                id={el.announcementId}
                                innerClick={innerNavigate}
                                meatBalls={
                                    <Meat
                                        id={el.announcementId}
                                        innerClick={innerNavigate}
                                    />
                                }
                            />
                        </div>
                    );
                })}
            </Div>
        </StyledAdvertisments>
    );
};

const StyledAdvertisments = styled.div`
    width: 100%;
`;
const StyledHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
`;
const StyledHeaderTitle = styled.div`
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
`;

const Div = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
    padding: 0 3%;
    @media (max-width: 350px) {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 20px;
    }
    margin-top: 30px;
`;

export default Announcements;
