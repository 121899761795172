import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';
import { _KEY_AUTH } from '../../utils/constans/general';
import {
    removeWithKeyFromlocalStorage,
    removeTimeFromLocalStorage,
    setLoginTimeToLocalStorage,
} from '../../utils/helpers/helpers';

export const setUserData = createAsyncThunk(
    '/auth/setUserData',
    async ({ userData, navigateAfterSuccessAuth }, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().post(
                '/auth/login',
                userData
            );
            setLoginTimeToLocalStorage();
            navigateAfterSuccessAuth();
            showSuccessMessage(response.status === 200 && 'Успешный вход');
            return response.data;
        } catch (error) {
            showErrorMessage('Логин или пароль неверны');
            return rejectWithValue(error.message);
        }
    }
);

const localData = JSON.parse(localStorage.getItem(_KEY_AUTH)) || {};

const initialState = {
    data: null,
    isLoading: false,
    token: localData?.token || null,
    role: localData?.role || null,
    user: localData?.userName || null,
    userID: localData?.id || null,
    isAuthorized: localData?.isAuthorized || false,
    clinicId: localData?.clinicId || null,
    errorMessage: [],
    messages: '',
    reloadMessages: false,
    createModal: false,
    station: 'post',
    createText: '',
    updateService : 0
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            state.isAuthorized = false;
            state.token = null;
            state.role = null;
            state.user = null;
            state.userID = null;
            state.clinicId = null;
            removeWithKeyFromlocalStorage();
            removeTimeFromLocalStorage();
        },
        updateChat(state, action) {
            state.reloadMessages = action.payload;
        },
        setCreateModal(state, action) {
            state.createModal = action.payload
        },
        setStation(state, action){
            state.station = action.payload
        },
        setCreateText(state, {payload}){
            state.createText = payload
        },
        setService(state, {payload}){
            state.updateService = payload
        }
    },
    extraReducers: {
        [setUserData.pending]: (state) => {
            state.isLoading = true;
        },
        [setUserData.fulfilled]: (state, { payload }) => {
            state.data = payload;
            state.isLoading = false;
            state.isAuthorized = true;
            state.token = payload.jwt;
            state.role = payload.role;
            state.user = payload.firstName;
            state.userID = payload.id;
            state.clinicId = payload.clinicId;
            state.messages = '';
        },
        [setUserData.rejected]: (state, action) => {
            state.isLoading = false;
            state.errorMessage = { message: action.payload };
            state.messages = action.payload;
        },
    },
});
export const authAction = authSlice.actions;
export default authSlice;
