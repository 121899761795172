import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const workService = createApi({
    reducerPath: 'workApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getWork: builder.query({
            query: (appointmentId) => `works/${appointmentId}`,
            providesTags: ['work'],
        }),
        postWork: builder.mutation({
            query: ({ appointmentId, data }) => {
                return {
                    url: `/works/${appointmentId}`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['work'],
        }),
        postQuickWork: builder.mutation({
            query: ({ appointmentId, data }) => {
                return {
                    url: `/works/quick/${appointmentId}`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['work'],
        }),
        deleteWork: builder.mutation({
            query: ({ workId }) => {
                return {
                    url: `/works/${workId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['work'],
        }),
        putWork: builder.mutation({
            query: ({ workId, data }) => {
                return {
                    url: `/works/${workId}`,
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['work'],
        }),
    }),
});
export default workService;

export const {
    useGetWorkQuery,
    usePostWorkMutation,
    useDeleteWorkMutation,
    usePutWorkMutation,
    usePostQuickWorkMutation
} = workService;
