import {
    StatusStyled,
    StyledActionsStatus,
} from '../../components/UI/AccardionTableMedia/AccardionTableMedia';
import { StyledIMG } from '../../components/UI/tables/UITable';
import { JWT_TOKEN_KEY } from '../constans/general';
import numeral from 'numeral';

export function fNumber(number) {
    return numeral(number).format();
}

export function translateItValueAndOption(value, options) {
    if (typeof value === 'object') {
        return value;
    } else {
        return options.filter((item) => item.value === value)[0];
    }
}

export function timeDifferenceInMinutes(startDataValue, endDataValue) {
    const [startHour, startMinute] = startDataValue.split(':').map(Number);
    const [endHour, endMinute] = endDataValue.split(':').map(Number);

    const startTimeInMinutes = startHour * 60 + startMinute;
    const endTimeInMinutes = endHour * 60 + endMinute;
    return endTimeInMinutes - startTimeInMinutes;
}

export function calculateEndTime(startTime, fullDuration) {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    let hours = Math.floor(fullDuration / 60);
    let minutes = fullDuration % 60;
    let endHours = startHours + hours;
    let endMinutes = startMinutes + minutes;
    if (endMinutes >= 60) {
        endMinutes -= 60;
        endHours++;
    }
    const formattedEndHours = endHours.toString().padStart(2, '0');
    const formattedEndMinutes = endMinutes.toString().padStart(2, '0');
    const endTime = `${formattedEndHours}:${formattedEndMinutes}:00`;
    return endTime;
}

export const transformDate = (item) => {
    const newName = item.replace('T', ' ');
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h2>{newName.slice(0, 16)}</h2>
            <h2>{newName.slice(21, 50)}</h2>
        </div>
    );
};

export const translateDepositType = (item) => {
    switch (item) {
        case 'MAKE_DEPOSIT':
            return 'Внесен депозит';
        case 'PAYMENT_BY_DEPOSIT':
            return 'Оплата с депозита';
        default:
            break;
    }
};

export const toothTransform = (item) =>
    item?.map((el, index) => <span key={index}>{el},&nbsp;</span>);

export const servicesTransform = (item) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        {item?.map((el) => (
            <span style={{ padding: '0 15px' }} key={el.id ? el.id : el.name}>
                • {el.name}
            </span>
        ))}
    </div>
);

export function generateFullHours({ startTime, endTime, step }) {
    const fullHours = [];
    const start = new Date(`2023-01-01T${startTime}`);
    const end = new Date(`2023-01-01T${endTime}`);
    const stepInMilliseconds = step * 60 * 1000;
    while (start <= end) {
        const hour = start.getHours().toString().padStart(2, '0');
        const minutes = start.getMinutes() === 0 ? '00' : '30';
        fullHours.push(`${hour}:${minutes}`);
        start.setTime(start.getTime() + stepInMilliseconds);
    }
    return fullHours;
}

export const removeWithKeyFromlocalStorage = () => {
    localStorage.removeItem(JWT_TOKEN_KEY);
};
export const saveTolocalStorage = (key, data) => {
    try {
        localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
        window.alert(error.message);
    }
};

export const getDateOFBirthday = ({ dateOfBirthday }) => {
    if (dateOfBirthday) {
        const yearOfB = new Date(dateOfBirthday).getFullYear();
        const today = new Date().getFullYear();
        const yearUser = today - yearOfB;
        return yearUser;
    } else {
        return '-';
    }
};

export const genderTransform = (gender) => {
    return gender === 'MALE' ? 'Мужской' : 'Женский';
};

export const removeTimeFromLocalStorage = () => {
    localStorage.removeItem('LOGIN_TIME');
};

export const setLoginTimeToLocalStorage = () => {
    localStorage.setItem('LOGIN_TIME', JSON.stringify(new Date()));
};

export const translateStatus = (appointmentStatus) => {
    switch (appointmentStatus) {
        case 'NOT_COME':
            return 'Не пришел';
        case 'COMEIN':
            return 'Пришел';
        case 'CONFIRMED':
            return 'Подтвержденный';
        case 'CANCELED':
            return 'Отменен';
        case 'NOT_CONFIRMED':
            return 'Не подтвержденный';
        case 'ONLINE':
            return 'Онлайн Запись';
        default:
            break;
    }
};

export const translateType = (recordType) => {
    switch (recordType) {
        case 'TREATMENT':
            return 'Лечение';
        case 'CONSULTATION':
            return 'Консультация';
        case 'DIAGNOSTICS':
            return 'Диагностика';
        case 'INSPECTION':
            return 'Осмотр';
        case 'IMPLANTATION':
            return 'Имплантация';
        case 'ORTHOPEDICS':
            return 'Ортопедия';
        case 'ORTHODONTICS':
            return 'Ортодонтия';
        case 'PERIODONTOLOGY':
            return 'Пародонтология';
        case 'SURGERY':
            return 'Хирургия';
        default:
            break;
    }
};

export const translatePayment = (typeOfPayment) => {
    switch (typeOfPayment) {
        case 'CASH':
            return 'Наличные';
        case 'CARD':
            return 'Без Нал.';
        case 'MBANK':
            return 'М Банк';
        case 'OPTIMA':
            return 'Оптима';
        default:
            break;
    }
};

export const translateDocument = (valueStatusDocument) => {
    switch (valueStatusDocument) {
        case 'NOT_PAID':
            return 'Не оплаченный';
        case 'PARTIALLY':
            return 'Частично';
        case 'PAID':
            return 'Оплачено';
        default:
            break;
    }
};

export const translateFromWhere = (fromWhere) => {
    switch (fromWhere) {
        case 'OTHER':
            return 'Другое';
        case 'STOCK':
            return 'Акция';
        case 'BUSINESS_CARD':
            return 'Визитка';
        case 'LEAFLET':
            return 'Листовка';
        case 'RADIO':
            return 'Радио';
        case 'TV':
            return 'Телевидение';
        case 'INSTAGRAM':
            return 'Instagram';
        case 'WHATSAPP':
            return 'Whatsapp';
        case 'TWOGIS':
            return '2Гис';
        case 'MAIL':
            return 'Почта рассылка';
        case 'ADVISED':
            return 'Посоветовали знакомые';
        default:
            break;
    }
};

export const translateServiceType = (title) => {
    switch (title) {
        case 'NO_CATEGORY':
            return 'Нет категории';
        case 'CONSULTATION':
            return 'Консультация';
        case 'X_RAY':
            return 'Рентгенология';
        case 'ORTHOPEDICS':
            return 'Ортопедия';
        case 'SURGERY_CHILD':
            return 'Детская хирургическая стоматология';
        case 'THERAPY':
            return 'Терапия';
        case 'SURGERY':
            return 'Хирургия';
        case 'IMPLANTATION':
            return 'Имплантация';
        case 'ORTHODONTICS':
            return 'Ортодонтия';
        case 'ANESTHESIA':
            return 'Анестезия';
        case 'HYGIENE':
            return 'Гигиена';
        case 'PREPS_AND_MATERIALS':
            return 'Препараты и материалы';
        case 'CHILD_DENTISTRY':
            return 'Детская стоматология';
        case 'LABORATORY':
            return 'Лаборатория';
        case 'BONE_SOFT':
            return 'Пластика костных и мягких тканей';
        case 'COSMETOLOGY':
            return 'Косметология';
        case 'PEDIATRIC_DENTISTRY':
            return 'Детская терапевтическая стоматология';
        case 'TECHNICAL_WORKS':
            return 'Технические работы';
        case 'FUNCTIONAL_DIAGNOSTICS':
            return 'Функциональная диагностика';
        case 'DIAGNOSTICS':
            return 'Диагностика';
        case 'GENERAL_EVENTS':
            return 'Общие мероприятия';
        case 'MAXILLOFACIAL_SURGERY':
            return 'Челюстно-лицевая хирургия';
        case 'PREVENTION':
            return 'Профилактика';
        case 'PREVENTION':
            return 'Профилактика';
        case 'ENDODONTII':
            return 'Эндодонтия';
        default:
            break;
    }
};

export const translateBackgroundColor = (bgColor) => {
    switch (bgColor) {
        case 'NOT_CONFIRMED':
            return '#e59560';
        case 'CONFIRMED':
            return '#5a8100';
        case 'COMEIN':
            return '#647295';
        case 'NOT_COME':
            return '#ff6347';
        case 'CANCELED':
            return '#e63d1f';
        case 'ONLINE':
            return '#8f97f0';
        default:
            break;
    }
};

export const translateBackground = (backgroundColor) => {
    switch (backgroundColor) {
        case 'NOT_CONFIRMED':
            return 'bg-[#ed7b2f]';
        case 'CONFIRMED':
            return 'bg-[#788402]';
        case 'COMEIN':
            return 'bg-[#25428c]';
        case 'NOT_COME':
            return 'bg-[#ff6347]';
        case 'CANCELED':
            return 'bg-[#e63d1f]';
        case 'ONLINE':
            return 'bg-[#8f97f0]';
        default:
            break;
    }
};

export const translateBorderColor = (borderColor) => {
    switch (borderColor) {
        case 'NOT_CONFIRMED':
            return '#ed7b2f';
        case 'CONFIRMED':
            return '#788402';
        case 'COMEIN':
            return '#25428c';
        case 'NOT_COME':
            return '#ff6347';
        case 'CANCELED':
            return '#e63d1f';
        case 'ONLINE':
            return '#8f97f0';
        default:
            break;
    }
};
export const translateLittleWeekFromUS = (item) => {
    switch (item) {
        case 'MONDAY':
            return 'Пн';
        case 'TUESDAY':
            return 'Вт';
        case 'WEDNESDAY':
            return 'Ср';
        case 'THURSDAY':
            return 'Чт';
        case 'FRIDAY':
            return 'Пт';
        case 'SATURDAY':
            return 'Сб';
        case 'SUNDAY':
            return 'Вс';
        default:
            break;
    }
};

export const transformTypeMark = (type) => {
    switch (type) {
        case 'PROMOTION':
            return 'АКЦИЯ';
        case 'HOLIDAY':
            return 'ПРАЗДНИК ';
        default:
            break;
    }
};

export const statusTransform = (type) => {
    switch (type) {
        case 'ACTIVE':
            return 'Активные';
        case 'BLOCKED':
            return 'Заблокирован ';
        default:
            break;
    }
};

export const transformRead = (read) => {
    switch (read) {
        case true:
            return 'Прочитано';
        case false:
            return 'Не прочитано ';
        default:
            break;
    }
};

export const getYoutubeThumbnail = (link) => {
    if (link) {
        const youtubeThumbnailId = link.substr(link.indexOf('=') + 1, 11);
        return `https://www.youtube.com/embed/${youtubeThumbnailId}`;
    }
    return '';
};

export const clearEmptyProps = (object) => {
    const returnedObject = {};
    Object.keys(object).forEach((key) => {
        if (object[key]) {
            returnedObject[key] = object[key];
        }
    });

    return returnedObject;
};

export const HeaderTitleAccordioins = ({ item, page }) => {
    switch (page) {
        case 'PATIENTS':
            return item.fullName;
        case 'STUFF':
            return item.fullName;
        case 'SERVICES_DIAGNOS':
            return item?.name;
        case 'PROMOTION':
            return item?.title;
        case 'HOLIDAY':
            return item?.title;
        case 'STOCK':
            return item?.name;
        case 'STOCK_DOCUMENT':
            return item?.supplier;
        case 'REPORTS_DOCTORS':
            return item?.fullName;
        case 'SALARIES':
            return item?.fullName;
        case 'EXPENSES':
            return item?.title;
        case 'SUPPORT_INFO':
            return item?.clinicName;
        case 'SUPER_CLINIC':
            return item?.clinicName;
        case 'DOCTOR_VISIT':
            return item?.appointment;
        case 'VISIT_PATIENTS':
            return item?.appointment;
        case 'SNAPSHOT_DOCTOR':
            return item?.imageDescription;
        case 'PATIENTS_PAYMENT':
            return transformDate(item?.dateOfPayment);
        case 'PATIENTS_NOTES':
            return item?.peculiarities;
        case 'DEPOSIT_HEADER':
            return transformDate(item.dateTimeAppointment);
        case 'DISCOUNT_HEADER':
            return transformDate(item?.appointmentDateTime);
        case 'SERVICE_ROOM':
            return item.name;
        case 'DEBTORS_PAGE':
            return item.fullName;
        case 'PAYMENT_HISTORY':
            return item.patientFullName;
        default:
            break;
    }
};

export const bodyAccordionData = (item, page, onClick, checkRole) => {
    switch (page) {
        case 'STUFF':
            return (
                <>
                    <p>Телефон: {item?.phoneNumber || '-'}</p>
                    <p>Почта: {item?.email || '-'}</p>
                    {item?.payrollCalculationsResponse && (
                        <p>
                            Ставка: {item?.payrollCalculationsResponse?.salary}
                        </p>
                    )}
                </>
            );
        case 'PATIENTS':
            return (
                <>
                    {checkRole !== true && <p>Телефон: {item?.phoneNumber || '-'}</p>}
                    <p>Почта: {item?.email || '-'}</p>
                    <p>Дата рождения: {item?.birthDate || '-'}</p>
                    {item?.debt === 0 ? (
                        <p>Долг:0</p>
                    ) : (
                        <p>Долг:{item?.debt}</p>
                    )}
                    {item?.deposit === 0 ? (
                        <p>Депозит: 0</p>
                    ) : (
                        <p>Депозит:{item?.deposit}</p>
                    )}
                    {item?.payment === 0 ? (
                        <p>Оплачено:0</p>
                    ) : (
                        <p>Оплачено:{item?.payment}</p>
                    )}
                </>
            );
        case 'PAYMENT_HISTORY':
            return (
                <>
                    <p>
                        Оплаченная дата: {item.payedDate.split('T')[0]}{' '}
                        {item.payedDate.slice(11, 16)}
                    </p>
                    <p>Оплачено: {item.payed}</p>
                    <p>Способ оплаты: {translatePayment(item.typeOfPayment)}</p>
                </>
            );
        case 'STOCK':
            return (
                <>
                    {item?.price === 0 ? (
                        <p>Стоимость:0</p>
                    ) : (
                        <p>Стоимость:{item?.price}</p>
                    )}
                    {item?.quantity === 0 ? (
                        <p>Количество: 0</p>
                    ) : (
                        <p>Количество:{item?.quantity}</p>
                    )}
                    {item?.totalPrice === 0 ? (
                        <p>Oбщая сумма:0</p>
                    ) : (
                        <p>Oбщая сумма:{item?.totalPrice}</p>
                    )}
                </>
            );
        case 'PROMOTION':
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    {item?.startDate && <p>Начало: {item?.startDate || '-'}</p>}
                    {item?.endDate && <p>Конец: {item?.endDate || '-'}</p>}
                    {item?.imageResponse && (
                        <img
                            style={{
                                width: 100,
                                height: 100,
                            }}
                            src={item?.imageResponse?.link || ''}
                            alt={item.title}
                        />
                    )}
                </div>
            );
        case 'HOLIDAY':
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    {item?.dateOfCreated && (
                        <p>Начало: {item?.dateOfCreated || '-'}</p>
                    )}

                    {item?.imageResponse && (
                        <img
                            style={{
                                width: 100,
                                height: 100,
                            }}
                            src={item?.imageResponse?.link || ''}
                            alt={item.title}
                        />
                    )}
                </div>
            );
        case 'STOCK_DOCUMENT':
            return (
                <>
                    <p>Описание: {item?.description || '-'}</p>
                    {item?.totalPrice === 0 ? (
                        <p>Итоговая цена: 0</p>
                    ) : (
                        <p>Итоговая цена:{item?.totalPrice}</p>
                    )}
                    {item?.payed === 0 ? (
                        <p>Оплачено: 0</p>
                    ) : (
                        <p>Оплачено:{item?.payed}</p>
                    )}
                    {item?.debt === 0 ? (
                        <p>Долг: 0</p>
                    ) : (
                        <p>Долг:{item?.debt}</p>
                    )}

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5,
                        }}
                    >
                        Cтатус:
                        <StyledActionsStatus bgColor={item.paymentStatus}>
                            {item?.paymentStatus}
                        </StyledActionsStatus>
                    </div>
                </>
            );
        case 'REPORTS_DOCTORS':
            return (
                <>
                    {item?.quantityAppointment === 0 ? (
                        <p>Визиты: 0</p>
                    ) : (
                        <p>
                            Визиты:
                            {item?.quantityAppointment}
                        </p>
                    )}
                    {item?.appointmentSum === 0 ? (
                        <p>Сумма Визита: 0</p>
                    ) : (
                        <p>
                            Сумма Визита:
                            {item?.appointmentSum}
                        </p>
                    )}
                    {item?.discount === 0 ? (
                        <p>Скидки: 0</p>
                    ) : (
                        <p>Скидки:{item?.discount}</p>
                    )}

                    {item?.percent === 0 ? (
                        <p>Доля: 0</p>
                    ) : (
                        <p>Доля:{item?.percent}</p>
                    )}
                    {item?.payrollCalculationsResponse && (
                        <p>
                            Ставка: {item?.payrollCalculationsResponse?.salary}
                        </p>
                    )}
                    {item?.salary === 0 ? (
                        <p>Начислено: 0</p>
                    ) : (
                        <p>Начислено:{item?.salary}</p>
                    )}
                </>
            );
        case 'EXPENSES':
            return (
                <>
                    <p>Дата: {item?.createAt || '-'}</p>
                    {item?.sum === 0 ? (
                        <p>Сумма: 0</p>
                    ) : (
                        <p>Сумма: {item?.sum}</p>
                    )}
                </>
            );
        case 'SALARIES':
            return (
                <>
                    <p>Название расход: {item?.title || '-'}</p>
                    <p>Оплачено: {item?.createAt || '-'}</p>
                    <p>С(год.мес./день): {item?.salaryDateFrom || '-'}</p>
                    <p>До(год.мес./день): {item?.salaryDateTo || '-'}</p>
                    {item?.sum === 0 ? (
                        <p>Сумма: 0</p>
                    ) : (
                        <p>Сумма:{item?.sum}</p>
                    )}
                </>
            );
        case 'SUPPORT_INFO':
            return (
                <>
                    <p>Админ: {item?.admin || '-'}</p>
                    <p>
                        Время: {item.messageTime.slice(0, 10)}{' '}
                        {item.messageTime.slice(11, 16)}
                    </p>
                    <p>Сообщение: {item?.message || '-'}</p>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5,
                        }}
                    >
                        Cтатус:
                        <StyledActionsStatus bgColor={item.read}>
                            {transformRead(item.read)}
                        </StyledActionsStatus>
                    </div>
                </>
            );
        case 'SUPER_CLINIC':
            return (
                <>
                    <p>Адрес: {item?.address || '-'}</p>
                    <p>ФИО: {item?.fullName || '-'}</p>
                    <p>Email: {item?.email || '-'}</p>
                    <p>Дата создания: {item?.dateOfCreate || '-'}</p>
                    <p>Дата платежа: {item?.dateOfPayment || '-'}</p>
                    {item?.payment === 0 ? (
                        <p>Оплата тарифа: 0</p>
                    ) : (
                        <p>Оплата тарифа:{item?.payment}</p>
                    )}
                    <StatusStyled status={item.status}>
                        {statusTransform(item.status)}
                    </StatusStyled>
                    <p>Тариф: {item?.tariff}</p>
                    {item?.pricePayable === 0 ? (
                        <p>Цена тариф: 0</p>
                    ) : (
                        <p>Цена тариф:{item?.tariffPrice}</p>
                    )}
                </>
            );
        case 'DEBTORS_PAGE':
            return (
                <>
                    <p>Номер телефона: {item.phoneNumber}</p>
                    <p className="text-green-500">Оплачено: {item.payed}</p>
                    <p className="text-red-500">Долг: {item.debt}</p>
                </>
            );
        case 'DOCTOR_VISIT':
            return (
                <>
                    <p>Тип:{translateType(item.recordType) || '-'}</p>
                    {item?.appointmentServiceToPatientResponses.length < 1 ? (
                        <p>Услуги: -</p>
                    ) : (
                        <p>
                            Услуги:{' '}
                            {item?.appointmentServiceToPatientResponses.map(
                                (el) => el.name
                            )}
                        </p>
                    )}
                    <p>
                        Цена Услуг:{' '}
                        <span
                            style={
                                item?.debt === 0
                                    ? {
                                        color: '#49cc90',
                                        fontWeight: '600',
                                    }
                                    : {
                                        color: 'red',
                                        fontWeight: '600',
                                    }
                            }
                        >
                            {item?.totalPrice}
                        </span>
                    </p>

                    {item?.tooth.length < 1 ? (
                        <p>Зубы: -</p>
                    ) : (
                        <p>Зубы: {toothTransform(item?.tooth)}</p>
                    )}
                    {item?.paid === 0 ? (
                        <p>Оплата: 0</p>
                    ) : (
                        <p>Оплата: {item?.paid}</p>
                    )}
                </>
            );
        case 'SNAPSHOT_DOCTOR':
            return (
                <>
                    <p>Дата загрузки: {transformDate(item.imageCreateAt)}</p>
                    {item?.imageLink && (
                        <>
                            <StyledIMG
                                src={item?.imageLink || ''}
                                alt={item.title}
                                onClick={() => onClick(item.imageLink)}
                            />
                            ;
                        </>
                    )}
                    <p>Визит: {transformDate(item.appointmentCreateAt)}</p>
                    <p>Зубы: {item?.toothToShow || '-'}</p>
                </>
            );
        case 'VISIT_PATIENTS':
            return (
                <>
                    <p>Тип:{translateType(item.recordType) || '-'}</p>
                    {item?.appointmentServiceToPatientResponses.length < 1 ? (
                        <p>Услуги: -</p>
                    ) : (
                        <p>
                            Услуги:{' '}
                            {item?.appointmentServiceToPatientResponses.map(
                                (el) => el.name
                            )}
                        </p>
                    )}
                    <p>
                        Цена Услуг:{' '}
                        <span
                            style={
                                item?.debt === 0
                                    ? {
                                        color: '#49cc90',
                                        fontWeight: '600',
                                    }
                                    : {
                                        color: 'red',
                                        fontWeight: '600',
                                    }
                            }
                        >
                            {item?.totalPrice}
                        </span>
                    </p>

                    {item?.tooth.length < 1 ? (
                        <p>Зубы: -</p>
                    ) : (
                        <p>Зубы: {toothTransform(item?.tooth)}</p>
                    )}
                    {item?.additionalDiscount === 0 ? (
                        <p>Скидка: 0</p>
                    ) : (
                        <p>Скидка: {item?.additionalDiscount}</p>
                    )}
                    {item?.paid === 0 ? (
                        <p>Оплата: 0</p>
                    ) : (
                        <p>Оплата: {item?.paid}</p>
                    )}
                </>
            );
        case 'PATIENTS_PAYMENT':
            return (
                <>
                    <p>
                        Визит :{' '}
                        {item?.dateOfAppointmentAndTime
                            ? transformDate(item?.dateOfAppointmentAndTime)
                            : '-'}
                    </p>
                    <p>
                        Способ оплаты :{' '}
                        {item?.dateOfAppointmentAndTime
                            ? translatePayment(item.typeOfPayment)
                            : '-'}
                    </p>
                    {item?.amountPaid === 0 ? (
                        <p>Сумма: 0</p>
                    ) : (
                        <p>Сумма: {item?.amountPaid}</p>
                    )}
                </>
            );
        case 'DEPOSIT_HEADER':
            return (
                <>
                    <p>
                        Дата создания:{' '}
                        {item?.dateTimeAppointment
                            ? transformDate(item.dateTimeAppointment)
                            : '-'}
                    </p>
                    <p>
                        Тип:{' '}
                        {item?.depositType
                            ? translateDepositType(item.depositType)
                            : '-'}
                    </p>
                    <p>Депозит: {item?.deposit ? item.deposit : '-'}</p>
                </>
            );
        case 'DISCOUNT_HEADER':
            return (
                <>
                    {item?.dateOfCreated && (
                        <p>Дата создании: {item?.dateOfCreated || '-'}</p>
                    )}
                    {item?.sum === 0 ? (
                        <p>Сумма: 0</p>
                    ) : (
                        <p>Сумма:{item?.sum}</p>
                    )}
                </>
            );
        default:
            break;
    }
};

export const CalculateWorksHours = (durationCurrent) => {
    if (durationCurrent._data.years) {
        if (durationCurrent._data.months) {
            return `${durationCurrent._data.years} год ${durationCurrent._data.months} мес`;
        } else {
            if (durationCurrent._data.days) {
                return `${durationCurrent._data.years} год ${durationCurrent._data.days} дней`;
            } else {
                return `${durationCurrent._data.years} год`;
            }
        }
    } else {
        if (durationCurrent._data.months) {
            if (durationCurrent._data.days) {
                return `${durationCurrent._data.months} мес ${durationCurrent._data.days} дней`;
            } else {
                if (durationCurrent._data.hours) {
                    return `${durationCurrent._data.months} мес ${durationCurrent._data.hours} час`;
                } else {
                    return `${durationCurrent._data.months} мес`;
                }
            }
        } else {
            if (durationCurrent._data.days) {
                if (durationCurrent._data.hours) {
                    return `${durationCurrent._data.days} дней ${durationCurrent._data.hours} час`;
                } else {
                    if (durationCurrent._data.minutes) {
                        return `${durationCurrent._data.days} дней  ${durationCurrent._data.minutes} минут`;
                    } else {
                        return `${durationCurrent._data.days} дней`;
                    }
                }
            } else {
                if (durationCurrent._data.hours) {
                    if (durationCurrent._data.minutes) {
                        return `${durationCurrent._data.hours} час  ${durationCurrent._data.minutes} мин`;
                    } else {
                        return `${durationCurrent._data.hours} час`;
                    }
                } else {
                    if (durationCurrent._data.minutes) {
                        return `${durationCurrent._data.minutes} мин`;
                    }
                }
            }
        }
    }
};

export function getMonday(date) {
    const inputDate = new Date(date);
    const dayOfWeek = inputDate.getDay();

    if (dayOfWeek === 1) {
        return inputDate.toISOString().slice(0, 10);
    } else {
        const monday = new Date(inputDate);
        monday.setDate(inputDate.getDate() - ((dayOfWeek + 7) % 7) + 1);
        return monday.toISOString().slice(0, 10);
    }
}

export function getSunday(date) {
    const inputDate = new Date(date);
    const dayOfWeek = inputDate.getDay();
    if (dayOfWeek === 0) {
        return inputDate.toISOString().slice(0, 10);
    } else {
        const sunday = new Date(inputDate);
        const daysUntilNextSunday = 7 - dayOfWeek;
        sunday.setDate(inputDate.getDate() + daysUntilNextSunday);
        return sunday.toISOString().slice(0, 10);
    }
}

export function formatDateToRussian (dateString, str = 'года') {
    const months = [
        'января', 'февраля', 'марта',
        'апреля', 'мая', 'июня',
        'июля', 'августа', 'сентября',
        'октября', 'ноября', 'декабря'
    ];

    const dateParts = dateString.split('-');
    const year = dateParts[0];
    const monthIndex = parseInt(dateParts[1]) - 1;
    const day = dateParts[2];

    const date = new Date(year, monthIndex, day);
    const formattedDate = `${Number(day)}${months[monthIndex]}`;
    return formattedDate;
};