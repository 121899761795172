import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const chatService = createApi({
    reducerPath: 'chatApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getChatClient: builder.query({
            query: () => 'supports/messages',
            providesTags: ['chatSupport'],
        }),
        postChatSupport: builder.mutation({
            query: ({ data }) => {
                return {
                    url: 'supports/send-message',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['chatSupport'],
        }),
        getChatSupport: builder.query({
            query: () => 'supports',
            providesTags: ['chatSupport'],
        }),
        getChatSupportAdmin: builder.query({
            query: (CLINIC_ID) =>
                `/supports/messages/super?clinicId=${CLINIC_ID}`,
            providesTags: ['chatSupport'],
        }),

        deleteSupport: builder.mutation({
            query: (MESSAGE_ID) => {
                return {
                    url: `supports/${MESSAGE_ID}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['chatSupport'],
        }),

        // Client

        getChats: builder.query({
            query: (clinicId) => `chats`,
            providesTags: ['chat'],
        }),
        getChat: builder.query({
            query: (chatId) => `chats/${chatId}`,
            providesTags: ['chat'],
        }),
        postChat: builder.mutation({
            query: ({ data }) => {
                return {
                    url: 'whatsapp-messages/send-message',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['chat'],
        }),
    }),
});
export default chatService;

export const {
    useGetChatClientQuery,
    useGetChatsQuery,
    useGetChatQuery,
    usePostChatMutation,
    usePostChatSupportMutation,
    useGetChatSupportQuery,
    useGetChatSupportAdminQuery,
    useDeleteSupportMutation,
} = chatService;
