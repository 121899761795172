import HeaderPublish from "../header"
import FooterPublish from "../footer"

export default function PrivacyPublish() {
    return (
        <div className="flex flex-col mt-24" >
            <HeaderPublish />
            <section className="px-48 lg:px-28 md:px-10 sm:px-4">
                <p className="w-full text-center font-medium text-lg">Политика конфиденциальности приложения &quot;DentApp&quot;</p>
                <p className="w-full text-center text-md my-8 font-medium">1. Общие положения</p>
                <p className="text-sm mb-4">1.1. Настоящая Политика конфиденциальности определяет порядок обработки и защиты персональных данных пользователей мобильного приложения &quot;DentApp&quot; (далее - &quot;Приложение&quot;) для автоматизации стоматологической практики.</p>
                <p className="text-sm">1.2. Используя Приложение, пользователь выражает безоговорочное согласие с условиями настоящей Политики конфиденциальности.</p>
                <p className="w-full text-center text-md my-8 font-medium">2. Состав обрабатываемых данных</p>
                <p className="text-sm mb-4">2.1. В рамках использования Приложения мы можем собирать следующие данные пользователей: <br />
                    • <span className="font-semibold mt-2">регистрационные данные</span> (имя, номер телефона, email); <br />
                    • <span className="font-semibold">данные о пациентах</span> (имя, дата рождения, анамнез, стоматологический статус и другие медицинские сведения); <br />
                    • <span className="font-semibold">данные о записях на прием</span> (дата/время записи, выбранный врач, оказанные услуги и т.д.). <br />
                    • <span className="font-semibold">Технические данные</span> (информация об устройстве, версия ОС, журналы ошибок)
                </p>
                <p className="text-sm">
                    2.2. Указанные данные необходимы для регистрации учетных записей, предоставления функций Приложения, ведения медицинских карт пациентов и улучшения работы сервиса.
                </p>
                <p className="w-full text-center text-md my-8 font-medium">3. Цели обработки данных</p>
                <p className="text-sm font-medium mb-4"><span className="text-sm font-normal">3.1. Основные цели обработки данных пользователей в Приложении: </span><br />
                    • Регистрация учетных записей врачей и персонала клиники;<br />
                    • Ведение медицинских карт пациентов;<br />
                    • Запись пациентов на прием, управление расписанием врачей;<br />
                    • Информирование об изменениях в работе Приложения;<br />
                    • Анализ использования для развития и оптимизации<br />
                </p>
                <p className="text-sm">3.2. Обработка данных осуществляется в объеме, необходимом для достижения указанных целей в соответствии с законодательством.</p>
                <p className="w-full text-center text-md my-8 font-medium" >4. Передача данных третьим лицам</p>
                <p className="text-sm font-medium mb-4"><span className="font-normal ">4.1. Мы можем передавать данные пользователей Приложения третьим лицам в следующих случаях:</span><br />
                    • Компаниям, обеспечивающим техническую поддержку и развитие Приложения;<br />
                    • При интеграции с платежными системами для оплаты услуг;<br />
                    • в целях соблюдения требований законодательства (по запросам государственных органов и т.д.).</p>
                <p className="text-sm"> 4.2. Мы гарантируем конфиденциальность передаваемых данных и обеспечиваем должный уровень их защиты у третьих лиц.</p>
                <p className="w-full text-center text-md my-8 font-medium">5. Права пользователей</p>
                <p className="font-medium text-sm mb-4"><span className="font-normal">5.1. Пользователи Приложения &quot;DentApp&quot; имеют право:</span><br />
                    • получать информацию об обрабатываемых персональных данных;<br />
                    • требовать уточнения, блокирования или уничтожения своих персональных данных;<br />
                    • отозвать согласие на обработку персональных данных.
                </p>
                <p className="text-sm">5.2. Для реализации указанных прав необходимо обратиться к нам по контактам, указанным в Приложении.</p>
                <p className="w-full text-center text-md my-8 font-medium"> 6. Заключительные положения</p>
                <p className="text-sm mb-4">6.1. Мы оставляем за собой право вносить изменения в настоящую Политику конфиденциальности. Актуальная версия всегда доступна в Приложении.</p>
                <p className="text-sm mb-16">6.2. По всем вопросам, связанным с обработкой персональных данных, вы можете обратиться к нам через службу поддержки Приложения.</p>
                <p className="mb-10">Дата последнего обновления: 01.09.2022</p>
            </section>
            <FooterPublish />
        </div>
    )
}