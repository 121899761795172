import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from './components/notification/Notification';
import { AppRoutes } from './routes/AppRoutes';
import { _KEY_AUTH } from './utils/constans/general';
import { saveTolocalStorage } from './utils/helpers/helpers';
import { authAction } from '../src/store/slices/authSlice';

function App() {
    const dispatch = useDispatch();
    const { token, isAuthorized, role, userID, user, clinicId } = useSelector(
        (state) => state.auth
    );

    const { clinicData } = useSelector((state) => state.clinic);

    useEffect(() => {
        const loginTime =
            JSON.parse(localStorage.getItem('LOGIN_TIME')) || new Date();

        const currentDate = new Date();
        const daysPassed = Math.floor(
            (currentDate.getTime() - new Date(loginTime).getTime()) /
                (1000 * 60 * 60 * 24)
        );

        if (daysPassed >= 25) {
            dispatch(authAction.logout());
        } else {
            saveTolocalStorage(_KEY_AUTH, {
                token: token,
                isAuthorized: isAuthorized,
                role: role,
                userName: user,
                id: userID,
                clinicId: clinicId,
                clinicData: clinicData,
            });
        }
    }, [
        isAuthorized,
        token,
        role,
        clinicId,
        clinicData,
        user,
        userID,
        dispatch,
    ]);

    let docTitle = document.title;
    window.addEventListener('blur', () => {
        document.title = 'Вернитесь в DentApp !';
    });
    window.addEventListener('focus', () => {
        document.title = docTitle;
    });

    return (
        <div className="App">
            <AppRoutes />
            <Notification />
        </div>
    );
}

export default App;
