import React from 'react';
import IconLogo from '../../../assets/images/logo192.png';
import { Link } from 'react-router-dom';

export default function HeaderPublish() {
    return (
        <header className="w-full bg-white border-b-gray-200 border-2 px-5 fixed z-20 top-0">
            <div className="flex items-center justify-between mx-auto p-4 sm:px-[2px] max-w-[1440px]">
                <div className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img
                        className="w-11 h-11 sm:w-[30px] sm:h-[30px]"
                        src={IconLogo}
                        alt=""
                    />
                    <span className="self-center text-2xl font-semibold whitespace-nowrap sm:text-[14px]">
                        <Link to={'/prices'}>
                            DentApp
                        </Link>
                    </span>
                </div>
                <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                    <button
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center sm:text-[10px] sm:py-1"
                    >
                        <Link target="_blank" to={'/login'}>
                            Войти в систему
                        </Link>
                    </button>
                </div>
            </div>
        </header>
    );
}
