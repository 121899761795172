import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const postWorkPatternSlice = createAsyncThunk(
    'workPattern/postWorkPatternSlice',
    async ({ data }, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().post(
                `/work-patterns`,
                data
            );
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const getWorkPatternSlice = createAsyncThunk(
    'workPattern/getWorkPatternSlice',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(`work-patterns`);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const getSingleWorkPatternSlice = createAsyncThunk(
    'workPattern/getSingleWorkPatternSlice',
    async ({ workPatternId }, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(
                `/work-patterns/${workPatternId}`
            );
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const putSingleWorkPatternSlice = createAsyncThunk(
    'workPattern/putSingleWorkPatternSlice',
    async (
        { workPatternId, workPatternData },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const response = await axiosConfigInstance().put(
                `/work-patterns/${workPatternId}`,
                workPatternData
            );
            dispatch(getWorkPatternSlice());
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const deleteSingleWorkPatternSlice = createAsyncThunk(
    'workPattern/deleteSingleWorkPatternSlice',
    async ({ workPatternId }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `/work-patterns/${workPatternId}`
            );
            dispatch(getWorkPatternSlice());
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

const initialState = {
    workPatternData: [],
    workSinglePatternData: {},
    isLoading: false,
};

const workPatternSlice = createSlice({
    name: 'workPattern',
    initialState,
    reducers: {},
    extraReducers: {
        //post
        [postWorkPatternSlice.pending]: (state) => {
            state.isLoading = true;
        },
        [postWorkPatternSlice.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [postWorkPatternSlice.rejected]: (state) => {
            state.isLoading = false;
        },
        //--
        [getWorkPatternSlice.pending]: (state) => {
            state.isLoading = true;
        },
        [getWorkPatternSlice.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.workPatternData = payload;
        },
        [getWorkPatternSlice.rejected]: (state) => {
            state.isLoading = false;
        },
        //--
        [getSingleWorkPatternSlice.pending]: (state) => {
            state.isLoading = true;
        },
        [getSingleWorkPatternSlice.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.workSinglePatternData = payload;
        },
        [getSingleWorkPatternSlice.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default workPatternSlice;
