import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const serviceService = createApi({
    reducerPath: 'serviceApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getServiceList: builder.query({
            query: () => 'services',
        }),
        getServiceAll: builder.query({
            query: () => 'services/all',
        }),
    }),
});
export default serviceService;

export const { useGetServiceListQuery, useGetServiceAllQuery } = serviceService;
