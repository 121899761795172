import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styled from 'styled-components';
import { Button } from '@mui/material';

export const MeatBalls = ({ options }) => {
    const [anchorEl, setAnchorEl] = useState(null || HTMLElement > null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = ({ click, id }) => {
        setAnchorEl(null);
        click(id);
    };
    return (
        <>
            <MeatBallsButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(event) => handleClick(event)}
            >
                <MoreVertIcon />
            </MeatBallsButton>

            <GeneralMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: -190,
                }}
            >
                {options.map((el) =>
                    el === undefined ? undefined : (
                        <MenuItems
                            key={Math.random()}
                            onClick={() =>
                                handleClose({ click: el.click, id: el.id })
                            }
                        >
                            <MenuItemIcon>{el.icon}</MenuItemIcon>
                            <p>{el.name}</p>
                        </MenuItems>
                    )
                )}
            </GeneralMenu>
        </>
    );
};

const GeneralMenu = styled(Menu)`
    /* & .MuiTouchRipple {
        width: auto;
    } */
`;

const MeatBallsButton = styled(Button)`
    color: #1976d2;
    cursor: pointer;
    padding: 0 !important;
    display: flex;
    min-width: 0 !important;
`;

const MenuItems = styled(MenuItem)`
    background-color: transparent !important;
    transition: all 0.2s;
    &:hover {
        background: rgba(26, 35, 126, 0.07) !important;
    }
`;

const MenuItemIcon = styled.p`
    padding-right: 10px;
`;
