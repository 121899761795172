import React, { useRef, useState } from 'react';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper } from 'swiper/react';
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md';
import clsx from 'clsx';
import 'swiper/css';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function ContainerSlider({ children, cardWidth }) {
    const swiperRef = useRef(null);
    const windowWidth = useWindowWidth();

    const [canSlidePrev, setCanSlidePrev] = useState(false);
    const [canSlideNext, setCanSlideNext] = useState(true);

    const goNext = () => {
        if (swiperRef.current && canSlideNext) {
            swiperRef.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiperRef.current && canSlidePrev) {
            swiperRef.current.swiper.slidePrev();
        }
    };

    return (
        <div className="w-full flex flex-col items-center justify-center relative">
            <div className="w-full px-5">
                <Swiper
                    ref={swiperRef}
                    slidesPerView={
                        windowWidth > 1440
                            ? 4
                            : Math.floor(windowWidth / cardWidth)
                    }
                    spaceBetween={30}
                    onSlideChange={({ isBeginning, isEnd }) => {
                        setCanSlidePrev(!isBeginning);
                        setCanSlideNext(!isEnd);
                    }}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation]}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                >
                    {children}
                </Swiper>
            </div>
            <div
                onClick={goPrev}
                style={{
                    backgroundColor: canSlidePrev ? '#1c4ed8' : 'gainsboro',
                }}
                className={clsx(
                    'p-2 cursor-pointer text-white rounded-lg relative top-6 right-10 w-fit',
                    {
                        'pointer-events-none': !canSlidePrev,
                    }
                )}
            >
                <MdOutlineNavigateBefore size={20} />
            </div>
            <div
                onClick={goNext}
                style={{
                    backgroundColor: canSlideNext ? '#1c4ed8' : 'gainsboro',
                }}
                className={clsx(
                    'p-2 cursor-pointer text-white rounded-lg relative bottom-3 left-10 w-fit',
                    {
                        'pointer-events-none': !canSlideNext,
                    }
                )}
            >
                <MdOutlineNavigateNext size={20} />
            </div>
        </div>
    );
}
