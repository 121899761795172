import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';

export const getAllInfoDiagram = createAsyncThunk(
    'dashboard/getAllInfoDiagram',
    async ({ TYPE_OF_DATE }, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(
                `heartbeats?dateType=${TYPE_OF_DATE}`
            );
            return response.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

const initialState = {
    isOpen: false,
    isLoading: false,
    diagramData: [],
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        OpenAndCloseInDashboard(state, action) {
            state.isOpen = action.payload;
        },
    },
    extraReducers: {
        [getAllInfoDiagram.pending]: (state) => {
            state.isLoading = true;
        },
        [getAllInfoDiagram.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.diagramData = action.payload;
        },
        [getAllInfoDiagram.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export const { OpenAndCloseInDashboard } = dashboardSlice.actions;
export default dashboardSlice;
