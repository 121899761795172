import React, { useState } from 'react';

export default function DentApp() {
    const [active, setActive] = useState(2);
    const HeaderTab = [
        {
            title: 'Владельцам',
            id: 0,
        },
        {
            title: 'Врачам',
            id: 1,
        },
        {
            title: 'Администраторам',
            id: 2,
        },
    ];

    const ContentTab = [
        [
            {
                title: 'Прибыль',
                describtion: 'Увеличение прибыли более чем на 20%',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="sm:w-[40px] sm:h-[40px]"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#1c4ed8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <circle cx="12" cy="12" r="10" />
                        <path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8" />
                        <path d="M12 18V6" />
                    </svg>
                ),
            },
            {
                title: 'Точность',
                describtion: 'Получение точной и актуальной отчетности',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="sm:w-[40px] sm:h-[40px]"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#1c4ed8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <circle cx="12" cy="12" r="10" />
                        <line x1="22" x2="18" y1="12" y2="12" />
                        <line x1="6" x2="2" y1="12" y2="12" />
                        <line x1="12" x2="12" y1="6" y2="2" />
                        <line x1="12" x2="12" y1="22" y2="18" />
                    </svg>
                ),
            },
            {
                title: 'Маркетинг',
                describtion:
                    'Увеличение возвращаемости клиентов за счет модуля “Маркетинг”',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="sm:w-[40px] sm:h-[40px]"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#1c4ed8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="m3 8 4-4 4 4" />
                        <path d="M7 4v16" />
                        <path d="M11 12h10" />
                        <path d="M11 16h7" />
                        <path d="M11 20h4" />
                    </svg>
                ),
            },
            {
                title: 'Загрузка',
                describtion: 'Увеличении загрузки клиники более чем на 30%',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="sm:w-[40px] sm:h-[40px]"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#1c4ed8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="M15.6 2.7a10 10 0 1 0 5.7 5.7" />
                        <circle cx="12" cy="12" r="2" />
                        <path d="M13.4 10.6 19 5" />
                    </svg>
                ),
            },
        ],
        [
            {
                title: 'Удобство',
                describtion:
                    'Быстрое и удобное заполнение плана лечения за 30 сек на всех экранах',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="sm:w-[40px] sm:h-[40px]"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#1c4ed8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <rect width="10" height="14" x="3" y="8" rx="2" />
                        <path d="M5 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-2.4" />
                        <path d="M8 18h.01" />
                    </svg>
                ),
            },
            {
                title: 'Отчетность',
                describtion: 'Прозрачная отчётность по своим лечениям',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="sm:w-[40px] sm:h-[40px]"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#1c4ed8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20" />
                        <path d="M12 13V7" />
                        <path d="m9 10 3 3 3-3" />
                    </svg>
                ),
            },
            {
                title: 'Средний чек',
                describtion:
                    'Увеличение среднего чека за счет комплексных планов лечения',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="sm:w-[40px] sm:h-[40px]"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#1c4ed8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
                        <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                        <path d="m9 14 2 2 4-4" />
                    </svg>
                ),
            },
            {
                title: 'Точность',
                describtion: 'Своё точное и актуальное расписание',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="sm:w-[40px] sm:h-[40px]"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#1c4ed8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <circle cx="12" cy="12" r="10" />
                        <polyline points="12 6 12 12 16.5 12" />
                    </svg>
                ),
            },
        ],
        [
            {
                title: 'Оптимизация',
                describtion:
                    'Планировка графика работы докторов за счет удобного календаря',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="sm:w-[40px] sm:h-[40px]"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#1c4ed8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="M15.6 2.7a10 10 0 1 0 5.7 5.7" />
                        <circle cx="12" cy="12" r="2" />
                        <path d="M13.4 10.6 19 5" />
                    </svg>
                ),
            },
            {
                title: 'Автоматизированные',
                describtion:
                    'Автоматизированные уведомления, рассылки и задачи',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="sm:w-[40px] sm:h-[40px]"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#1c4ed8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <rect width="16" height="13" x="6" y="4" rx="2" />
                        <path d="m22 7-7.1 3.78c-.57.3-1.23.3-1.8 0L6 7" />
                        <path d="M2 8v11c0 1.1.9 2 2 2h14" />
                    </svg>
                ),
            },
            {
                title: 'Отчетность',
                describtion: 'Быстрое формирование необходимой отчетности',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="sm:w-[40px] sm:h-[40px]"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#1c4ed8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="M4 19.5v-15A2.5 2.5 0 0 1 6.5 2H20v20H6.5a2.5 2.5 0 0 1 0-5H20" />
                        <path d="M12 13V7" />
                        <path d="m9 10 3 3 3-3" />
                    </svg>
                ),
            },
            {
                title: 'Фин учет',
                describtion: 'Полноценное ведение финансовой отчетности',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="sm:w-[40px] sm:h-[40px]"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#1c4ed8"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <rect width="18" height="18" x="3" y="3" rx="2" />
                        <path d="M3 9a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2" />
                        <path d="M3 11h3c.8 0 1.6.3 2.1.9l1.1.9c1.6 1.6 4.1 1.6 5.7 0l1.1-.9c.5-.5 1.3-.9 2.1-.9H21" />
                    </svg>
                ),
            },
        ],
    ];

    return (
        <div className="w-full flex flex-col gap-3 mx-auto">
            <h1 className="text-[40px] sm:text-[12px]">
                DentApp открывает возможности
            </h1>
            <span className="sm:text-[10px]">
                Готовое решение для успешного управления стоматологией или
                клиникой.
            </span>
            <div className="w-full flex gap-5 px-2 py-4 border-b">
                {HeaderTab.map((item) => {
                    return (
                        <div
                            onClick={() => setActive(item.id)}
                            key={item.id}
                            className={`px-2 py-2 text-[22px] cursor-pointer sm:text-[10px] sm:px-0 sm:py-0 ${
                                item.id === active
                                    ? 'text-[#1c4ed8]'
                                    : 'text-[#b8c8da]'
                            }`}
                        >
                            {item.title}
                        </div>
                    );
                })}
            </div>
            <div className="h-fit px-2.5 py-7 flex justify-between items-center md:flex-wrap sm:py-2 sm:px-1 sm:gap-5">
                {ContentTab[active].map((item, index) => {
                    return (
                        <div
                            className="w-[250px] sm:w-[100px] sm:h-[100px] sm:max-h-[100px] sm:min-h-[100px] flex flex-col gap-3 sm:gap-1 items-center justify-between text-center min-h-[220px] max-h-[220px]"
                            key={index}
                        >
                            <div className="w-[80px] h-[80px] max-w-[80px] min-w-[80px] min-h-[80px] max-h-[80px] sm:w-[40px] sm:h-[40px] sm:min-w-[40px] sm:max-w-[40px] sm:min-h-[40px] sm:max-h-[40px]">
                                {item.icon}
                            </div>
                            <div className="text-[18px] text-[#1c4ed8] font-semibold sm:text-[10px]">
                                {item.title}
                            </div>
                            <div className="text-[gray]/90 sm:text-[8px]">
                                {item.describtion}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
