import React from 'react';
import { AdminRoutes } from './AdminRoutes';
import { GuestRoutes } from './GuestRoutes';
import { useSelector } from 'react-redux';
import { SuperAdmin } from './SuperAdmin';
import { DoctorRoutes } from './DoctorRoutes';
import NotFoundPage from '../pages/error-page/NotFoundPage';
import { ManagerRoutes } from './ManagerRoutes';

export const AppRoutes = () => {
    const { isAuthorized, role } = useSelector((state) => state.auth);
    if (!isAuthorized) {
        return <GuestRoutes />;
    }
    switch (role) {
        case 'ADMIN':
            return <AdminRoutes />;
        case 'DOCTOR':
            return <DoctorRoutes />;
        case 'DEVELOPER':
            return <SuperAdmin />;
        case 'MANAGER':
            return <ManagerRoutes />;
        default:
            return <NotFoundPage />;
    }
};
