import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllStocks } from '../../../../store/slices/stockSlice';
import styled from 'styled-components';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useState } from 'react';
import Input from '../../../../components/UI/inputs';
import Button from '../../../../components/UI/buttons';
import { postDocument } from '../../../../store/slices/documentSlice';
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { ImDropbox } from 'react-icons/im';
import ModalStock from '../../stock/ModalStock';
import { MdOutlineNoteAlt } from 'react-icons/md';

const CraeteDocumentPage = () => {
    const { allStock } = useSelector((state) => state.stock);
    const [state, setState] = useState([]);
    const [data, setData] = useState([]);
    const [finalData, setFinalData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [inputValues, setInputValues] = useState({
        supplier: '',
        description: '',
        totalPrice: '',
        itemAddQuantityRequests: [],
    });

    const animatedComponents = makeAnimated();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getAllStocks());
    }, [dispatch]);

    useEffect(() => {
        setData(
            allStock?.itemResponses?.map((item) => {
                return {
                    label: `${item.name} - ${item.price}`,
                    value: item.id,
                    totalPrice: item.totalPrice,
                    quantity: 1,
                    price: item.price,
                    id: item.id,
                    name: item.name,
                };
            })
        );
    }, [allStock?.itemResponses]);

    const handleIncrement = (index) => {
        setState((prevState) => {
            const newState = [...prevState];
            newState[index].quantity += 1;
            return newState;
        });
    };

    const handleDecrement = (index) => {
        setState((prevState) => {
            const newState = [...prevState];
            if (newState[index].quantity > 0) {
                newState[index].quantity -= 1;
            }
            return newState;
        });
    };

    const handleInputChange = (event, index) => {
        const { value } = event.target;
        setState((prevState) => {
            const newState = [...prevState];
            newState[index].quantity = parseInt(value, 10);
            return newState;
        });
    };

    const handlePriceChange = (event, index) => {
        const { value } = event.target;
        setState((prevState) => {
            const newState = [...prevState];
            newState[index].price = parseInt(value, 10);
            return newState;
        });
    };

    const [totalPriceInfo, setTotalPriveInfo] = useState();

    useEffect(() => {
        setFinalData(
            state.map((item) => {
                return {
                    itemId: item.id,
                    price: item.price,
                    quantity: item.quantity,
                };
            })
        );

        if (state) {
            setTotalPriveInfo(
                state
                    .map(({ price, quantity }) => price * quantity)
                    .reduce((accumulator, item) => accumulator + item, 0)
            );
        }
    }, [data, state]);

    useEffect(() => {
        setInputValues((prevData) => ({
            ...prevData,
            itemAddQuantityRequests: finalData,
        }));
    }, [finalData]);

    function handleClick() {
        dispatch(postDocument({ data: inputValues }));
        navigate(-1);
        setInputValues({
            supplier: '',
            description: '',
            totalPrice: '',
            itemAddQuantityRequests: [],
        });
    }

    return (
        <Wrapper>
            <Header>
                <WrapperName onClick={() => navigate(-1)}>
                    <ArrowStyled>
                        <BiArrowBack
                            size="25px"
                            color="#5460e6"
                            cursor="pointer"
                        />
                    </ArrowStyled>
                    <h1>Назад</h1>
                </WrapperName>
                <WrapperButtons>
                    <Button onClick={() => setShowModal(true)}>
                        <ImDropbox />
                        Добавить товар
                    </Button>
                </WrapperButtons>
                <ModalStock active={showModal} setActive={setShowModal} />
            </Header>
            <InnerWrapper>
                <RightBlock>
                    <Input
                        label="Поставщик"
                        onChange={(e) =>
                            setInputValues({
                                ...inputValues,
                                supplier: e.target.value,
                            })
                        }
                        value={inputValues.supplier}
                    />
                    <WrapperInputDesAndPrice>
                        <div style={{ width: '60%' }}>
                            <Input
                                label="Описание"
                                onChange={(e) =>
                                    setInputValues({
                                        ...inputValues,
                                        description: e.target.value,
                                    })
                                }
                                value={inputValues.description}
                            />
                        </div>
                        <div style={{ width: '40%' }}>
                            <Input
                                label="Сумма"
                                type="number"
                                onChange={(e) =>
                                    setInputValues({
                                        ...inputValues,
                                        totalPrice: e.target.value,
                                    })
                                }
                                value={inputValues.totalPrice}
                            />
                        </div>
                    </WrapperInputDesAndPrice>
                    <div>
                        <StyledLable>Товары</StyledLable>
                        <Select
                            name="Диагноз"
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            placeholder="Поиск"
                            isMulti
                            onChange={(e) => setState(e)}
                            options={data}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    padding: '5px',
                                    cursor: 'pointer',
                                }),
                            }}
                        />
                    </div>
                </RightBlock>
                <LeftBlock>
                    {state.length < 1 ? (
                        <WrapperNot>
                            <div>
                                <MdOutlineNoteAlt size="30px" />
                                <span>Пусто</span>
                            </div>
                            <h2>Выберите товар !</h2>
                        </WrapperNot>
                    ) : (
                        state?.map((item, index) => (
                            <Item key={item.value}>
                                <div>
                                    <span>{index + 1}.</span>
                                    <span>{item.name}</span>
                                </div>

                                <WrapperAmountAndCancel>
                                    <WrapperPrice>
                                        <StyledLable>Сумма: </StyledLable>
                                        <input
                                            type="number"
                                            value={item.price}
                                            onChange={(e) =>
                                                handlePriceChange(e, index)
                                            }
                                        />
                                    </WrapperPrice>
                                    <WrapperPrice>
                                        <StyledLable>Количество: </StyledLable>
                                        <WrapperAmount>
                                            <button
                                                onClick={() =>
                                                    handleDecrement(index)
                                                }
                                            >
                                                -
                                            </button>
                                            <input
                                                type="number"
                                                value={item.quantity}
                                                onChange={(e) =>
                                                    handleInputChange(e, index)
                                                }
                                            />
                                            <button
                                                onClick={() =>
                                                    handleIncrement(index)
                                                }
                                            >
                                                +
                                            </button>
                                        </WrapperAmount>
                                    </WrapperPrice>
                                </WrapperAmountAndCancel>
                            </Item>
                        ))
                    )}
                </LeftBlock>
            </InnerWrapper>
            <StyledFooterButton>
                <div>
                    <h2>Общая сумма: {totalPriceInfo}</h2>
                    <Button
                        width="200px"
                        disabled={
                            inputValues.supplier === ''
                                ? true
                                : inputValues.description === ''
                                ? true
                                : inputValues.totalPrice === ''
                                ? true
                                : inputValues.itemAddQuantityRequests.length ===
                                  0
                                ? true
                                : false
                        }
                        onClick={handleClick}
                    >
                        Сохранить
                    </Button>
                </div>
            </StyledFooterButton>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
const WrapperPrice = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    input {
        outline: none;
        width: 50px;
        border: none;
        border-radius: 4px;
        padding: 0 2px;
        text-align: center;
        :focus {
            -webkit-box-shadow: 0px 0px 1px 1px rgba(84, 96, 230, 1);
            -moz-box-shadow: 0px 0px 1px 1px rgba(84, 96, 230, 1);
            box-shadow: 0px 0px 1px 1px rgba(84, 96, 230, 1);
        }
    }
`;
const WrapperInputDesAndPrice = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    input {
        :focus {
            -webkit-box-shadow: 0px 0px 1px 1px rgba(84, 96, 230, 1);
            -moz-box-shadow: 0px 0px 1px 1px rgba(84, 96, 230, 1);
            box-shadow: 0px 0px 1px 1px rgba(84, 96, 230, 1);
        }
    }
`;
const WrapperNot = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #d6d6d6;
    margin: 15px 0;
    span {
        font-size: 20px;
    }
    div {
        display: flex;
        align-items: center;
    }
    h2 {
        font-size: 14px;
        color: #d6d6d6;
    }
`;
const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const ArrowStyled = styled.div`
    position: relative;
    :hover {
        transform: translateX(-15%);
        transition: ease-in-out 0.5s;
    }
`;
const WrapperName = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    h1 {
        font-family: 'PT Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        color: #5460e6;
        text-decoration: underline;
        cursor: pointer;
    }
`;
const WrapperButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
const WrapperAmountAndCancel = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    @media screen and (max-width: 400px) {
        flex-direction: column;
        align-items: start;
    }
`;
const StyledFooterButton = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    div {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 6px;
    }
    h2 {
        font-family: 'PT Sans', sans-serif;
        font-size: 17px;
    }
`;
const InnerWrapper = styled.div`
    width: 100%;
    gap: 20px;
    display: flex;
    margin-top: 20px;
    align-items: flex-start;
    @media screen and (max-width: 900px) {
        flex-direction: column;
    }
`;
const RightBlock = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @media screen and (max-width: 900px) {
        width: 100%;
    }
`;
const LeftBlock = styled.div`
    width: 50%;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 25px;
    -webkit-box-shadow: 0px 0px 1px 2px rgba(34, 60, 80, 0.12);
    -moz-box-shadow: 0px 0px 1px 2px rgba(34, 60, 80, 0.12);
    box-shadow: 0px 0px 1px 2px rgba(34, 60, 80, 0.12);
    @media screen and (max-width: 900px) {
        width: 100%;
    }
`;
const Item = styled.div`
    border-radius: 5px;
    padding: 5px 10px 5px;
    background-color: #efeff6;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 460px) {
        flex-direction: column;
        align-items: start;
        font-size: 12px;
        gap: 5px;
    }
`;
const WrapperAmount = styled.div`
    display: flex;
    justify-content: space-around;
    gap: 5px;
    input {
        outline: none;
        width: 32px;
        border: none;
        border-radius: 4px;
        padding: 0 2px;
        text-align: center;
        :focus {
            -webkit-box-shadow: 0px 0px 1px 1px rgba(84, 96, 230, 1);
            -moz-box-shadow: 0px 0px 1px 1px rgba(84, 96, 230, 1);
            box-shadow: 0px 0px 1px 1px rgba(84, 96, 230, 1);
        }
    }
    button {
        width: 25px;
        background-color: white;
        border-radius: 4px;
        :active {
            background-color: #5460e6;
            color: white;
        }
    }
`;

const StyledLable = styled.label`
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #87939e;
`;

export default CraeteDocumentPage;
