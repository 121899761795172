import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BiSupport } from 'react-icons/bi';
import {
    BsFillTelephoneOutboundFill,
    BsTelegram,
    BsThreeDotsVertical,
    BsWhatsapp,
} from 'react-icons/bs';
import { Popover, Typography } from '@mui/material';
import { MdSend } from 'react-icons/md';
import InputEmojiWithRef from 'react-input-emoji';
import { useDispatch, useSelector } from 'react-redux';
import MessageMain from './MessageMain';
import { useParams } from 'react-router-dom';
import { getSingleClients } from '../../../../store/slices/clientSlice';
import {
    useGetChatSupportAdminQuery,
    usePostChatSupportMutation,
} from '../../../../services/chat.service';
import { showSuccessMessage } from '../../../../components/notification/Notification';

const SupportInside = () => {
    const { clinicId } = useSelector((state) => state.auth);
    const { singleClinic } = useSelector((state) => state.client);

    const [text, setText] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [validation, setValidation] = useState(false);

    const { id } = useParams();

    const [postChatSupport] = usePostChatSupportMutation();
    const { data: adminSupport } = useGetChatSupportAdminQuery(id);

    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        dispatch(getSingleClients({ ID: id }));
    }, [id, dispatch]);

    const open = Boolean(anchorEl);
    const ID = open ? 'simple-popover' : undefined;

    useEffect(() => {
        setValidation(text.length > 0);
    }, [text.length]);

    async function handleOnEnter(text) {
        try {
            const response = await postChatSupport({
                data: {
                    clinicId: clinicId || id,
                    channel: true,
                    message: text,
                },
            }).unwrap();
            showSuccessMessage(response.message);
        } catch (error) {}
    }

    const sendMessage = async () => {
        try {
            const response = await postChatSupport({
                data: {
                    clinicId: clinicId || id,
                    channel: true,
                    message: text,
                },
            }).unwrap();
            showSuccessMessage(response.message);
        } catch (error) {}
    };

    return (
        <Wrapper>
            <WrapperHeader>
                <WrapperHeaderRightBlock>
                    <WrapperLogo>
                        <BiSupport fontSize="25px" />
                    </WrapperLogo>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <h1>
                            {adminSupport?.clinicId}.{adminSupport?.clinicName}{' '}
                        </h1>
                        <StyledLabel>{adminSupport?.admin}</StyledLabel>
                    </div>
                </WrapperHeaderRightBlock>
                <WrapperHeaderLefttBlock>
                    <BsThreeDotsVertical
                        fontSize="25px"
                        aria-describedby={ID}
                        variant="contained"
                        onClick={handleClick}
                    />
                    <Popover
                        id={ID}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Typography sx={{ p: 1 }}>
                            <StyledLink
                                target="_blank"
                                href={`https://wa.me/+${singleClinic?.phoneNumber}`}
                                rel="noreferrer"
                                style={{
                                    color: '#42cba5',
                                }}
                            >
                                <BsWhatsapp fontSize="20px" /> WhatsApp
                            </StyledLink>
                        </Typography>
                        <Typography sx={{ p: 1 }}>
                            <StyledLink
                                target="_blank"
                                href={`https://t.me/+${singleClinic?.phoneNumber}`}
                                rel="noreferrer"
                                style={{
                                    color: '#2aa1da',
                                }}
                            >
                                <BsTelegram fontSize="20px" /> Telegram
                            </StyledLink>
                        </Typography>
                        <Typography sx={{ p: 1 }}>
                            <StyledLink
                                target="_blank"
                                href={`tel:+${singleClinic?.phoneNumber}`}
                                rel="noreferrer"
                                style={{
                                    color: '#f2741c',
                                }}
                            >
                                <BsFillTelephoneOutboundFill fontSize="20px" />{' '}
                                Телефон
                            </StyledLink>
                        </Typography>
                    </Popover>
                </WrapperHeaderLefttBlock>
            </WrapperHeader>
            <MessageMain />
            <WrapperSendMessage>
                <WrapperInputMessage>
                    <InputEmojiWithRef
                        value={text}
                        onChange={setText}
                        cleanOnEnter
                        onEnter={handleOnEnter}
                        placeholder="Type a message"
                    />
                </WrapperInputMessage>
                <WrapperSend
                    onClick={() => {
                        if (validation) {
                            sendMessage();
                        }
                    }}
                >
                    <MdSend fontSize="25px" color="#5460e6" />
                </WrapperSend>
            </WrapperSendMessage>
        </Wrapper>
    );
};
const WrapperInputMessage = styled.div`
    width: 100%;
`;
const StyledLabel = styled.label`
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: black;
`;

const StyledLink = styled.a`
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const Wrapper = styled.div`
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    position: relative;
    @media screen and (max-width: 900px) {
        width: 100%;
    }
    @media screen and (max-width: 620px) {
        width: 100%;
    }
`;
const WrapperHeader = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ffffff;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 5px;
`;
const WrapperLogo = styled.div`
    background-color: #5460e6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    width: 40px;
    height: 40px;
    color: #ffffff;
`;

const WrapperHeaderRightBlock = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    h1 {
        font-family: 'PT Sans', sans-serif;
        font-size: 18px;
        font-weight: 400;
        color: #87939e;
    }
`;
const WrapperHeaderLefttBlock = styled.div`
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s;
    :active {
        transition: 0.2s;
        background-color: #f2f6fa;
    }
`;

const WrapperSendMessage = styled.div`
    width: 100%;
    background-color: #537188;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    padding: 5px 10px;
    fill: #ffffff !important;
    .react-input-emoji--button {
        stroke: #ffffff;
    }
`;
const WrapperSend = styled.div`
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.2s;
    scale: 1.2;
    :active {
        transition: 0.2s;
        scale: 0.8;
    }
`;

export default SupportInside;
