import React, { useState } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import CountUp from 'react-countup';

export default function CountUps() {
    const [counterOn, setCounterOn] = useState(false);
    return (
        <ScrollTrigger
            onEnter={() => setCounterOn(true)}
            onExit={() => setCounterOn(false)}
            className="w-full h-[300px] md:h-fit md:py-4 bg-gradient-to-r from-[#5361e7]/40 via-[#5361e7]/60 to-[#5361e7]/90 relative z-10 flex justify-center items-center"
        >
            <div className="w-full max-w-[1440px] flex md:flex-wrap md:gap-4 items-center justify-around px-10 sm:px-0">
                <div className="bg-white w-[230px] h-[180px]  flex flex-col items-center justify-center p-4 rounded-xl text-center text-[15px] text-[#6874ea]">
                    <svg
                        className="sm:w-[30px] sm:h-[30px]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M9 12L11 14L15 10M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
                            stroke="#6874ea"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <div className="text-[30px] sm:text-[15px]">
                        {counterOn ? (
                            <CountUp
                                className="text-[35px] sm:text-[20px]"
                                start={1}
                                end={100}
                                duration={1}
                                delay={0}
                            />
                        ) : (
                            123
                        )}
                        +
                    </div>
                    Докторов доверяют нам
                </div>
                <div className="bg-white w-[230px] h-[180px] flex flex-col items-center justify-center p-4 rounded-xl text-center text-[16px] text-[#6874ea]">
                    <svg
                        className="sm:w-[30px] sm:h-[30px]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21M21 12C21 9.61305 20.0518 7.32387 18.364 5.63604C16.6761 3.94821 14.3869 3 12 3M21 12H3M12 21C9.61305 21 7.32387 20.0518 5.63604 18.364C3.94821 16.6761 3 14.3869 3 12M12 21C13.657 21 15 16.97 15 12C15 7.03 13.657 3 12 3M12 21C10.343 21 9 16.97 9 12C9 7.03 10.343 3 12 3M12 3C9.61305 3 7.32387 3.94821 5.63604 5.63604C3.94821 7.32387 3 9.61305 3 12"
                            stroke="#6874ea"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <div className="text-[30px] sm:text-[15px]">
                        {counterOn ? (
                            <CountUp
                                className="text-[35px] sm:text-[20px]"
                                start={1}
                                end={2}
                                duration={2}
                                delay={0}
                            />
                        ) : (
                            3
                        )}
                        +
                    </div>
                    Стран используют DentApp
                </div>
                <div className="bg-white w-[230px] h-[180px] flex flex-col items-center justify-center p-4 rounded-xl text-center text-[15px] text-[#6874ea]">
                    <svg
                        className="mr-4 sm:w-[30px] sm:h-[30px]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M9 12.0001L11 14.0001L15 10.0001M20.618 5.98409C17.4561 6.15201 14.3567 5.0587 12 2.94409C9.64327 5.0587 6.5439 6.15201 3.382 5.98409C3.12754 6.96921 2.99918 7.98264 3 9.00009C3 14.5911 6.824 19.2901 12 20.6221C17.176 19.2901 21 14.5921 21 9.00009C21 7.95809 20.867 6.94809 20.618 5.98409Z"
                            stroke="#6874ea"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <div className="text-[30px] sm:text-[15px]">
                        {counterOn ? (
                            <CountUp
                                className="text-[35px] sm:text-[20px]"
                                start={1}
                                end={100}
                                duration={1}
                                delay={0}
                            />
                        ) : (
                            100
                        )}
                        %
                    </div>
                    Безопасность хранения данных
                </div>
            </div>
        </ScrollTrigger>
    );
}
