import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const getTariffs = createAsyncThunk(
    'tariffs/getTariffs',
    async (_, { rejectedWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(`tariffs`);
            return response.data;
        } catch (error) {
            return rejectedWithValue(error.message);
        }
    }
);

export const putTariffs = createAsyncThunk(
    'tariffs/putTariffs',
    async ({ Data, ID }, { rejectedWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                `tariffs/${ID}`,
                Data
            );
            showSuccessMessage(response.data.message);
            dispatch(getTariffsPattern());
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

export const getEditTariffs = createAsyncThunk(
    'tariffs/getEditTariffs',
    async ({ ID }, { rejectedWithValue, dispatch }) => {
        try {
            const { data } = await axiosConfigInstance().get(`tariffs/${ID}`);
            return data;
        } catch (error) {
            rejectedWithValue(error.message);
        }
    }
);

export const postTariffs = createAsyncThunk(
    'tariffs/postTariffs',
    async ({ data }, { rejectedWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().post('tariffs', data);
            showSuccessMessage(response.data.message);
            dispatch(getTariffsPattern());
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectedWithValue(error.message);
        }
    }
);

export const getTariffsPattern = createAsyncThunk(
    'tariffs/getTariffsPattern',
    async (_, { rejectedWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(`tariffs/pattern`);
            return response.data;
        } catch (error) {
            return rejectedWithValue(error.message);
        }
    }
);

export const deleteTariff = createAsyncThunk(
    'tariffs/deleteTariff',
    async ({ id }, { rejectedWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `tariffs/${id}`
            );
            showSuccessMessage(response.data.message);
            dispatch(getTariffsPattern());
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectedWithValue(error.message);
        }
    }
);

const initialState = {
    tariffs: [],
    tariffsPattern: [],
    putEditTariffs: null,
    singleClinic: null,
    isLoading: false,
};

export const tariffsSlice = createSlice({
    name: 'tariffs',
    initialState,
    reducers: {},
    extraReducers: {
        [getTariffs.pending]: (state) => {
            state.isLoading = true;
        },
        [getTariffs.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.tariffs = action.payload;
        },
        [getTariffs.rejected]: (state) => {
            state.isLoading = false;
        },

        [getTariffsPattern.pending]: (state) => {
            state.isLoading = true;
        },
        [getTariffsPattern.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.tariffsPattern = action.payload;
        },
        [getTariffsPattern.rejected]: (state) => {
            state.isLoading = false;
        },

        //post

        [postTariffs.pending]: (state) => {
            state.isLoading = true;
        },
        [postTariffs.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [postTariffs.rejected]: (state) => {
            state.isLoading = false;
        },

        //delete

        [deleteTariff.pending]: (state) => {
            state.isLoading = true;
        },
        [deleteTariff.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [deleteTariff.rejected]: (state) => {
            state.isLoading = false;
        },

        //getEditTariffs

        [getEditTariffs.pending]: (state) => {
            state.isLoading = true;
        },
        [getEditTariffs.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.putEditTariffs = payload;
        },
        [getEditTariffs.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});
export default tariffsSlice;
