export const ROLES = {
    ADMIN: 'ADMIN',
    DOCTOR: 'DOCTOR',
    CLINIC: 'CLINIC',
};
export const ROUTES = {
    LOGIN: {
        path: '/login',
    },
    NOT_FOUND_PAGE: {
        path: '*',
    },
};

export const ADMIN_ROUTES = {
    ADMIN: {
        path: '/',
    },
    CALENDAR: {
        path: '/calendar',
    },
    STAFF: {
        path: '/staff',
    },
    PATIENTS: {
        path: '/patients',
    },
    SERVICES: {
        path: '/services',
    },
    REPORTS: {
        path: '/reports',
    },
    MARKETING: {
        path: '/marketing',
    },
    STOCK: {
        path: '/stock',
    },
    CHATS: {
        path: '/chats',
    },
    ANNOUNCEMENTS: {
        path: '/announcements',
    },
};

export const SUPER_ADMIN_ROUTES = {
    ANNOUNCEMENTS: {
        path: 'announcements',
    },
    CLIENTS: {
        path: 'clients',
    },
    NEW_CLIENT: {
        path: 'clients/create-client',
    },
    TARIFFS: {
        path: 'tariffs',
    },
    SUPPORT: {
        path: 'supports',
    },
    SPECIALIZATION: {
        path: 'specialization',
    },
};
export const DOCTOR_ROUTES = {
    DOCTOR: {
        path: '/',
    },
    STAFF: {
        path: '/staff',
    },
    CALENDAR: {
        path: 'calendar',
    },
    PATIENTS: {
        path: '/patients',
    },
    SERVICES: {
        path: '/services',
    },
    ANNOUNCEMENTS: {
        path: '/announcements',
    },
};
