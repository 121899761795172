import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';
import { BsCamera } from 'react-icons/bs';

export const ImagePicker = ({ onDrop, file }) => {
    const { getRootProps, getInputProps } = useDropzone({ onDrop });
    return (
        <ImgCont {...getRootProps()} file={file}>
            <input {...getInputProps()} type="file" accept="image/*" />
            {file ? (
                <img src={file} alt="Your selected file" />
            ) : (
                <BsCamera fontSize="38px" color="grey" />
            )}
        </ImgCont>
    );
};

const ImgCont = styled.div`
    width: 85px;
    height: 85px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${({ file }) => (file ? '3px solid #49cc90' : '3px solid grey')};
    cursor: pointer;
    & > img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
`;
