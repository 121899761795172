import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const diagnosService = createApi({
    reducerPath: 'diagnosApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getDiagnosisList: builder.query({
            query: () => 'diagnosis',
        }),
    }),
});
export default diagnosService;

export const { useGetDiagnosisListQuery } = diagnosService;
