import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';

export const getAllDiscount = createAsyncThunk(
    'discount/getDeposit',
    async ({ patientID, currentPage }, { rejectWithValue }) => {
        try {
            const responce = await axiosConfigInstance().get(
                `discounts/patients/${patientID}?page=${currentPage}&size=10`
            );
            return responce.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

const initialState = {
    discount: [],
    isLoading: false,
};

const discountSlice = createSlice({
    name: 'discount',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllDiscount.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllDiscount.fulfilled, (state, action) => {
                state.isLoading = false;
                state.discount = action.payload;
            })
            .addCase(getAllDiscount.rejected, (state, action) => {
                state.isLoading = false;
            });
    },
});

export default discountSlice;
