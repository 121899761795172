import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from '../api/api.base-query';

const calendarService = createApi({
    reducerPath: 'calendarApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        // ---------------CALENDAR------------------------------------>
        postCalendarData: builder.mutation({
            query: ({ data }) => {
                return {
                    url: `/appointments`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['calendar'],
        }),
        postFastCalendarData: builder.mutation({
            query: ({ data, urlParams }) => {
                return {
                    url:
                        urlParams === undefined
                            ? `/appointments/fast`
                            : `/appointments/fast?${urlParams}`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['calendar'],
        }),

        getCalendarData: builder.query({
            query: ({ startDay, endDay, userIds }) => {
                const userIdsParams = userIds
                    .map((id) => `userIds=${id.value}`)
                    .join('&');
                return {
                    url: `/calendars?${userIdsParams}`,
                    params: { startDay, endDay },
                };
            },
            providesTags: ['calendar'],
        }),

        getCalendarPersonalData: builder.query({
            query: ({ day, page, size }) =>
                `/appointments/doctors?day=${day}&page=${page}&size=${size}`,
            providesTags: ['calendar'],
        }),

        getCalendarPersonalDataWithRole: builder.query({
            query: ({ startDay, endDay }) =>
                `calendars/doctor?startDay=${startDay}&endDay=${endDay}`,
            providesTags: ['calendar'],
        }),

        putCalendarData: builder.mutation({
            query: ({ appointmentId, body }) => {
                return {
                    url: `appointments/${appointmentId}`,
                    method: 'PUT',
                    body,
                };
            },
            invalidatesTags: ['calendar'],
        }),

        putCalendarDragAndDrop: builder.mutation({
            query: ({ appointmentIds, startTime, endTime }) => {
                return {
                    url: `calendars/${appointmentIds}?startTime=${startTime}&endTime=${endTime}`,
                    method: 'PUT',
                };
            },
            invalidatesTags: ['calendar'],
        }),

        // -----------------------PERSONAL---------------------------->

        getPersonal: builder.query({
            query: () => '/calendars/users',
        }),

        getPersonalAppointment: builder.query({
            query: ({ currentPage, id }) =>
                `appointments/staff/${id}?page=${currentPage}&size=5`,
            providesTags: ['calendar'],
        }),

        // --------------------PATIENT------------------------------>

        postPatient: builder.mutation({
            query: (body) => {
                return {
                    url: '/patients',
                    method: 'POST',
                    body,
                };
            },
            invalidatesTags: ['patient'],
        }),

        getPatientAppointment: builder.query({
            query: ({ id, currentPage }) =>
                `appointments/patient/${id}?page=${currentPage}&size=5`,
            providesTags: ['calendar'],
        }),

        getPatient: builder.query({
            query: () => '/calendars/patients',
            providesTags: ['patient'],
        }),

        // ----------------------SCHEDULE---------------------------->

        getSchedule: builder.query({
            query: ({ userId, dataOfAppointment }) =>
                `day-schedules/freeTime/${userId}?dataOfAppointment=${dataOfAppointment}`,
            providesTags: ['calendar'],
        }),
        putAppointment: builder.mutation({
            query: ({appointmentId, body}) => {
                return {
                    url: `appointments/${appointmentId}`,
                    method: 'PUT',
                    body: body
                }
            },
            invalidatesTags:['calendar']
        }),
        getWork: builder.query({
            query: (appointmentId) => `works/${appointmentId}`,
            providesTags: ['work'],
        }),
        postWork: builder.mutation({
            query: ({ appointmentId, data }) => {
                return {
                    url: `/works/${appointmentId}`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['work', 'calendar', 'patient', 'payment'],
        }),
        postQuickWork: builder.mutation({
            query: ({ appointmentId, data }) => {
                return {
                    url: `/works/quick/${appointmentId}`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['work'],
        }),
        getAppointmentPaymentwantsToPay: builder.query({
            query: ({ appointmentId }) =>
                `/payments/wantsToPay/${appointmentId}`,
            providesTags: ['payment'],
        }),
        getPaymentWantsToPayFast: builder.query({
            query: ({ appointmentId }) =>
                `/payments/wants-to-fast-pay/${appointmentId}`,
            providesTags: ['payment', 'work', 'calendar'],
        }),
    }),
});
export default calendarService;

export const {
    usePostCalendarDataMutation,
    useGetCalendarDataQuery,
    useGetPersonalQuery,
    useGetPatientQuery,
    useLazyGetPatientQuery,
    useGetScheduleQuery,
    usePostPatientMutation,
    usePutCalendarDataMutation,
    useLazyGetScheduleQuery,
    usePutCalendarDragAndDropMutation,
    useLazyGetCalendarPersonalDataQuery,
    useGetCalendarPersonalDataWithRoleQuery,
    usePostFastCalendarDataMutation,
    useGetPersonalAppointmentQuery,
    useGetPatientAppointmentQuery,
    useLazyGetPatientAppointmentQuery,
    usePutAppointmentMutation,
    usePostQuickWorkMutation,
    usePostWorkMutation,
    useGetWorkQuery,
    useGetPaymentWantsToPayFastQuery
} = calendarService;
