import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const getServices = createAsyncThunk(
    'services/getServices',
    async (searchValue, { rejectWithValue }) => {
        try {
            if (searchValue) {
                const response = await axiosConfigInstance().get(
                    `services/all?search=${searchValue}`
                );
                return response.data;
            } else {
                const response = await axiosConfigInstance().get(
                    'services/all'
                );
                return response.data;
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getServicesList = createAsyncThunk(
    'services/getServicesList',
    async (searchValue, { rejectWithValue }) => {
        try {
            if (searchValue) {
                const response = await axiosConfigInstance().get(
                    `services/?search=${searchValue}`
                );
                return response.data;
            } else {
                const response = await axiosConfigInstance().get('services');
                return response.data;
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const postServices = createAsyncThunk(
    'services/postServices',
    async ({ servicesData }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().post(
                'services',
                servicesData
            );
            showSuccessMessage(response.data.message);
            dispatch(getServices());
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectWithValue(error.message);
        }
    }
);

export const deleteServices = createAsyncThunk(
    'services/deleteServices',
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `services/${id}`
            );
            showSuccessMessage(response.data.message);
            dispatch(getServices());
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const getSingleService = createAsyncThunk(
    'services/getSingleService',
    async ({ id }, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(`services/${id}`);
            return response.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

export const editService = createAsyncThunk(
    'services/editService',
    async ({ id, editServiceValue }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                `services/${id}`,
                editServiceValue
            );
            showSuccessMessage(response.data.message);
            dispatch(getServices());
            dispatch(getSingleService({ id }));
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

const initialState = {
    servicesToothList:[],
    services: [],
    singleService: null,
    isLoading: false,
    message: '',
};

const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {},
    extraReducers: {
        [postServices.pending]: (state, { payload }) => {
            state.isLoading = true;
        },
        [postServices.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.message = 'Succes';
        },
        [postServices.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.message = 'Error!';
        },

        [getServices.pending]: (state) => {
            state.isLoading = true;
        },
        [getServices.fulfilled]: (state, { payload }) => {
            state.services = payload;
            state.isLoading = false;
        },
        [getServices.rejected]: () => {},
        [getSingleService.fulfilled]: (state, { payload }) => {
            state.singleService = payload;
        },

        [editService.pending]: (state) => {
            state.isLoading = true;
        },
        [editService.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.message = 'Secces';
        },
        [editService.rejected]: (state) => {
            state.isLoading = false;
            state.message = 'Error!';
        },
        //GetSerVice List Tooth
        [getServicesList.pending]: (state) => {
            state.isLoading = true;
        },
        [getServicesList.fulfilled]: (state, { payload }) => {
            state.servicesToothList = payload;
            state.isLoading = false;
        },
        [getServicesList.rejected]: (state) => {
            state.isLoading = false;
            state.message = 'Error!';
        },
    },
});

export default servicesSlice;
