import Calendar from '../../../assets/images/DentApp-Calendar.jpg';
import PatientCard from '../../../assets/images/patientCard.png';
import Shablon from '../../../assets/images/TeethAndShablon.png';
import TeethPatient from '../../../assets/images/TeethPatent.png';

export default function FeaturesKey() {
    const ArrayFeaturesKey = [
        {
            title: 'Удобный календарь по врачам, креслам, специальности',
            images: Calendar,
            subTitle:
                'Календарь позволяет легко записывать пациентов к докторам, обеспечивая четкость в рабочем процессе. ⁣Администраторы и стоматологи теперь могут эффективно планировать приемы, управлять расписанием и обеспечивать высокий стандарт обслуживания. ⁣Попробуйте упростить свою работу с нашим приложением!⁣',
        },
        {
            title: 'Информативная карточка пациента, с полной историей по пациенту',
            images: PatientCard,
            subTitle:
                'Оптимизируйте свою работу с нашим приложением, которое не только помогает собирать, но и умеет хранить детальную информацию о ваших клиентах. ⁣ Знание клиента – ключ к успешной работе: оценивайте ожидания, эффективно управляйте ситуацией.⁣С нашим инструментом вы сможете легко отслеживать все посещения клиента: дата, время, предоставленные услуги и итоговая стоимость. ⁣Повышайте эффективность и создавайте лучший опыт для своих пациентов!',
        },
        {
            title: 'Готовые шаблоны лечений и диагнозы по МКБ 10',
            images: Shablon,
            subTitle:
                'Экономьте время с готовыми шаблонами лечения от DentApp Знакомая ситуация: у пациентов часто встречаются похожие проблемы и требуется проводить однотипные процедуры. Например, лечение кариеса у взрослых. Чтобы не тратить кучу времени на повторный ввод одних и тех же данных о плане лечения - используйте наши шаблоны!',
        },
        {
            title: 'Графическая зубная формула',
            images: TeethPatient,
            subTitle:
                'Визуализируйте лечение с графической картой зубов от DentApp! Теперь можно легко отмечать зубы, которые требуют лечения, прямо на интерактивной модели челюсти пациента в приложении. Отображаются все зубы верхней и нижней челюсти с нумерацией Выделяете нужные зубы и отмечаете на них вид работы разным цветом Окрашивание и подписи помогают быстро визуализировать план лечения В итоге получаете наглядную схему работ для каждого пациента! Удобный инструмент для стоматолога и обзорная информация для клиента о предстоящих процедурах. Визуализация лечения в один клик с DentApp!',
        },
    ];

    return (
        <div className="w-full flex flex-col  gap-[40px] flex-wrap">
            <h1 className="text-[40px] sm:text-[25px] text-center">
                Основные возможности DentApp
            </h1>
            {ArrayFeaturesKey.map((item, i) => (
                <div
                    key={i}
                    className=" gap-[20px] rounded-[10px] bg-slate-200 px-4 shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] hover:shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px] cursor-pointer"
                >
                    <h1 className=" text-center p-4 font-bold text-[22px] sm:text-[13px]">
                        {item.title}
                    </h1>
                    <div className="flex flex-wrap justify-evenly pb-4 sm:gap-3">
                        <div className="w-[60%] sm:w-[100%] max-h-[500px]">
                            <img
                                src={item.images}
                                alt="images"
                                className="rounded-[15px] h-auto  shadow-xl"
                            />
                        </div>
                        <div className="content flex flex-col gap-3">
                            <p className="w-[300px] xs:w-[280px]">
                                {item.subTitle}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
