import React from 'react';
import styled from 'styled-components';
import Button from '../buttons';
import Modal from '../Modal/Modal';

const DeleteModal = ({
    showDeleteModal,
    setShowDeleteModal,
    deleteHandler,
    title,
}) => {
    return (
        <Modal active={showDeleteModal}>
            <h2 style={{ textAlign: 'center' }}>
                Вы действительно хотите удалить ?
            </h2>
            <h2
                style={{
                    fontWeight: 'bold',
                    textAlign: 'center',
                    maxWidth: '460px',
                    wordBreak: 'break-all',
                }}
            >
                {title}
            </h2>
            <WrapperButtons>
                <Button
                    borderRadius="5px"
                    color="#5460e6"
                    backgroundColor="#ffffff"
                    border="1px solid #5460e6"
                    onClick={() => setShowDeleteModal(false)}
                >
                    Отменить
                </Button>
                <Button
                    className="delete"
                    borderRadius="5px"
                    border="1px solid red"
                    backgroundColor="none"
                    color="red"
                    onClick={() => deleteHandler()}
                >
                    Удалить
                </Button>
            </WrapperButtons>
        </Modal>
    );
};

const WrapperButtons = styled.div`
    padding: 10px;
    display: flex;
    gap: 20px;
    .delete {
        :hover {
            opacity: 0.9;
        }
        :active {
            color: white;
            background-color: red;
        }
    }
`;

export default DeleteModal;
