import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const getMagazine = createAsyncThunk(
    'magazine/getMagazine',
    async ({ documentID }, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(
                `documents/${documentID}/journals`
            );
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectWithValue(error.message);
        }
    }
);

export const putMagazine = createAsyncThunk(
    'magazine/putMagazine',
    async ({ documentID, dataMagazine }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                `documents/${documentID}/journals`,
                dataMagazine
            );
            dispatch(getMagazine({ documentID }));
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectWithValue(error.message);
        }
    }
);

export const deleteMagazine = createAsyncThunk(
    'magazine/putMagazine',
    async ({ documentID, magazineID }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `documents/${documentID}/journals/${magazineID}`
            );
            dispatch(getMagazine({ documentID }));
            showSuccessMessage(response.data.message);
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    magazine: [],
    isLoading: false,
};

const magazineSlice = createSlice({
    name: 'magazine',
    initialState,
    reducers: {},
    extraReducers: {
        [getMagazine.pending]: (state) => {
            state.isLoading = true;
        },
        [getMagazine.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.magazine = payload;
        },
        [getMagazine.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default magazineSlice;
