import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Input from '../../../components/UI/inputs';
import Button from '../../../components/UI/buttons';
import { useDropzone } from 'react-dropzone';
import {
    getpostCardsInside,
    handleSwitch,
    putpostCards,
    putpostCardsSend,
} from '../../../store/slices/marketingSlice';
import { SmallSpinner } from '../../../components/UI/Spinners/SmallSpinner';
import { useNavigate } from 'react-router-dom';
import { LuImagePlus } from 'react-icons/lu';
import Modal from '../../../components/UI/Modal/Modal';
import { Switch } from '@mui/material';

function EditMarketing({ insideData, isLoading, valueSwitch, id }) {
    const [images, setImages] = useState(insideData?.imageResponse);
    const [fileImages, setFileImages] = useState(null);
    const [title, setTitle] = useState(insideData?.title);
    const [description, setDescription] = useState(insideData?.description);
    const [startDate, setStartDate] = useState(insideData?.startDate);
    const [endDate, setEndDate] = useState(insideData?.endData);
    const [active, setActive] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setImages(insideData?.imageResponse);
        setTitle(insideData?.title);
        setDescription(insideData?.description);
        setStartDate(insideData?.startDate);
        setEndDate(insideData?.endDate);
    }, [insideData]);

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                setImages((prevState) => [...prevState, reader.result]);
            };
            reader.readAsDataURL(file);
        });
        setFileImages(acceptedFiles[0]);
        setImages([]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/JPG': [],
        },
    });

    useEffect(() => {
        dispatch(getpostCardsInside({ id: id }));
    }, [dispatch, id]);

    function handleChnagePut() {
        dispatch(
            putpostCards({
                data: {
                    title: title,
                    description: description,
                    startDate: startDate,
                    endDate: endDate,
                    imageId: images.imageId,
                    imagedFile: fileImages,
                    postcardType: insideData.postcardType,
                },
                id: id,
            })
        );
    }

    function handlePut() {
        navigate('/marketing?type=АКЦИИ');
        dispatch(
            putpostCardsSend({
                id: id,
            })
        );
    }

    return (
        <StyledContainer>
            <StyledContainerSwitch>
                Редактировать
                <Switch
                    value={valueSwitch}
                    onChange={(e) => dispatch(handleSwitch(e.target.checked))}
                />
            </StyledContainerSwitch>
            <Modal active={active}>
                <div className="w-[375px] flex flex-col justify-around">
                    <p className="text-[17px] text-gray-800 bg-[#fff3cd] text-center rounded-md py-2">
                        Подтвердите отправку
                    </p>
                    <p className="text-[16px] text-gray-700 my-3 mb-1">
                        Тема: {title}
                    </p>
                    <span className="text-[14px] text-gray-600 my-3 mb-5">
                        Ваше сообщение будет отправлено на WhatsApp и на Mail{' '}
                        {insideData?.postcardType === 'HOLIDAY'
                            ? 'всех ващих пациентов'
                            : 'выбранных пациентов'}
                    </span>
                    <div className="flex gap-[60px]">
                        <Button
                            borderRadius="4px"
                            border="2px solid #5460e6"
                            backgroundColor="none"
                            color="#5460e6"
                            onClick={() => setActive(false)}
                        >
                            Отменить
                        </Button>
                        <Button borderRadius="4px" onClick={() => handlePut()}>
                            Подтвердить
                        </Button>
                    </div>
                </div>
            </Modal>
            {isLoading ? (
                <SmallSpinner />
            ) : (
                <>
                    {insideData?.postcardType !== 'HOLIDAY' && (
                        <StyledWrapperTime>
                            <div>
                                <Input
                                    type="date"
                                    label="Начало"
                                    value={startDate}
                                    disabled={!valueSwitch}
                                    notDisabled
                                    onChange={(e) =>
                                        setStartDate(e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <Input
                                    type="date"
                                    label="Конец"
                                    value={endDate}
                                    notDisabled
                                    disabled={!valueSwitch}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        </StyledWrapperTime>
                    )}
                    <StyledContainerTitle>
                        <div>
                            <Input
                                label="Тема"
                                value={title}
                                disabled={!valueSwitch}
                                notDisabled
                                onChange={(e) => setTitle(e.target.value)}
                            />
                            <div className="relative">
                                <p className="absolute text-gray-400 text-[14px] ">
                                    Описание
                                </p>
                                <Input
                                    type="textarea"
                                    spellCheck={false}
                                    placeholder="Напиишите что нибудь..."
                                    height="180px"
                                    value={description}
                                    disabled={!valueSwitch}
                                    notDisabled
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="w-[50%] ">
                            <StyledImageWrapper
                                {...getRootProps()}
                                disabled={valueSwitch}
                                className={`${
                                    insideData?.imageResponse?.link ||
                                    fileImages
                                        ? 'border-[1px]'
                                        : 'border-[2px] border-dashed border-blue-600'
                                } `}
                            >
                                <input {...getInputProps()} />
                                <StyledImagePickerImage
                                    src={
                                        images?.link === undefined
                                            ? images
                                            : images?.link
                                    }
                                />
                                {insideData?.imageResponse?.link ||
                                fileImages ? (
                                    <></>
                                ) : (
                                    <div className="absolute top-[35%] left-[30%] flex items-center">
                                        <LuImagePlus
                                            color="gray"
                                            className="w-10 h-10"
                                        />
                                        <p className="text-gray-500 ml-4 font-medium ">
                                            Выберите картинку
                                        </p>
                                    </div>
                                )}
                            </StyledImageWrapper>
                        </div>
                    </StyledContainerTitle>
                    <StyledContainerButtom>
                        <Button onClick={() => setActive(true)}>
                            Отправить
                        </Button>
                        <Button
                            disabled={!valueSwitch}
                            onClick={() => handleChnagePut()}
                        >
                            Сохранить
                        </Button>
                    </StyledContainerButtom>
                </>
            )}
        </StyledContainer>
    );
}

const StyledContainer = styled.div`
    width: 100%;
    margin-bottom: 40px;
`;

const StyledWrapperTime = styled.div`
    width: 100%;
    display: flex;
    margin-top: 10px;
    gap: 20px;
    div {
        :nth-child(1) {
            width: 50%;
            div {
                width: 100%;
                input {
                    width: 100%;
                }
            }
        }
        :nth-child(2) {
            width: 50%;
            div {
                width: 100%;
                input {
                    width: 100%;
                }
            }
        }
    }
`;
const StyledContainerSwitch = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;
const StyledContainerButtom = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
    button {
        width: 10%;
        height: 40px;
    }
`;

const StyledImageWrapper = styled.div`
    width: 50%;
    height: 277px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: scale-down;
    cursor: pointer;
    position: relative;
    pointer-events: ${({ disabled }) => (disabled ? 'all' : 'none')};
`;
const StyledImagePickerImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: scale-down;
`;
const StyledContainerTitle = styled.div`
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 10px;
    @media screen and (max-width: 670px) {
        flex-direction: column;
    }
    div {
        :nth-child(1) {
            width: 50%;
            @media screen and (max-width: 670px) {
                width: 100%;
            }
            div {
                width: 100%;
                input {
                    width: 100%;
                }
            }
        }
        :nth-child(2) {
            @media screen and (max-width: 670px) {
                width: 100%;
            }
            div {
                width: 100%;
                margin-top: 25px;
                input {
                    width: 100%;
                }
            }
        }
    }
`;

export default EditMarketing;
