import React from 'react';
import {
    MdOutlineOpenInBrowser,
} from 'react-icons/md';
import { MeatBalls } from '../../../components/UI/MeatBalls/MeatBalls';

const Meat = ({
    innerClick,
    id,
}) => {
    const navigate = [
        {
            id: '1',
            icon: <MdOutlineOpenInBrowser color="#5460e6" />,
            name: 'Открыть',
            click: () => innerClick(id),
        },
      
    ];
    return <MeatBalls options={navigate} />;
};

export default Meat;
