import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import { getPatients } from './patientsSlice';

export const postExcel = createAsyncThunk(
    'excel/downloadExcel',
    async (requestData, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().post(
                'patients/export',
                requestData,
                {
                    responseType: 'blob',
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'DENTAPP-DATA-PATIENTS.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            return null;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const postImportExcel = createAsyncThunk(
    'exel/postImportExcel',
    async ({ file, currentPage }, { rejectWithValue, dispatch }) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const response = await axiosConfigInstance().post(
                'patients/import',
                formData
            );
            dispatch(getPatients({ currentPage: currentPage }));

            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    exel: [],
    exelFileData: '',
    isLoading: false,
};

const exelSlice = createSlice({
    name: 'exel',
    initialState,
    reducers: {},
    extraReducers: {
        // post exel export
        [postExcel.pending]: (state) => {
            state.isLoading = true;
        },
        [postExcel.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.exelFileData = payload;
        },
        [postExcel.rejected]: (state) => {
            state.isLoading = false;
        },
        // post exel import
        [postImportExcel.pending]: (state) => {
            state.isLoading = true;
        },
        [postImportExcel.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.exel = action.payload;
        },
        [postImportExcel.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export default exelSlice;
