import React, { Suspense } from 'react';
import { SUPER_ADMIN_ROUTES } from '../utils/constans/routes';
import { Spinner } from '../components/UI/Spinners/Spinner';
import { Routes, Route, Navigate } from 'react-router-dom';
import { SuperDashboard } from '../layout/super/SuperDashboard';
import SupportInside from '../pages/super-admin/support/inner/SupportInside';
import NewTariffs from '../pages/super-admin/tariffs/NewTariffs';
import ChangeAnnouncement from '../pages/super-admin/Announcement/inner/ChangeAnnouncement';
import InnerAnnouncements from '../pages/super-admin/Announcement/inner/InnerAnnouncements';
import EditTariffs from '../pages/super-admin/tariffs/EditTariffs';

const Announcements = React.lazy(() =>
    import('../pages/super-admin/Announcement/index')
);
const AnnouncementCreate = React.lazy(() =>
    import('../pages/super-admin/Announcement/NewAnnouncement')
);
const Clients = React.lazy(() => import('../pages/super-admin/clients'));
const ClinicInfo = React.lazy(() =>
    import('../pages/super-admin/clients/inner/ClinicInfo')
);
const CreateClient = React.lazy(() =>
    import('../pages/super-admin/clients/inner/CreateClinic')
);
const Tariffs = React.lazy(() => import('../pages/super-admin/tariffs'));
const Support = React.lazy(() => import('../pages/super-admin/support'));
const SpecilizationPage = React.lazy(() =>
    import('../pages/super-admin/specilization')
);

export const SuperAdmin = () => {
    const {
        ANNOUNCEMENTS,
        CLIENTS,
        TARIFFS,
        SUPPORT,
        NEW_CLIENT,
        SPECIALIZATION,
    } = SUPER_ADMIN_ROUTES;
    return (
        <Suspense fallback={<Spinner />}>
            <Routes>
                <Route path="/" element={<SuperDashboard />}>
                    <Route path="/" element={<Navigate to={CLIENTS.path} />} />
                    <Route path={CLIENTS.path} element={<Clients />} />
                    <Route
                        path={`${CLIENTS.path}/clinic/:id`}
                        element={<ClinicInfo />}
                    />
                    <Route path={NEW_CLIENT.path} element={<CreateClient />} />

                    <Route
                        path={ANNOUNCEMENTS.path}
                        element={<Announcements />}
                    />
                    <Route
                        path={`${ANNOUNCEMENTS.path}/create`}
                        element={<AnnouncementCreate />}
                    />
                    <Route
                        path={`${ANNOUNCEMENTS.path}/announcements-edit/:id`}
                        element={<ChangeAnnouncement />}
                    />
                    <Route
                        path={`${ANNOUNCEMENTS.path}/announcements-inner/:id`}
                        element={<InnerAnnouncements />}
                    />
                    <Route
                        path={SPECIALIZATION.path}
                        element={<SpecilizationPage />}
                    />
                    <Route path={SUPPORT.path} element={<Support />} />
                    <Route
                        path={`${SUPPORT.path}/support-info/:id`}
                        element={<SupportInside />}
                    />
                    <Route path={TARIFFS.path} element={<Tariffs />} />
                    <Route
                        path={`${TARIFFS.path}/create`}
                        element={<NewTariffs />}
                    />
                    <Route
                        path={`${TARIFFS.path}/tariffs-edit/:id`}
                        element={<EditTariffs />}
                    />
                </Route>
            </Routes>
        </Suspense>
    );
};
