import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosConfigInstance } from '../../api/axios-config';
import {
    showErrorMessage,
    showSuccessMessage,
} from '../../components/notification/Notification';

export const getPatients = createAsyncThunk(
    'patients/getPatients',
    async ({ currentPage, search, size = 10 }, { rejectWithValue }) => {
        try {
            if (search) {
                const response = await axiosConfigInstance().get(
                    `/patients?searchPatient=${search}`
                );
                return response.data;
            } else {
                const response = await axiosConfigInstance().get(
                    `/patients?page=${currentPage}&size=${size}`
                );
                return response.data;
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getPatientsCreate = createAsyncThunk(
    'patients/getPatientsCreate',
    async ({ currentPage, search, size = 10 }, { rejectWithValue }) => {
        try {
            if (search) {
                const response = await axiosConfigInstance().get(
                    `/patients?searchPatient=${search}`
                );
                return response.data;
            } else {
                const response = await axiosConfigInstance().get(
                    `/patients?page=${currentPage}&size=${size}`
                );
                return response.data;
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getSinglePatients = createAsyncThunk(
    'patients/getSinglePatients',
    async (id, { rejectWithValue }) => {
        try {
            const responce = await axiosConfigInstance().get(`patients/${id}`);
            return responce.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

export const postPatients = createAsyncThunk(
    '/patients',
    async (
        { patientsData, currentPage, hideModalHandler },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const response = await axiosConfigInstance().post(
                `/patients`,
                patientsData
            );

            showSuccessMessage(response.data.message);
            dispatch(getPatients({ currentPage }));
            hideModalHandler();
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectWithValue(error.message);
        }
    }
);
export const deletePatients = createAsyncThunk(
    'patients/deletePatients',
    async ({ id, currentPage }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `patients/${id}`
            );
            showSuccessMessage(response.data.message);
            dispatch(getPatients({ currentPage }));
            return response;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
            dispatch(getPatients({ currentPage }));
        }
    }
);

export const editPatientsId = createAsyncThunk(
    'patients/editPatientsId',
    async ({ id, patientEdit, currentPage }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                `patients/${id}`,
                patientEdit
            );
            showSuccessMessage(response.data.message);
            id && dispatch(getSinglePatients(id));
            currentPage && dispatch(getPatients({ currentPage }));
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const editPatientsIdAttention = createAsyncThunk(
    'patients/editPatientsIdAttention',
    async ({ pattientId, patientEdit }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().put(
                `patients/${pattientId}/attention?attention=${patientEdit.attention}&peculiarities=${patientEdit.peculiarities}`,
                patientEdit
            );
            showSuccessMessage(response.data.message);
            dispatch(getSinglePatients(pattientId));
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const getSinglePatientsAllAppointments = createAsyncThunk(
    'patients/getSinglePatientsAllAppointments',
    async ({ id, currentPage, pageSize = 5 }, { rejectWithValue }) => {
        try {
            const responce = await axiosConfigInstance().get(
                `appointments/patient/${id}?page=${currentPage}&size=${pageSize}`
            );
            return responce.data;
        } catch (error) {
            rejectWithValue(error.message);
        }
    }
);

export const getSinglePatientAppointment = createAsyncThunk(
    'patient/getSinglePatientAppointment',
    async ({ appointmentId }, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(
                `/appointments/${appointmentId}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const putOnlyAppoitmentStatus = createAsyncThunk(
    'patient/putOnlyAppoitmentStatus',
    async (
        { appointmentId, appointmentData, id },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const response = await axiosConfigInstance().put(
                `appointments/${appointmentId}/comments`,
                appointmentData
            );
            showSuccessMessage(response.data.message);
            dispatch(
                getSinglePatientAppointment({ appointmentId: appointmentId })
            );
            dispatch(getSinglePatientsAllAppointments(id));
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectWithValue(error.message);
        }
    }
);

export const deleteSinglePatientsAllAppointments = createAsyncThunk(
    'patients/deleteSinglePatientsAllAppointments',
    async ({ id, patientID }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `/appointments/${id}`
            );
            showSuccessMessage(response.data.message);
            dispatch(getSinglePatientsAllAppointments(patientID));
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

// FILE SNAPSHOT
export const getAppointmentImageData = createAsyncThunk(
    'patients/getAppointmentImageData',
    async (patientID, { rejectWithValue }) => {
        try {
            const response = await axiosConfigInstance().get(
                `/images/${patientID}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const postSnapshotFile = createAsyncThunk(
    'patients/postSnapshotFile',
    async (
        { file, patientID, appointmentId, description, teeth },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const formData = new FormData();
            formData.append('file', file);

            const res = await axiosConfigInstance().post(
                '/files/SNAPSHOTS',
                formData
            );

            const data = await res.data.imageId;

            const response = await axiosConfigInstance().post(
                `/images/snapshots/${patientID}/${data}?appointmentId=${appointmentId}&description=${description}&teeth=${teeth}`
            );
            showSuccessMessage(response.data.message);

            dispatch(getAppointmentImageData(patientID));

            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

export const deleteSnapshotFile = createAsyncThunk(
    'patients/deleteSnapshotFile',
    async ({ imageID, patientID }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosConfigInstance().delete(
                `images/${imageID}`
            );
            showSuccessMessage(response.data.message);
            dispatch(getAppointmentImageData(patientID));
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            rejectWithValue(error.message);
        }
    }
);

// EXCEL
export const sendPatientsAsExcel = createAsyncThunk(
    'patients/sendPatientsAsExcel',
    async ({ file }, { rejectWithValue }) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const response = await axiosConfigInstance().post(
                `patients/import`,
                file
            );
            return response.data;
        } catch (error) {
            showErrorMessage(error.response.data.message);
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    patients: [],
    createPatients: [],
    singlePatient: [],
    allAppointmentsPatient: [],
    appointmentPayment: [],
    totalPages: 1,
    singlePatientAppointment: null,
    totalPatients: null,
    snapshotsData: null,
    imageId: null,
    message: '',
    isLoading: false,
};

const patientsSlice = createSlice({
    name: 'patients',
    initialState,
    reducers: {},
    extraReducers: {
        //post
        [postPatients.pending]: (state) => {
            state.isLoading = true;
        },
        [postPatients.fulfilled]: (state) => {
            state.isLoading = false;
            state.message = 'Пациент успешно добавлен';
        },
        [postPatients.rejected]: (state) => {
            state.isLoading = false;
        },

        //delete

        [deletePatients.pending]: (state) => {
            state.isLoading = true;
        },
        [deletePatients.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.message = payload.data.message;
        },
        [deletePatients.rejected]: (state) => {
            state.isLoading = false;
        },

        //editPatientsId

        [editPatientsId.pending]: (state) => {
            state.isLoading = true;
        },
        [editPatientsId.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.message = action.payload.message;
        },
        [editPatientsId.rejected]: (state) => {
            state.isLoading = false;
        },

        // get single patinet appointment

        [getSinglePatientAppointment.pending]: (state) => {
            state.isLoading = true;
        },
        [getSinglePatientAppointment.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.singlePatientAppointment = action.payload;
        },
        [getSinglePatientAppointment.rejected]: (state) => {
            state.isLoading = false;
        },

        //deleteSinglePatientsAllAppointments

        [deleteSinglePatientsAllAppointments.pending]: (state) => {
            state.isLoading = true;
            state.message = '';
        },
        [deleteSinglePatientsAllAppointments.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.message = action.payload.message;
        },
        [deleteSinglePatientsAllAppointments.rejected]: (state) => {
            state.isLoading = false;
        },

        //postSnapshotFile

        [postSnapshotFile.pending]: (state) => {
            state.isLoading = true;
        },
        [postSnapshotFile.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [postSnapshotFile.rejected]: (state) => {
            state.isLoading = false;
        },

        //deleteSnapshotFile

        [deleteSnapshotFile.pending]: (state) => {
            state.isLoading = true;
        },
        [deleteSnapshotFile.fulfilled]: (state) => {
            state.isLoading = false;
        },
        [deleteSnapshotFile.rejected]: (state) => {
            state.isLoading = false;
        },

        // getPatients

        [getPatients.pending]: (state) => {
            state.isLoading = true;
        },
        [getPatients.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.patients = payload.content;
            state.totalPages = payload.totalPages;
            state.totalPatients = payload;
        },
        [getPatients.rejected]: (state) => {
            state.isLoading = false;
        },

        //getPatientsCreate
        [getPatientsCreate.pending]: (state) => {
            state.isLoading = true;
        },
        [getPatientsCreate.fulfilled]: (state, { payload }) => {
            state.createPatients = payload.content;
        },
        [getPatientsCreate.rejected]: (state) => {
            state.isLoading = false;
        },
        // getSinglePatients
        [getSinglePatients.pending]: (state) => {
            state.isLoading = true;
        },
        [getSinglePatients.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.singlePatient = payload;
        },
        [getSinglePatients.rejected]: (state) => {
            state.isLoading = false;
        },
        //getSinglePatientsAllAppointments

        [getSinglePatientsAllAppointments.pending]: (state) => {
            state.isLoading = true;
        },
        [getSinglePatientsAllAppointments.fulfilled]: (state, { payload }) => {
            state.allAppointmentsPatient = payload;
            state.isLoading = false;
        },
        [getSinglePatientsAllAppointments.rejected]: (state) => {
            state.isLoading = false;
        },
        // ---
        [getAppointmentImageData.pending]: (state) => {
            state.isLoading = true;
        },
        [getAppointmentImageData.fulfilled]: (state, { payload }) => {
            state.snapshotsData = payload;
            state.isLoading = false;
        },
        [getAppointmentImageData.rejected]: (state) => {
            state.isLoading = false;
        },
    },
});

export const { getRouterId } = patientsSlice.actions;

export default patientsSlice;
