import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/material/styles';
import { ReactComponent as Information } from '../../../assets/icons/Information.svg';
import notFountPhoto from '../../../assets/images/no-photo.png';

import {
    TableContainer as MuiTableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    ThemeProvider,
    createTheme,
} from '@mui/material';
import {
    servicesTransform,
    statusTransform,
    toothTransform,
    transformDate,
    transformRead,
    transformTypeMark,
    translateDepositType,
    translatePayment,
    translateServiceType,
    translateStatus,
    translateType,
} from '../../../utils/helpers/helpers';

const customTheme = createTheme({
    palette: {
        main: 'rgba(26, 35, 126, 0.07);',
        hover: 'rgba(27, 35, 119, 0.199)',
    },
});

const StyledTableRow = muiStyled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.main,
    },
    '&:hover': {
        background: theme.palette.hover,
    },
}));

export const UITable = (props) => {
    const {
        data,
        columns,
        navigationInner,
        onClickHandler,
        sorting,
        minheight,
        position,
        positionHeader,
        currentPage,
    } = props;

    const sortedData = Object.assign([], data);

    const [allData, setAllData] = useState(data);
    const [searchById, setSearchById] = useState('asd');
    const [searchByName, setSearchByName] = useState('asd');

    const navigate = useNavigate();

    useEffect(() => {
        setAllData(data);
    }, [data]);

    const payroll = (item) => {
        return item?.salary !== 0 ? (
            <h2>
                {item?.salary}{' '}
                {item?.percentOrFixed === 'PERCENT' ? '(%)' : '(Фикс.)'}
            </h2>
        ) : (
            '0'
        );
    };

    const accessKeyMappings = {
        appointment: (item) => transformDate(item.appointment),
        BreakSchedule: (item) =>
            `${item.startTime.slice(0, 5)} - ${item.endTime.slice(0, 5)}`,
        attention: () => <Information />,
        appointmentStatus: (item) => translateStatus(item.appointmentStatus),
        recordType: (item) => translateType(item.recordType),
        appointmentCreateAt: (item) => transformDate(item.appointmentCreateAt),
        imageCreateAt: (item) => transformDate(item.imageCreateAt),
        appointmentDateTime: (item) => transformDate(item.appointmentDateTime),
        dateTimeAppointment: (item) => transformDate(item.dateTimeAppointment),
        depositTranslateType: (item) => translateDepositType(item.depositType),
        dateOfAppointmentAndTime: (item) =>
            transformDate(item.dateOfAppointmentAndTime),
        dateOfPayment: (item) => transformDate(item.dateOfPayment),
        typeOfPayment: (item) => translatePayment(item.typeOfPayment),
        appointmentServiceToPatientResponses: (item) =>
            servicesTransform(item.appointmentServiceToPatientResponses),
        tooth: (item) => toothTransform(item.tooth),
        postcardType: (item) => transformTypeMark(item.postcardType),
        birthDate: (item) => item?.birthDate || '2023-01-01',
        imageLink: (item, onClick) => (
            <StyledIMG
                src={item.imageLink}
                alt="X_REY"
                onClick={() => onClick(item.imageLink)}
            />
        ),
        imageLinkMarketing: (item) => (
            <StyledIMG
                src={
                    item.imageResponse === null
                        ? notFountPhoto
                        : item?.imageResponse?.link
                }
                alt="X_REY"
            />
        ),
        statusPay: (item) => (
            <StyledActionsStatus bgColor={item.paymentStatus}>
                <div>{item.paymentStatus}</div>
            </StyledActionsStatus>
        ),
        payrollCalculationsResponse: (item) => payroll(item),
        // client
        clientName: (item) => (
            <DoableData>
                <p>{item.fullName}</p>
                <span>{item.email}</span>
            </DoableData>
        ),
        clientTariff: (item) => (
            <DoableData>
                <p>{item.tariff}</p>
                <span>{item.tariffPrice}</span>
            </DoableData>
        ),
        clientPaymentDate: (item) => (
            <DoableData>
                <p>{item.payment}</p>
                <span>{item.dateOfPayment}</span>
            </DoableData>
        ),
        clientClinickAdress: (item) => (
            <DoableData>
                <p>{item.clinicName}</p>
                <span>{item.address}</span>
            </DoableData>
        ),
        status: (item) => (
            <StatusStyled status={item.status}>
                {statusTransform(item.status)}
            </StatusStyled>
        ),
        period: (item) => (
            <div>
                От: {item.dateFrom} <br />
                До: {item.dateTo}
            </div>
        ),
        clinicName: (item) => (
            <DoableData>
                <p>{item.clinicName}</p>
                <span>{item.admin}</span>
            </DoableData>
        ),
        messageTime: (item) => (
            <span>
                {item.messageTime.slice(0, 10)} {item.messageTime.slice(11, 16)}
            </span>
        ),
        read: (item) => (
            <StyledActionsStatus bgColor={item.read}>
                {transformRead(item.read)}
            </StyledActionsStatus>
        ),
        message: (item) => (
            <StyledText>
                <span>{item.message}</span>
            </StyledText>
        ),
        translateServiceType: (title) => {
            return translateServiceType(title.serviceType);
        },
        // PATTERN
        diagnosisResponses: (item) => {
            return servicesTransform(item?.diagnosisResponses);
        },
        serviceInfoResponses: (item) => {
            return servicesTransform(item?.serviceInfoResponses);
        },
    };

    const renderCellValue = (col, item, onClickHandler, index) => {
        const accessKey = col.accessKey;
        const handler = accessKeyMappings[accessKey];
        if (handler) {
            return handler(item, onClickHandler);
        } else if (accessKey === 'fio') {
            return `${item.firstName} ${item.lastName}`;
        } else if (accessKey === 'quantityAppointment') {
            return item.sum === 0 ? 0 : item.quantityAppointment;
        } else if (accessKey === 'INDEXED') {
            return index;
        } else {
            return item[accessKey];
        }
    };

    const navigationInnerHandler = ({ id, appointmentId, clinicId }) => {
        if (navigationInner) {
            navigate(
                `${navigationInner}/${id ? id : appointmentId || clinicId}`
            );
        }
    };

    const sortByIdHandler = () => {
        const sortedIdName = sortedData.sort((a, b) => {
            return searchById === 'asd'
                ? a.id > b.id
                    ? 1
                    : -1
                : a.id < b.id
                ? 1
                : -1;
        });
        setAllData(sortedIdName);
        setSearchById(searchById === 'asd' ? 'dsc' : 'asd');
    };
    const sortByNameHandler = () => {
        const sortedByName = sortedData.sort((a, b) => {
            return searchByName === 'asd'
                ? a.firstName > b.firstName
                    ? 1
                    : -1
                : a.firstName < b.firstName
                ? 1
                : -1;
        });
        setAllData(sortedByName);
        setSearchByName(searchByName === 'asd' ? 'dsc' : 'asd');
    };

    const sortingHandler = (id) => {
        if (sorting) {
            if (id === 1) {
                sortByIdHandler();
            }
            if (id === 2) {
                sortByNameHandler();
            }
        }
    };

    const keyItem = (item) => {
        return item.imageId
            ? item.imageId
            : item.paymentId
            ? item.paymentId
            : item.appointmentId
            ? item.appointmentId
            : item.userId !== undefined
            ? item.userId
            : item.financeId || item.id;
    };

    return (
        <ThemeProvider theme={customTheme}>
            <Container component={Paper} minheight={minheight}>
                <Table>
                    <TableHead>
                        <TableRowContainer>
                            {columns?.map((col) => {
                                return (
                                    <TableContainer key={col.id}>
                                        <StyledItemLabel
                                            positionHeader={positionHeader}
                                            onClick={() =>
                                                sortingHandler(col.id)
                                            }
                                        >
                                            {col.title}
                                        </StyledItemLabel>
                                    </TableContainer>
                                );
                            })}
                        </TableRowContainer>
                    </TableHead>
                    <TableBody>
                        {allData?.map((item, index) => (
                            <StyledTableRow key={keyItem(item)}>
                                {columns?.map((col) => {
                                    if (item.userId !== 0) {
                                        if (col.action) {
                                            return col.action(item);
                                        }
                                    }
                                    return (
                                        <StyledTable
                                            key={col.id}
                                            onClick={() =>
                                                navigationInnerHandler({
                                                    id: item.id,
                                                    appointmentId:
                                                        item.appointmentId,
                                                    clinicId: item.clinicId,
                                                })
                                            }
                                        >
                                            <StyledItemLabel
                                                position={position}
                                                accesskeystyle={col.accessKey}
                                                style={
                                                    col.debt
                                                        ? item.debt === 0
                                                            ? {
                                                                  color: '#49cc90',
                                                                  fontWeight:
                                                                      '600',
                                                              }
                                                            : {
                                                                  color: 'red',
                                                                  fontWeight:
                                                                      '600',
                                                              }
                                                        : {
                                                              color: 'black',
                                                          }
                                                }
                                            >
                                                {renderCellValue(
                                                    col,
                                                    item,
                                                    onClickHandler,
                                                    currentPage * 10 -
                                                        10 +
                                                        index +
                                                        1
                                                )}
                                            </StyledItemLabel>
                                        </StyledTable>
                                    );
                                })}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </Container>
        </ThemeProvider>
    );
};

const Container = styled(MuiTableContainer)`
    position: relative;
    overflow-x: scroll;
    min-height: ${({ minheight }) => minheight || '595px'};
    margin: 20px auto;
    background: #ffffff;
    border: 1px solid #d4d4d4;
    box-sizing: border-box;
    border-radius: 10px;
    span {
        cursor: pointer;
    }
`;

const TableRowContainer = styled(TableRow)`
    border-bottom: 1.5px solid #f7f8fa;
`;

const StyledTable = styled(TableCell)`
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #1d293f;
    letter-spacing: 0.02em;
    @media only screen and (max-width: 1100px) {
        padding: 16px 0px;
        :first-of-type {
            padding: 0 10px 0 10px;
        }
    }
`;

const StyledItemLabel = styled.span`
    display: flex;
    justify-content: ${({ position, positionHeader }) =>
        position ? position : positionHeader ? positionHeader : 'center'};

    ${({ accesskeystyle }) =>
        accesskeystyle === 'appointmentServiceToPatientResponses'
            ? 'align-items: flex-start; flex-direction: column; width: 160px !important;'
            : `align-items: center; flex-direction: initial;`}
    font-size: ${({ headerFontSize }) => headerFontSize || '14px'};
`;
const StatusStyled = styled.div`
    color: ${({ status }) => (status === 'ACTIVE' ? '#49cc90' : '#f93e3e')};
`;

const DoableData = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    span {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        color: #9e9e9e;
    }
`;
const TableContainer = styled(TableCell)`
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #1d293f;
    border: none;
`;
export const StyledIMG = styled.img`
    width: 100px;
    height: 100px;
    object-fit: contain;
`;
const StyledActionsStatus = styled.div`
    display: flex;
    border: none;
    max-width: 150px;
    align-items: center;
    justify-content: space-around;
    padding: 6px;
    border-radius: 5px;
    color: white;
    background-color: ${({ bgColor }) =>
        bgColor === 'Оплачено' || bgColor === true
            ? '#2ba802'
            : bgColor === 'Частично' || bgColor === false
            ? '#72bcb2'
            : 'red'};
`;
const StyledText = styled.div`
    white-space: nowrap;
    width: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
`;