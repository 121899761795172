import React from 'react';
import styled from 'styled-components';

export const Spinner = () => {
    return (
        <LoaderContainer>
            <StyledSpinner />
        </LoaderContainer>
    );
};
const LoaderContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const StyledSpinner = styled.div`
    display: inline-block;
    width: 80px;
    height: 80px;
    ::after {
        content: ' ';
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid #3a10e5;
        border-color: #3a10e5 transparent #3a10e5 transparent;
        animation: lds-dual-ring 1s linear infinite;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;
