import React from 'react';
import IconLogo from '../../../assets/icons/dentAppLogo.svg';
import { BsAndroid2 } from 'react-icons/bs';
import { IoLogoApple } from "react-icons/io5";
import { FaGooglePlay } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function FooterPublish() {
    return (
        <footer className="w-full bg-white border-t-gray-200 border-t-2">
            <div className="container px-6 py-8 mx-auto max-w-[1440px]">
                <div className="flex flex-col items-center text-center">
                    <img className="w-20 h-20 " src={IconLogo} alt="" />
                    <p className="max-w-md mx-auto mt-4 text-gray-500 sm:text-[12px]">
                        Dent-App Приложение для автоматизации современной
                        стоматологии
                    </p>
                    <p className='max-w-md mx-auto mt-4 text-gray-500 sm:text-[12px]'>
                        Скачайте приложение в
                    </p>
                    <div className="flex gap-4 mt-4 sm:gap-1 sm:items-center sm:justify-center">
                    <a
                            target="_blank"
                            href="https://drive.google.com/file/d/1FtWvaZf1tXPy8yUxO91OdP8Il_98jsBT/view?usp=share_link"
                            download
                            className="w-fit cursor-pointer sm:text-[12px] flex items-center justify-center gap-2 sm:px-2 sm:py-1 px-5 py-2 text-sm tracking-wide text-blue-600 border border-blue-600 transition-colors duration-300 transform rounded-md sm:mx-2 sm:order-2 sm:w-auto hover:bg-blue-600 hover:text-white focus:outline-none"
                        >
                            <FaGooglePlay size={24} /> Play Market
                        </a>
                        <a
                            target="_blank"
                            href="https://apps.apple.com/kg/app/dentapp-mobile/id6560111656"
                            download
                            className="w-fit cursor-pointer sm:text-[12px] flex items-center justify-center gap-2 sm:px-2 sm:py-1 px-5 py-2 text-sm tracking-wide text-blue-600 border border-blue-600 transition-colors duration-300 transform rounded-md sm:mx-2 sm:order-2 sm:w-auto hover:bg-blue-600 hover:text-white focus:outline-none"
                        >
                            <IoLogoApple size={24} /> App Store
                        </a>
                    </div>
                </div>
                <hr className="my-6 border-gray-200 sm:my-5" />
                <div className="flex flex-col items-center">
                    <Link
                    to='/prices/privacy'
                    className='text-sm text-gray-500 sm:text-[12px] mb-3 sm:mb-0'>
                        Политика кофиденциальности
                    </Link>
                    <p className="text-sm text-gray-500 sm:text-[12px] ">
                        © 2023. Все права защищены DentApp.
                    </p>
                    <div className="flex mt-3 -mx-2 sm:mt-0 sm:flex-col sm:gap-0 items-center">
                        <a
                            href="https://aksoft.dev/"
                            className="mx-2 text-sm text-gray-500 transition-colors duration-300 hover:text-gray-500 sm:text-[12px]"
                            target="_blank"
                        >
                            О компании{' '}
                        </a>
                        <a
                            href="https://wa.me/+996999886644"
                            className="mx-2 text-sm text-gray-500 transition-colors duration-300 hover:text-gray-500 sm:text-[12px]"
                            aria-label="Reddit"
                        >
                            Контакты{' '}
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}
