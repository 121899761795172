import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Input from '../../../components/UI/inputs';
import UICheckbox from '../../../components/UI/checkbox/UICheckbox';
import { Breadcrumbs, Link } from '@mui/material';
import Button from '../../../components/UI/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { putTariffs, getEditTariffs } from '../../../store/slices/tariffsSlice';
import { useNavigate, useParams } from 'react-router-dom';

const EditTariffs = () => {
    const [validation, setValidation] = useState(false);
    const [tariffs, setTariffs] = useState({
        name: '',
        description: '',
        price: '',
        quantityDoctor: '',
        warehouse: false,
        postcardSMS: false,
        tariffType: '',
    });

    const { putEditTariffs } = useSelector((state) => state.tariffs);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getEditTariffs({ ID: id }));
    }, [dispatch, id]);

    useEffect(() => {
        setValidation(
            tariffs.name === ''
                ? false
                : tariffs.description === ''
                ? false
                : tariffs.price === ''
                ? false
                : tariffs.quantityDoctor === ''
                ? false
                : true
        );
    }, [tariffs, dispatch, id]);

    const putSubmitHandle = () => {
        navigate(-1);
        dispatch(
            putTariffs({
                ID: id,
                Data: {
                    name: tariffs.name,
                    description: tariffs.description,
                    price: tariffs.price,
                    quantityDoctor: tariffs.quantityDoctor,
                    warehouse: tariffs.warehouse,
                    postcardSMS: tariffs.postcardSMS,
                    tariffType: tariffs.tariffType,
                },
            })
        );
    };

    useEffect(() => {
        if (putEditTariffs !== null) {
            setTariffs({
                name: putEditTariffs?.name,
                description: putEditTariffs?.description,
                price: putEditTariffs?.price,
                quantityDoctor: putEditTariffs?.quantityDoctor,
                warehouse: putEditTariffs?.warehouse,
                postcardSMS: putEditTariffs?.postcardSMS,
                tariffType: putEditTariffs?.tariffType,
            });
        }
    }, [putEditTariffs]);

    return (
        <StyledContainerTariffs>
            <Breadcrumbs aria-label="breadcrumb">
                <LINK underline="hover" color="inherit" href="/tariffs">
                    Тарифы
                </LINK>
                <LINK underline="hover" color="inherit" aria-current="page">
                    Редактировать тариф
                </LINK>
            </Breadcrumbs>
            <h1>Редактировать тариф</h1>
            <StyledContainerMain>
                <StyledContainerLeft>
                    <Input
                        maxLength="25"
                        label="Название"
                        value={tariffs.name}
                        onChange={(e) => {
                            setTariffs({ ...tariffs, name: e.target.value });
                        }}
                    />
                    <Input
                        label="Описание"
                        type="textarea"
                        value={tariffs.description}
                        onChange={(e) => {
                            setTariffs({
                                ...tariffs,
                                description: e.target.value,
                            });
                        }}
                    />
                    <Input
                        label="Цена"
                        type="number"
                        value={tariffs.price}
                        onChange={(e) => {
                            setTariffs({
                                ...tariffs,
                                price: e.target.value,
                            });
                        }}
                    />
                    <Input
                        label="Количество докторов"
                        type="number"
                        value={tariffs.quantityDoctor}
                        onChange={(e) => {
                            setTariffs({
                                ...tariffs,
                                quantityDoctor: e.target.value,
                            });
                        }}
                    />
                    <StyledWrapper>
                        <Wrapper>
                            <UICheckbox
                                label="Склад"
                                widthAndHeight="20px"
                                top="10px"
                                checked={tariffs.warehouse}
                                onChange={(e) => {
                                    setTariffs({
                                        ...tariffs,
                                        warehouse: e.target.checked,
                                    });
                                }}
                            />
                            <UICheckbox
                                label="SMS"
                                widthAndHeight="20px"
                                top="10px"
                                checked={tariffs.postcardSMS}
                                onChange={(e) => {
                                    setTariffs({
                                        ...tariffs,
                                        postcardSMS: e.target.checked,
                                    });
                                }}
                            />
                        </Wrapper>
                        <Button
                            disabled={!validation}
                            width="150px"
                            onClick={putSubmitHandle}
                        >
                            Сохранить
                        </Button>
                    </StyledWrapper>
                </StyledContainerLeft>
            </StyledContainerMain>
        </StyledContainerTariffs>
    );
};

const StyledContainerTariffs = styled.div`
    h1 {
        margin-top: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        @media screen and (max-width: 750px) {
            font-size: 16px;
        }
    }
`;

const LINK = styled(Link)`
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #000000;
    @media screen and (max-width: 750px) {
        font-size: 14px;
    }
    @media screen and (max-width: 550px) {
        font-size: 12px;
    }
`;

const StyledContainerMain = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
`;
const StyledContainerLeft = styled.div`
    width: 70%;
`;
const StyledWrapper = styled.div`
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
`;
const Wrapper = styled.div`
    display: flex;
    gap: 20px;
`;
export default EditTariffs;
