import styled from 'styled-components';

const UiInput = ({
    value,
    placeholder,
    type,
    error,
    id,
    width,
    margin,
    marginleft,
    marginright,
    marginbottom,
    onChange,
    isDisabled,
}) => {
    return (
        <Input
            disabled={isDisabled}
            onChange={onChange}
            value={value}
            error={error}
            placeholder={placeholder}
            type={type}
            id={id}
            width={width}
            margin={margin}
            marginleft={marginleft}
            marginright={marginright}
            marginbottom={marginbottom}
        />
    );
};

export default UiInput;

const Input = styled.input`
    height: 42px;
    border-radius: 5px;
    background: #ffffff;
    width: ${(props) => props.width};
    margin: ${(props) => props.margin};
    margin-left: ${(props) => props.marginleft};
    margin-right: ${(props) => props.marginright};
    margin-top: 10px;
    margin-bottom: ${(props) => props.marginbottom};
    height: 45px;
    border: 1px solid #ced4da;
    padding: 0 10px;
    outline: none;
    font-weight: 400;
    color: grey;
    ::placeholder {
        color: #d0d2d3;
    }
`;
